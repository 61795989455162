import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ModalBody } from '../../../../../components/bootstrap/Modal';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';
import Button from '../../../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { PatientService } from '../../../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import { UserService } from '../../../../../custom/api/service/user.service';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import CustomSelect from '../../../../../custom/components/Select/select';
import ScheduleModal from '../../../../../custom/pages/doctors/schedule/ScheduleModal';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { GoogleService } from '../../../../../custom/api/service/google-callendar.service';
import { ConsultationListService } from '../../../../../custom/api/service/consulatation-list.service';
import { IConsultationList } from '../../../../../custom/api/interface/consultation-list.interface';
import DentalContext from '../../../../../contexts/dentalContext';
import { connectClick } from '../../../../../utils/functions';
import VisitInfoComponent from '../CreateVisitComponents/VisitInfoComponent';
import ServiceChooseModal from '../../../../../custom/pages/services/ServiceChooseModal/ServiceChooseModal';
import { IStage } from '../../../../../custom/api/interface/stage.interface';
import { StageService } from '../../../../../custom/api/service/stage.service';
import Loading from '../../../../../custom/components/loading/Loading';
import DentalContextFunctions from '../../../../../contexts/dentalContextFunctions';
import FormDataContext from '../../../../../contexts/formDataContext';

interface CreateConsultationParams {
	inputDto: IConsultationList;
	uuid: string;
}

type NotificationPlacement = NotificationArgsProps['placement'];

interface CreateVisitProps {
	patientId: string;
	setIsOpen(...args: unknown[]): unknown;
	setIsPatientChoosen(...args: unknown[]): unknown;
	setIsNewPatientChoosen(...args: unknown[]): unknown;
	setChoosenPatientId(...args: unknown[]): unknown;
	setPatientCreated(...args: unknown[]): unknown;
	setPatientCreatedId(...args: unknown[]): unknown;
	setCreateVisit(...args: unknown[]): unknown;
	setServiceId(...args: unknown[]): unknown;
	choosenPatientId: string | null;
	serviceId: null | string | [];
	medicalCaseUuid: null | string;
	stageUuid: null | string;
	isNewPatientChoosen?: boolean;
	clinicUuid: string;
	patientCreated: boolean;
}

const CreateVisit: FC<CreateVisitProps> = ({
	patientId,
	clinicUuid,
	setIsOpen,
	setServiceId,
	setIsPatientChoosen,
	setIsNewPatientChoosen,
	setChoosenPatientId,
	setPatientCreated,
	isNewPatientChoosen,
	setPatientCreatedId,
	setCreateVisit,
	choosenPatientId,
	patientCreated,
	serviceId,
	medicalCaseUuid,
	stageUuid,
}) => {
	const { getInfoForCreateVisit } = useContext(DentalContextFunctions);
	const { setSelectMany, setSelectedItems, setSelectedItemsAll, setSelectManyAll } =
		useContext(DentalContext);
	const { default_form_data } = useContext(FormDataContext);

	const [search, setSearch] = useState<any>({});
	const DebouncedSearch = useDebounce(search);
	const [visitData, setVisitData] = useState([]);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);
	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [isGoogleCalendar, setIsGoogleCallendar] = useState<boolean>(false);
	const [isGoogleCalendarLink, setIsGoogleCallendarLink] = useState<any>(null);
	const [consultationListUuid, setConslutationListUuid] = useState<any>('');
	const [consultationListData, setConslutationListdata] = useState<any>({});
	const { themeStatus } = useDarkMode();
	const queryClient = useQueryClient();
	const [api, contextHolder] = notification.useNotification();
	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);
	const changeValues = useCallback((key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	}, []);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	useEffect(() => {
		if (isUserDoctor) {
			changeValues('clinicUuid', user_role?.roles[0]?.clinicUuid);
		}
	}, [changeValues, isUserDoctor, user_role?.roles]);

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};
	const { isLoading: patientLoading, data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() =>
			PatientService.findOne(
				patientId,
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'medicalCase',
						},
					],
					filterMeta: {},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!patientId,
		},
	);

	const [values, setValues] = useState<IVisit>({});

	const getValue = useCallback((visitValue: any, defaultValue: any) => {
		return visitValue !== undefined ? visitValue : defaultValue;
	}, []);

	const { data: stage }: { data: IStage[] | any; isLoading: boolean } = useQuery(
		['stage', stageUuid],
		() => (stageUuid ? StageService.findOne(stageUuid) : Promise.resolve(null)),
		{
			refetchOnWindowFocus: false,
			enabled: !!stageUuid,

		},
	);

	const { data: consulatation_list }: { data: any[] | any; isLoading: boolean } = useQuery(
		['consulatation_list', stage?.uuid],
		() =>
			ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage?.uuid,
							},
						},
					],
				}),
			),
		{
			enabled: !!stage?.uuid,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (consulatation_list?.data?.length > 0) {
			setConslutationListUuid(consulatation_list?.data[0]?.uuid);
			setConslutationListdata(consulatation_list?.data[0]?.dentalFormula);
		}
	}, [consulatation_list]);

	useEffect(() => {
		if (patient?.medicalCase?.length <= 0 || consulatation_list?.data[0]?.dentalFormula) {
			const newValues: any = {
				services: getValue(values.services, serviceId),
				clinicUuid: getValue(values.clinicUuid, clinicUuid),
				complaint: getValue(undefined, values.complaint),
				visitNumber: getValue(undefined, values.visitNumber),
				patientUuid: patientId,
				...((!patient?.medicalCase || patient.medicalCase.length) <= 0 &&
					!values.stage_type && {
						stage_type: 'стоматология',
					}),
				...((!patient?.medicalCase || patient.medicalCase.length) <= 0 &&
					values.stage_type && {
						stage_type: values?.stage_type,
					}),
				startDate: values?.startDate ?? '',
				endDate: values?.endDate ?? '',
				userUuid: values?.userUuid ?? '',
				userFullName: values?.userFullName ?? '',
				visitDate: values?.visitDate ?? '',
			};
			if (medicalCaseUuid !== null && stageUuid !== null) {
				newValues.medicalCaseUuid = medicalCaseUuid;
				newValues.stageUuid = stageUuid;
			}

			const data = getInfoForCreateVisit(
				newValues?.services,
				JSON.stringify(
					patient?.medicalCase?.length <= 0
						? default_form_data
						: consulatation_list?.data[0]?.dentalFormula,
				),
			);
			setValues({
				...newValues,
				services: newValues.services,
				...(patient?.medicalCase?.length <= 0
					? { new_form_data: data.data }
					: {
							form_data: data.data,
							consulatation_list_uuid: consulatation_list?.data[0]?.uuid,
					  }),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		patientId,
		medicalCaseUuid,
		stageUuid,
		serviceId,
		clinicUuid,
		getInfoForCreateVisit,
		consulatation_list?.data,
		consultationListData,
		patient?.medicalCase?.length,
	]);
	const changeSearchValue = useCallback((key: string, value: string | number) => {
		setSearch((prev: any) => ({ ...prev, [key]: value }));
	}, []);

	const { isLoading: clinicsLoading, data: clinics } = useQuery<IClinic | null, Error>(
		['clinics', DebouncedSearch],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: calendarAccess } = useQuery(
		['calendar_access', isGoogleCalendar],
		async () => {
			if (isGoogleCalendar) {
				return await GoogleService.generateGoogleCallendarAccses();
			}
			return null;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isGoogleCalendar,
		},
	);

	useEffect(() => {
		setIsGoogleCallendarLink(calendarAccess);
	}, [isGoogleCalendar, calendarAccess]);

	useEffect(() => {
		if (isGoogleCalendar && isGoogleCalendarLink && typeof isGoogleCalendarLink === 'string') {
			if (
				isGoogleCalendar &&
				isGoogleCalendarLink &&
				typeof isGoogleCalendarLink === 'string'
			) {
				connectClick(isGoogleCalendarLink);
			}
		}
	}, [isGoogleCalendar, isGoogleCalendarLink]);

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			setIsOpen(false);
			setIsPatientChoosen(false);
			setIsNewPatientChoosen(false);
			setChoosenPatientId(null);
			setPatientCreated(false);
			setPatientCreatedId(null);
			setCreateVisit(false);
			queryClient.invalidateQueries('consultation_list');
			queryClient.invalidateQueries('stages');
			queryClient.invalidateQueries('visits');
			// if (!values.stageUuid) return;
			if (!!values.consulatation_list_uuid && !!values.form_data) {
				updateConslutationList({
					uuid: values.consulatation_list_uuid,
					inputDto: { dentalFormula: values.form_data },
				});
			}
		},

		onError: (error: any) => {
			const { message } = error.response.data;
			const errorMessages: { [key: string]: [string | null, string | null] } = {
				calendar_auth_failed: [null, null], // Special case handled separately
				choose_user_date_time: ['Важно !', 'Выберите дату и врача чтобы создать визит'],
				'Start date must be before end date': [
					'Важно !',
					'Дата начала должна быть раньше даты окончания.',
				],
				end_date_empty: ['Важно !', 'выберите время окончания визита'],
				start_date_empty: ['Важно !', 'выберите время начала визита'],
				cant_select_past_date: ['Важно !', 'Нельзя выбрать прошедшую дату'],
				can_not_create_visit_whitout_services: [
					'Важно !',
					'выберите услуги чтобы создать визит',
				],
				time_range_choosen: ['Важно !', 'выбранное время уже занято'],
			};

			if (message === 'calendar_auth_failed') {
				setIsGoogleCallendar(true);
			} else if (errorMessages[message]) {
				const [title, description] = errorMessages[message];
				openNotification('bottomRight', title || 'Ошибка', description || 'Ошибка'); // Provide a default title if null
			}
		},
	};

	useEffect(() => {
		if (
			(values?.services?.length > 0 && patient?.medicalCase?.length <= 0) ||
			(values?.services?.length > 0 && consultationListData && consultationListUuid)
		) {
			const data = getInfoForCreateVisit(
				values.services,
				JSON.stringify(
					patient?.medicalCase?.length <= 0 ? default_form_data : consultationListData,
				),
			);
			setValues((prev: any) => ({
				...prev,
				services: values.services,
				...(patient?.medicalCase?.length <= 0
					? { new_form_data: data.data }
					: {
							form_data: data.data,
							consulatation_list_uuid: consulatation_list?.data[0]?.uuid,
					  }),
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.services]);

	const goBack = useCallback(() => {
		setCreateVisit(false);
		if (isNewPatientChoosen) {
			setIsNewPatientChoosen(false);
			setIsPatientChoosen(false);
			setIsNewPatientChoosen(false);
			setChoosenPatientId(null);
			setPatientCreatedId('');
			setPatientCreated(false);
			setCreateVisit(false);
		}
	}, [
		isNewPatientChoosen,
		setChoosenPatientId,
		setCreateVisit,
		setIsNewPatientChoosen,
		setIsPatientChoosen,
		setPatientCreated,
		setPatientCreatedId,
	]);

	const { mutate: updateConslutationList, isLoading: consultationLoading } = useMutation(
		({ uuid, inputDto }: CreateConsultationParams) =>
			ConsultationListService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('stages');
				queryClient.invalidateQueries('visits');
				setIsOpen(false);
				setSelectMany(false);
				setSelectManyAll(false);
				setSelectedItemsAll([]);
				setSelectedItems([]);
				setIsOpen(false);
				setValues({
					services: serviceId || undefined,
					userUuid: undefined,
					complaint: undefined,
					clinicUuid,
					visitDate: '',
					patientUuid: patientId,
					...(medicalCaseUuid && stageUuid && { medicalCaseUuid, stageUuid }),
				});

				setIsPatientChoosen(false);
				setIsNewPatientChoosen(false);
				setChoosenPatientId(null);
				setPatientCreatedId('');
				setPatientCreated(false);
				setCreateVisit(false);
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const { mutate, isLoading: visitLoading } = useMutation(
		(inputDto: IVisit) => VisitService.create(inputDto),
		queryOptions,
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await mutate({
			...values,
			services: values?.services,
		});
	};

	if (patientLoading || clinicsLoading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<ModalBody className='d-flex flex-column gap-4 align-items-center col-12 px-0'>
				<span>{contextHolder}</span>
				<form style={{ width: '100%' }} onSubmit={handleSubmit}>
					<div className='d-flex flex-row justify-content-between align-items-start mb-4 col-12'>
						<div className='d-flex flex-column gap-4 col-5'>
							<FormGroup className='col-12' id='visitNumber' label='Номер визита'>
								<Input
									required
									type='number'
									value={values.visitNumber}
									onChange={(e: any) =>
										changeValues('visitNumber', e.target.value)
									}
								/>
							</FormGroup>
							<FormGroup className='col-12' id='patient' label='Пациент'>
								<Input
									disabled={true}
									value={`${patient?.surname} ${patient?.name} ${patient?.middleName}`}
									type='text'
									className='px-4'
								/>
							</FormGroup>
							<FormGroup
								id='clinic'
								label='Филиал'
								className='m-0 d-flex flex-column'>
								<CustomSelect
									placeholder={'Выберите филиал'}
									disabled={isUserDoctor}
									name={'clinic'}
									value={values.clinicUuid}
									options={clinics?.data.map((item: IClinic, index: number) => {
										return {
											value: item.uuid,
											label: item.name,
										};
									})}
									changeSearchValue={changeSearchValue}
									changeValues={changeValues}
								/>
							</FormGroup>

							<div className='d-flex flex-column gap-3'>
								<FormGroup className='col-12' id='complaint' label='Жалоба'>
									<Textarea
										required
										value={values.complaint}
										onChange={(e: any) =>
											changeValues('complaint', e.target.value)
										}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='d-flex flex-column gap-4 col-5'>
							{' '}
							<FormGroup label='Услуги' className='col-12'>
								<Button
									style={{
										boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
									}}
									onClick={() => setPopperIsOpenService(true)}
									color={themeStatus}
									className='text-nowrap me-2 col-12'>
									Управление услугами
								</Button>
							</FormGroup>
							<FormGroup id='doctor' label='Врач' className='m-0'>
								<Button
									onClick={() => {
										setSchedulModalOpen(true);
									}}
									isLight
									color='primary'
									className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-center'>
									Выбрать врача, дату и время визита
								</Button>
							</FormGroup>
							{(!patient?.medicalCase || patient.medicalCase.length <= 0) && (
								<FormGroup
									id='stage'
									label='План по специальности*'
									className='m-0'>
									<select
										className='form-select'
										style={{
											width: 150,
										}}
										defaultChecked={true}
										onChange={(e: any) =>
											changeValues('stage_type', e.target.value)
										}
										value={values.stage_type}>
										<option value='стоматология'>Стоматология</option>
										<option value='лор'>ЛОР</option>
										<option value='косметология'>Косметология</option>
									</select>
								</FormGroup>
							)}
							<VisitInfoComponent values={values} />
						</div>
					</div>
					<div className='d-flex justify-content-between w-100'>
						<Button
							isDisable={consultationLoading || visitLoading}
							className='fw-bold fs-6'
							color='link'
							isLight
							style={{ color: '#0D6EFD' }}
							onClick={goBack}>
							Назад
						</Button>
						<Button
							isDisable={consultationLoading || visitLoading}
							className='fw-bold fs-6 position-relative'
							color='info'
							type='submit'>
							<Loading loading={consultationLoading || visitLoading} />
							Создать визит
						</Button>
					</div>
				</form>
			</ModalBody>
			<ServiceChooseModal
				stage_type={
					!(!patient?.medicalCase || patient.medicalCase.length <= 0) && stage?.type
						? stage?.type
						: values?.stage_type
				}
				setIsOpen={setPopperIsOpenService}
				isOpen={popperIsOpenService}
				stage={stageUuid}
				setServiceUuid={setServiceId}
				setChosenValues={setValues}
				serviceDropdownInfo={values.services}
				createVisit
			/>

			<ScheduleModal
				medicalCaseUuid={medicalCaseUuid ?? null}
				clinicUuid={values?.clinicUuid}
				changeValues={changeValues}
				visitData={visitData}
				setVisitData={setVisitData}
				values={values}
				isOpen={schedulModalOpen}
				setIsOpen={setSchedulModalOpen}
			/>
		</>
	);
};

export default CreateVisit;
