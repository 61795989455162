import React, { FC, memo, useCallback, useState } from 'react';
import SearchFilterCompnent from './FilterComponentItems/SearchFilterCompnent';
import CodeFilterComponent from './FilterComponentItems/CodeFilterComponent';
import ServiceFilterComponent from './FilterComponentItems/ServiceFilterComponent';
import SpecializationFilterComponent from './FilterComponentItems/SpecializationFilterComponent';
import CategoryFilterComponent from './FilterComponentItems/CategoryFilterComponent';
import FilialFilterComponent from './FilterComponentItems/FilialFilterComponent';
import NameFilterComponent from './FilterComponentItems/NameFilterComponent';
import { filter } from 'lodash';
import TreatmentTypeFilterComponent from './FilterComponentItems/TreatmentTypeFilterComponent';

interface IVisitFilterComponentProps {
	values: any;
	filter: any;
	isLargeDesktop: boolean;
	isSmallDesktop: boolean;
	isUserDoctor: boolean;
	isTablet: boolean;
	searchIconClicked: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setSearchIconClicked(...args: unknown[]): unknown;
	canvasColor: any;
	dynamicFont: any;
}

const ServicesFilterComponent: FC<IVisitFilterComponentProps> = memo(
	({
		values,
		filter,
		canvasColor,
		isUserDoctor,
		dynamicFont,
		isSmallDesktop,
		isLargeDesktop,
		setValues,
		setFilter,
		isTablet,
		searchIconClicked,
		setSearchIconClicked,
	}) => {
		//TODO: FIX ALL FILTERS, RESEARCH BACKEND TO FIND QUERY PARAMS
		const [isCodePopperOpen, setIsCodePopperOpen] = useState(false);
		const [isFilialPopperOpen, setIsFilialPopperOpen] = useState(false);
		const [isTreatmentTypePopperOpen, setIsTreatmentTypePopperOpen] = useState(false);
		const [isServicePopperOpen, setIsServicePopperOpen] = useState(false);
		const [isUserPopperOpen, setIsUserPopperOpen] = useState(false);
		const [isCategoryPopperOpen, setIsCategoryPopperOpen] = useState(false);

		const changeValues = useCallback(
			(key: string, value: string | number) => {
				if (key === 'services') {
					setValues((prev: any) => ({
						...prev,
						[key]: [...(values[key] || []), value],
					}));
				} else if (key === 'users') {
					setValues((prev: any) => ({
						...prev,
						userName: undefined,
						[key]: [...values[key], value],
					}));
				} else if (key === 'clinic') {
					setFilter((prev: any) => ({
						...prev,
						[key]: [...values[key], value],
					}));
				} else {
					setValues((prev: any) => ({ ...prev, [key]: [...values[key], value] }));
				}
			},
			[setValues, values],
		);
		const changeFilter = useCallback(
			(key: string, value: string | number) => {
				if (key === 'clinic') {
					setFilter((prev: any) => ({
						...prev,
						[key]: [...values[key], value],
					}));
				} else {
					setFilter((prev: any) => ({ ...prev, [key]: [...values[key], value] }));
				}
			},
			[setFilter, filter],
		);

		const handleCheckboxChange = useCallback(
			(e: any) => {
				const name = e.target.name;
				if (e.target.type === 'checkbox') {
					const isChecked = e.target.checked;
					if (isChecked) {
						if (!values[name].includes(e.target.defaultValue)) {
							changeValues(name, e.target.defaultValue);
						}
					} else {
						setValues((prev: any) => ({
							...prev,
							[name]: prev[name].filter(
								(value: string) => value !== e.target.defaultValue,
							),
						}));
					}
				}

				if (e.target.type === 'button') {
					setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
				}

				if (e.target.type === 'search') {
					if (name === 'serviceId' && /^\d*$/.test(e.target.value)) {
						setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
					if (name === 'serviceSearch' || name === 'serviceName') {
						setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
				}
			},
			[changeValues, setValues, values],
		);

		const handleFilterCheckboxChange = useCallback(
			(e: any) => {
				const name = e.target.name;
				const value = e.target.value;
				if (e.target.type === 'checkbox') {
					const isChecked = e.target.checked;
					if (isChecked) {
						setFilter((prev: any) => ({
							...prev,
							[name]: value,
						}));
					} else {
						setFilter((prev: any) => ({
							...prev,
							[name]: '',
						}));
					}
				}

				if (e.target.type === 'button') {
					setFilter((prev: any) => ({ ...prev, [name]: e.target.value }));
				}

				if (e.target.type === 'search') {
					if (name === 'serviceId' && /^\d*$/.test(e.target.value)) {
						setFilter((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
					if (name === 'serviceSearch' || name === 'serviceName') {
						setFilter((prev: any) => ({ ...prev, [name]: e.target.value }));
					} else {
						setFilter((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
				}
			},
			[setFilter],
		);

		return (
			<div className='d-flex flex-row align-items-center w-full grow'>
				<div className='d-flex flex-row align-items-center'>
					{isLargeDesktop && <span className='text-nowrap me-2'> Фильтровать по:</span>}
					<FilialFilterComponent
						handleCheckboxChange={handleFilterCheckboxChange}
						setPopperIsOpenStatus={setIsFilialPopperOpen}
						popperIsOpenStatus={isFilialPopperOpen}
						values={filter}
						dynamicFont={dynamicFont}
						// changeSearchValue={changeSearchValue}
						disabled={isUserDoctor}
						changeValues={changeFilter}
					/>
					<TreatmentTypeFilterComponent
						handleCheckboxChange={handleFilterCheckboxChange}
						setPopperIsOpen={setIsTreatmentTypePopperOpen}
						popperIsOpen={isTreatmentTypePopperOpen}
						values={filter}
						dynamicFont={dynamicFont}
						// changeSearchValue={changeSearchValue}
						disabled={isUserDoctor}
						changeValues={changeFilter}
					/>
					{/* <ServiceFilterComponent 
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenService={setIsServicePopperOpen}
						popperIsOpenService={isServicePopperOpen}
						values={values}
						dynamicFont={dynamicFont}
						canvasColor={canvasColor}
					/> */}
					<SpecializationFilterComponent
						handleCheckboxChange={handleFilterCheckboxChange}
						setPopperIsOpenStatus={setIsUserPopperOpen}
						popperIsOpenStatus={isUserPopperOpen}
						values={filter}
						dynamicFont={dynamicFont}
						disabled={isUserDoctor}
						changeValues={changeFilter}
					/>
					{/* <CategoryFilterComponent
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenStatus={setIsCategoryPopperOpen}
						popperIsOpenStatus={isCategoryPopperOpen}
						values={values}
						dynamicFont={dynamicFont}
					/> */}
					<CodeFilterComponent
						handleInputChange={handleFilterCheckboxChange}
						setPopperIsOpenCode={setIsCodePopperOpen}
						popperIsOpenCode={isCodePopperOpen}
						values={filter}
					/>
				</div>
			</div>
		);
	},
);
export default ServicesFilterComponent;
