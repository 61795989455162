import React, { FC, memo } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../../components/bootstrap/Button';
import useDarkMode from '../../../../../../hooks/useDarkMode';
import Status from '../../../../../../custom/components/tables/columns/Status';

interface IStatusFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenStatus(...args: unknown[]): unknown;
	popperIsOpenStatus: any;
	values: any;
	dynamicFont: any;
}

const StatusFilterComponent: FC<IStatusFilterComponentProps> = memo(
	({ values, setPopperIsOpenStatus, popperIsOpenStatus, handleCheckboxChange }) => {
		const { themeStatus } = useDarkMode();

		return (
			<Dropdown isOpen={popperIsOpenStatus} setIsOpen={setPopperIsOpenStatus}>
				<DropdownToggle>
					<Button
						style={{
							boxSizing: 'border-box',
							border:
								values?.statuses?.length > 0
									? '2px solid #1266f1'
									: '2px solid #f8f9fa',
						}}
						color={themeStatus}
						className='text-nowrap me-2'>
						Статусу
					</Button>
				</DropdownToggle>
				<DropdownMenu className='py-4 px-2' style={{ width: '400px' }}>
					<div
						className='p-0'
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Предстоящий'
								id='flexCheckDefault-0'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Предстоящий')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-0'>
								<Status status={'Предстоящий'} statusName={'Предстоящий'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='В ожидании'
								id='flexCheckDefault-1'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('В ожидании')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-1'>
								<Status status={'В ожидании'} statusName={'В ожидании'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								color={themeStatus}
								className='form-check-input'
								type='checkbox'
								value='В процессе'
								id='flexCheckDefault-2'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('В процессе')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-2'>
								<Status status={'В процессе'} statusName={'На приеме'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Визит завершен'
								id='flexCheckDefault-3'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Визит завершен')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-3'>
								<Status status={'Визит завершен'} statusName={'Визит завершен'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Калькуляция'
								id='flexCheckDefault-4'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Калькуляция')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-4'>
								<Status status={'Калькуляция'} statusName={'Ожидание оплаты'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Задолженность'
								id='flexCheckDefault-5'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Задолженность')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-5'>
								<Status status={'Задолженность'} statusName={'Задолженность'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Завершен'
								id='flexCheckChecked-6'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Завершен')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckChecked-6'>
								<Status status={'Завершен'} statusName={'Оплачено'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Отменен'
								id='flexCheckDefault-7'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Отменен')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-7'>
								<Status status={'Отменен'} statusName={'Отменен'} />
							</label>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default StatusFilterComponent;
