import React, { SVGProps } from "react";

export default function T_CHANNELS(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="116" height="196" viewBox="0 0 116 196" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M13.894 130.504C16.2604 108.304 18.3405 103.47 15.694 84.3037H57.6939V102.304C53.4604 105.904 48.694 124.504 46.894 152.704C45.094 180.904 33.6599 189.904 29.494 189.904C25.328 189.904 11.5275 152.704 13.894 130.504Z" fill="#E2DFDF"/>
<path d="M101.422 130.504C99.056 108.304 93.841 103.47 96.4875 84.3037H57.6225V102.304C61.856 105.904 66.6225 124.504 68.4225 152.704C70.2225 180.904 81.6565 189.904 85.8224 189.904C89.9884 189.904 103.789 152.704 101.422 130.504Z" fill="#E2DFDF"/>
<path d="M84.8207 168.335C90.6118 165.568 88.2573 94.6449 88.0391 88.4148C88.0307 88.1731 87.7904 88.0593 87.5615 88.1375C79.4957 90.8918 73.9471 91.8231 64.4621 93.1834C64.1478 93.2285 64.0223 93.6454 64.2515 93.865C66.4698 95.9899 73.9715 104.421 77.1918 123.795C81.7721 143.626 81.6683 169.841 84.8207 168.335Z" fill="#B98F66" stroke="#A97949" strokeWidth="0.6"/>
<path d="M71.998 93.0002L80.998 90.6002C80.998 90.6002 83.8699 113.244 85.2004 139.8C86.6261 168.258 86.4591 200.587 84.598 195C82.8401 189.723 83.656 162.85 80.998 139.2C78.2164 114.449 71.998 93.0002 71.998 93.0002Z" fill="#CD7D64" stroke="#C46749" strokeWidth="0.6"/>
<path d="M28.8981 168.16C22.8406 165.265 25.6952 87.8037 25.6952 87.8037C34.2516 90.764 39.6088 91.93 49.7962 93.384C49.7962 93.384 40.3284 100.75 36.5269 123.62C31.9466 143.451 32.0505 169.667 28.8981 168.16Z" fill="#B98F66" stroke="#A97949" strokeWidth="0.6"/>
<path d="M40.5527 92.4001L31.5527 90.0001C31.5527 90.0001 28.6809 112.644 27.3504 139.2C25.9247 167.658 26.0917 199.987 27.9527 194.4C29.7106 189.123 28.8948 162.25 31.5527 138.6C34.3344 113.849 40.5527 92.4001 40.5527 92.4001Z" fill="#CD7D64" stroke="#C46749" strokeWidth="0.6"/>
<path d="M14.9996 84C12.6377 73.4985 10.6276 67.7953 6.59961 57.6L100.2 69.6C95.8602 77.2657 95.1327 80.508 96.5996 84.6C64.8822 96.8188 48.3395 96.1355 14.9996 84Z" fill="url(#paint0_linear_730_5913)"/>
<path d="M14.9996 84C12.6377 73.4985 10.6276 67.7953 6.59961 57.6L100.2 69.6C95.8602 77.2657 95.1327 80.508 96.5996 84.6C64.8822 96.8188 48.3395 96.1355 14.9996 84Z" fill="url(#paint1_linear_730_5913)" fillOpacity="0.8"/>
<path d="M113.475 26.2656C105.595 -2.62656 72.982 7.66097 57.6604 16.4163C52.7358 8.20852 31.3944 0 23.1864 0C14.9784 0 -6.36235 6.5664 1.84565 45.9648C8.41206 77.4835 41.7915 73.3248 57.6604 67.3056C95.4172 87.0048 123.324 62.3808 113.475 26.2656Z" fill="url(#paint2_linear_730_5913)"/>
<path d="M113.475 26.2656C105.595 -2.62656 72.982 7.66097 57.6604 16.4163C52.7358 8.20852 31.3944 0 23.1864 0C14.9784 0 -6.36235 6.5664 1.84565 45.9648C8.41206 77.4835 41.7915 73.3248 57.6604 67.3056C95.4172 87.0048 123.324 62.3808 113.475 26.2656Z" fill="url(#paint3_radial_730_5913)" fillOpacity="0.3"/>
<g filter="url(#filter0_f_730_5913)">
<path d="M65.2946 43.0807C62.0114 59.4967 77.5686 54.5719 86.6349 54.5719C95.7012 54.5719 93.2019 47.0505 93.2019 39.7975C93.2019 32.5444 67.7944 25.0231 58.7281 25.0231C49.6618 25.0231 55.445 38.1559 65.2946 43.0807Z" fill="#FDFDFD"/>
</g>
<g filter="url(#filter1_f_730_5913)">
<path d="M55.3821 32.7864L42.2293 17.4414C41.0304 16.0427 42.6906 14.0338 44.2902 14.9478L47.5528 16.8121L57.4024 23.3785L72.9255 17.8998C74.7475 17.2567 75.9901 19.7419 74.3824 20.8137L56.2449 32.9054C55.9686 33.0896 55.5982 33.0385 55.3821 32.7864Z" fill="#BBA8A8"/>
<path d="M55.3821 32.7864L42.2293 17.4414C41.0304 16.0427 42.6906 14.0338 44.2902 14.9478L47.5528 16.8121L57.4024 23.3785L72.9255 17.8998C74.7475 17.2567 75.9901 19.7419 74.3824 20.8137L56.2449 32.9054C55.9686 33.0896 55.5982 33.0385 55.3821 32.7864Z" stroke="#BBA8A8" strokeWidth="3.2832"/>
</g>
<defs>
<filter id="filter0_f_730_5913" x="37.7871" y="8.60707" width="72.1465" height="63.0733" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="8.208" result="effect1_foregroundBlur_730_5913"/>
</filter>
<filter id="filter1_f_730_5913" x="30.3145" y="3.21241" width="56.3125" height="41.2945" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4.9248" result="effect1_foregroundBlur_730_5913"/>
</filter>
<linearGradient id="paint0_linear_730_5913" x1="53.3996" y1="57.6" x2="53.3996" y2="93.4392" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<linearGradient id="paint1_linear_730_5913" x1="58.7996" y1="73.8" x2="56.3996" y2="94.2" gradientUnits="userSpaceOnUse">
<stop stopColor="#B8A2A2"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_730_5913" x1="57.7341" y1="0" x2="57.7341" y2="74.2859" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_730_5913" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.2171 30.9006) rotate(32.9052) scale(33.2399 51.6677)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
<clipPath id="clip0_730_5913">
<rect width="24" height="24" fill="white" transform="translate(46 75)"/>
</clipPath>
</defs>
</svg>   
  )
}




