import { VisitStatusStyles } from '../../../../utils/functions';
import React from 'react';

export const Sum: React.FC<{
	text?: string;
	status: string;
	className?: string | {};
}> = ({ text, status, className }) => {
	const dateStyles = VisitStatusStyles(status)?.dateStyles;
	const getColor = (status: string | null) => {
		if (status === 'Завершен') {
			return 'success';
		} else if (status === 'Отменен' || status === 'Калькуляция' || 'Задолженность') {
			return 'danger';
		} else if (status === 'В процессе') {
			return 'info';
		} else if (status === 'Предстоящий') {
			return 'warning';
		}
	};
	const color = getColor(status);
	
	return (
		<div className={`d-flex align-items-center ${className}`}>
			<div
				className={`date-frame rounded-2 d-flex align-items-center justify-content-center border-0`}
				style={{ ...dateStyles, maxWidth: '15rem' }}>
				<div>
					<span className={`text-${color} fs-5 fw-bold align-text-bottom`}>
						{status === 'Завершен' && '+'}
						{status === 'Задолженность' && '-'}
					</span>

					<span
						className={`text-${color} fs-3 fw-bold text-nowrap`}>{`${text} AMD`}</span>
				</div>
			</div>
		</div>
	);
};
