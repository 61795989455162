import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Button from '../../../../../../../components/bootstrap/Button';
import DentalContext from '../../../../../../../contexts/dentalContext';
import { TC_IMPLANT } from '../../../../../../../assets/svg/TC_IMPLANT';
import { TC_BRIDGE_S } from '../../../../../../../assets/svg/TC_BRIDGE_S';
import { TC_BRIDGE_C } from '../../../../../../../assets/svg/TC_BRIDGE_C';
import { TC_BRIDGE_E } from '../../../../../../../assets/svg/TC_BRIDGE_E';
import Icon from '../../../../../../../components/icon/Icon';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../../../components/bootstrap/Card';
import T_CHANNELS from '../../../../../../../assets/svg/T_CHANNELS';
import HighlightedTitle from '../../../UI/HighlightedTitle';
import { TheethMilkTheeth } from '../../../../../../../components/icon/material-icons';
import DentalContextFunctions from '../../../../../../../contexts/dentalContextFunctions';
import { TC_BRIDGE_S_PRINT } from '../../../../../../../assets/svg/TC_BRIDGE_S_PRINT';
import { TC_BRIDGE_E_PRINT } from '../../../../../../../assets/svg/TC_BRIDGE_E _PRINT';
import { TC_BRIDGE_C_PRINT } from '../../../../../../../assets/svg/TC_BRIDGE_C_PRINT';
import { TC_IMPLANT_PRINT } from '../../../../../../../assets/svg/TC_IMPLANT_PRINT';

interface ITheethItemComponentProps {
	data: any;
	onToothClick?: any;
	onToothCircleClick?: any;
	nullifyCards?: any;
	key_custom: any;
	isPrint: boolean;
}

const TheethItemComponent: FC<ITheethItemComponentProps> = ({
	data,
	onToothClick,
	onToothCircleClick,
	nullifyCards,
	key_custom,
	isPrint,
}) => {
	const {
		selectMany,
		selectedItesm,
		setSelectedItems,
		intermediateItems,
		setIntermediateItems,
		selectedItesmAll,
		setSelectedItemsAll,
		selectManyAll,
	} = useContext(DentalContext);
	const { generateSequence } = useContext(DentalContextFunctions);
	const { dentalFormula } = useContext(DentalContext);
	const { enamel } = data;
	const [direction, setDirection] = useState('');
	const [selectedDirection, setSelectedDirection] = useState('');
	const [directionRevers, setDirectionRevers] = useState('');

	useEffect(() => {
		setDirection(data.id[0] === '1' || data.id[0] === '2' ? 'top' : 'bottom');
		setDirectionRevers(data.id[0] === '1' || data.id[0] === '2' ? 'bottom' : 'top');
	}, [data]);

	useEffect(() => {
		if (selectedItesm.length > 0) {
			setSelectedDirection(
				selectedItesm[0][0] === '1' || selectedItesm[0][0] === '2' ? 'bottom' : 'top',
			);
		} else {
			setSelectedDirection('');
		}
	}, [selectedItesm]);

	const pocedurseDelRef = (theeth: any, direction: string) => {
		return (
			<div
				style={{ ...(isPrint && { top: '0', height: `100%` }) }}
				className={`theeth-deletion`}>
				{theeth.referral && !isPrint ? (
					<div
						className={isPrint ? `theeth-referral` : `theeth-referral bg-light`}
						style={{
							// [directionRevers]: 0,
							transform: directionRevers === 'top' ? 'scale(1, -1)' : 'none',
						}}>
						<Icon
							// style={{ fill: 'white' }}
							icon='TheethDirection'
							size='2x'
							color='light'
						/>
					</div>
				) : (
					<></>
				)}

				{theeth.deletion && isPrint ? (
					<div
						className={
							isPrint ? `theeth-deletion-info` : `theeth-deletion-info bg-light`
						}
						style={{
							width: '100%',
							height: '100%',
							// [directionRevers]: 0,
							backgroundColor: 'transparent',
							transform: directionRevers === 'top' ? 'scale(1, -1)' : 'none',
							position: 'relative',
							zIndex: '8000',
							overflow: 'hidden',
						}}>
						<span
							style={{
								display: 'block',
								width: 3,
								height: '100%',
								transform: 'rotate(22deg)',

								backgroundColor: 'black',
							}}>
							{' '}
						</span>

						<span
							style={{
								display: 'block',
								width: 3,
								height: '100%',
								backgroundColor: 'black',
								transform: 'rotate(-22deg)',
							}}>
							{' '}
						</span>
					</div>
				) : (
					<></>
				)}
				{theeth.deletion && !isPrint ? (
					<div
						className={
							isPrint ? `theeth-deletion-info` : `theeth-deletion-info bg-light`
						}
						style={{
							// [directionRevers]: 0,
							transform: directionRevers === 'top' ? 'scale(1, -1)' : 'none',
						}}>
						<Icon style={{ fill: 'red' }} icon='Close' size='2x' color='danger' />
					</div>
				) : (
					<></>
				)}

				{theeth.milk_theeth && !isPrint ? (
					<div
						className={
							isPrint ? `theeth-deletion-info` : `theeth-deletion-info bg-light`
						}
						style={{
							transform: directionRevers === 'top' ? 'scale(1, -1)' : 'none',
						}}>
						<TheethMilkTheeth
						// color={darkModeStatus ? '#FFF' : '#000'}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		);
	};

	const pocedurse = (theeth: any, direction: string) => {
		return (
			<>
				{theeth.bridge ? (
					<div
						className={`theeth-bridge theeth-bridge-${theeth.bridge_position}`}
						style={{
							[directionRevers]: 0,
							...((theeth.bridge_position === 'start' ||
								theeth.bridge_position === 'end') &&
								isPrint && { [directionRevers]: 3 }),
							...(theeth.bridge_position === 'center' &&
								isPrint && { [directionRevers]: 4, left: -15 }),
							transform: directionRevers === 'top' ? 'scale(1, -1)' : 'none',
						}}>
						{theeth.bridge_position === 'start' &&
							(!isPrint ? (
								<TC_BRIDGE_S height={'100%'} style={{ height: '100%' }} />
							) : (
								<TC_BRIDGE_S_PRINT height={'100%'} style={{ height: '100%' }} />
							))}
						{theeth.bridge_position === 'end' &&
							(!isPrint ? (
								<TC_BRIDGE_E height={'100%'} style={{ height: '100%' }} />
							) : (
								<TC_BRIDGE_E_PRINT height={'100%'} style={{ height: '100%' }} />
							))}
						{theeth.bridge_position === 'center' &&
							(!isPrint ? (
								<TC_BRIDGE_C height={'100%'} style={{ height: '100%' }} />
							) : (
								<TC_BRIDGE_C_PRINT height={'100%'} style={{ height: '100%' }} />
							))}
					</div>
				) : (
					<></>
				)}

				{theeth.implant ? (
					<div
						className='theeth-implant'
						style={{
							backgroundColor: isPrint ? 'white' : 'inherit',
							[direction]: isPrint ? '0.4rem' : '0.7rem',
							transform: direction === 'bottom' ? 'scale(1, -1)' : 'none',
						}}>
						{!isPrint ? (
							<TC_IMPLANT height={'100%'} style={{ height: '100%' }} />
						) : (
							<TC_IMPLANT_PRINT height={'100%'} style={{ height: '100%' }} />
						)}
					</div>
				) : (
					<></>
				)}
			</>
		);
	};

	const selectItem = useCallback(
		(value: string) => {
			if (!selectMany && !selectManyAll && !selectedItesm.includes(value)) {
				setSelectedItems([value]);
			} else if (!selectMany && !selectManyAll && selectedItesm.includes(value)) {
				setSelectedItems([]);
			} else if (selectMany && !selectManyAll && selectedItesm.length === 0) {
				setSelectedItems([value]);
			} else if (
				selectMany &&
				!selectManyAll &&
				selectedItesm.length === 1 &&
				!selectedItesm.includes(value)
			) {
				setSelectedItems((prev: any) => {
					const arr = [...prev];
					const start = arr.shift();
					const end = value;
					const direction = start[0] === '1' || start[0] === '2' ? 'top' : 'bottom';
					const startIdx = dentalFormula[direction].findIndex(
						(item: any) => item.id === start,
					);
					const endIdx = dentalFormula[direction].findIndex(
						(item: any) => item.id === end,
					);
					const correct_start = startIdx > endIdx ? end : start;
					const correct_end = startIdx > endIdx ? start : end;
					arr.unshift(correct_start);
					arr.push(correct_end);

					setIntermediateItems(generateSequence(start, end));
					return [...arr];

					// data
					// [...prev, value];
				});
			} else if (
				selectMany &&
				selectedItesm.length === 2 &&
				!selectManyAll &&
				!selectedItesm.includes(value) &&
				!intermediateItems.includes(value)
			) {
				setSelectedItems([value]);
				setIntermediateItems([]);
			} else if (
				!selectMany &&
				selectManyAll &&
				!selectedItesmAll.includes(value) &&
				selectedItesm.length === 0
			) {
				setSelectedItemsAll((prev: any) => [...prev, value]);
			} else if (
				!selectMany &&
				selectManyAll &&
				selectedItesmAll.includes(value) &&
				selectedItesm.length === 0
			) {
				setSelectedItemsAll((prev: any) => {
					const filter_items = prev.filter((item: any) => item !== value);
					return [...filter_items];
				});
			}
		},
		[
			dentalFormula,
			generateSequence,
			intermediateItems,
			selectMany,
			selectManyAll,
			selectedItesm,
			selectedItesmAll,
			setIntermediateItems,
			setSelectedItems,
			setSelectedItemsAll,
		],
	);

	const [showToothInfo, setShowToothInfo] = useState(null);
	const [circleChosen, setCircleChosen] = useState(false);

	const getItemStyles = useCallback(
		(data: any, isCircle: boolean) => {
			if ((data.implant || data.bridge || data.crown) && isPrint) {
				return '0 0 0 3px #000000';
			}
			if ((!data.implant || !data.bridge || !data.crown) && isPrint) {
				return 'none';
			}
			if (selectMany && selectedItesm?.includes(data.id)) {
				return '0 0 0 3px #19ABED';
			} else if (selectMany && intermediateItems?.includes(data.id)) {
				return '0 0 0 3px #0D6EFD';
			} else if (
				(!selectMany && selectedItesm?.includes(data.id)) ||
				(selectManyAll && selectedItesmAll?.includes(data.id))
			) {
				return '0 0 0 3px #0D6EFD';
			} else if (isCircle) {
				return 'none';
			} else {
				return '0 0 0 1px rgba(0, 0, 0, 0.25)';
			}
		},
		[intermediateItems, isPrint, selectMany, selectManyAll, selectedItesm, selectedItesmAll],
	);

	const handleToothClick = useCallback(
		(data: any) => {
			if (selectedItesm.includes(data.id)) {
				if (circleChosen) {
					setCircleChosen(false);
					onToothClick(data);
					onToothCircleClick(null);
				} else {
					selectItem(data.id);
					onToothClick(null);
				}
			} else {
				selectItem(data.id);
				setCircleChosen(false);
				onToothClick(data);
				onToothCircleClick(null);
			}
		},
		[circleChosen, onToothCircleClick, onToothClick, selectItem, selectedItesm],
	);

	const handleToothCircleClick = useCallback(
		(data: any) => {
			if (selectedItesm.includes(data.id)) {
				if (circleChosen) {
					selectItem(data.id);
					setCircleChosen(false);

					onToothCircleClick(null);
				} else {
					setCircleChosen(true);
					onToothCircleClick(data);
					onToothClick(null);
				}
			} else {
				selectItem(data.id);
				setCircleChosen(true);
				onToothCircleClick(data);
				onToothClick(null);
			}
		},
		[circleChosen, onToothCircleClick, onToothClick, selectItem, selectedItesm],
	);

	return (
		<>
			<div
				key={`${key_custom}-div`}
				onMouseLeave={() => {
					setShowToothInfo(null);
				}}
				style={{
					transform: data.transform ? 'rotate(180deg)' : 'none',
				}}
				className='d-flex flex-column gap-2 theeth-item border-0'>
				<div
					key={`${key_custom}-div-1`}
					className='position-relative'
					style={{
						boxShadow:
							circleChosen
								? '0 0 0 1px rgba(0, 0, 0, 0.25)'
								: getItemStyles(data, false),
						boxSizing: 'border-box',
						position: 'relative',
						background: data.empty ? 'none' : 'rgb(255 255 255 / 80%)',
						borderRadius: 5,
					}}>
					{(data.deletion || data.referral || data.milk_theeth) &&
						pocedurseDelRef(data, direction)}

					<div
						key={`${key_custom}-div-2`}
						style={{ transform: data.transform ? 'rotate(180deg)' : 'none' }}
						className='theeth-title col-12 d-flex justify-content-center aligin-items-center'>
						<span
							className={
								data.empty && isPrint
									? 'fw-bold fs-4 text-center cursor-pointer text-muted'
									: 'fw-bold fs-4 text-center cursor-pointer'
							}>
							{data.id}
						</span>
					</div>
					<div
						key={`${key_custom}-div-3`}
						onClick={() => {
							if (!isPrint) {
								handleToothClick(data);
								nullifyCards();
							}
						}}
						style={{
							position: 'relative',
							transform: data.transform ? 'rotate(180deg)' : 'none',
						}}
						className={`theeth cursor-pointer align-items-${
							data.transform ? 'start' : 'end'
						}`}>
						{(data.deletion === false || data.referral === false) &&
							pocedurse(data, direction)}
						<Button
							key={`${key_custom}-Button`}
							className='theeth-btn'
							isOutline={false}
							isDisable={
								selectedItesm.length > 0 ? direction === selectedDirection : false
							}
							isActive={false}
							style={{
								borderColor: 'none',
								border: 'none',
								opacity: data.empty ? 0.3 : 1,
							}}>
							{isPrint ? data?.printImage : data?.image}
						</Button>
					</div>
					{showToothInfo && !isPrint && (
						<div
							className='position-absolute'
							style={{
								left: data.transform ? '0%' : '0%',
								top: data.transform ? '0%' : '0%',
								transform: data.transform ? 'rotate(180deg)' : 'none',
								minWidth: '14.5rem',
								zIndex: 2000,
							}}>
							<Card className={`d-flex event-direction mb-0 p-1 px-3 gap-0`}>
								<CardHeader className='p-0'>
									<div
										className='d-flex align-items-center justify-content-around m-1 bg-info'
										style={{ width: 25, height: 25, borderRadius: 3 }}>
										<p className='m-0 fs-5 fw-bold text-light'>{data.id}</p>
									</div>
									<div className='d-flex gap-3'>
										<CardLabel>
											<CardTitle>Корни и каналы</CardTitle>
										</CardLabel>
									</div>
								</CardHeader>
								<CardBody className='py-1 px-0'>
									<div className='d-flex gap-2'>
										<T_CHANNELS width={45} height={60} />
										<div className='d-flex flex-column gap-1 align-items-start'>
											<p className='m-0'>
												{data.disease.root.map((root: any, idx: number) => (
													<>{`${root.label} `}</>
												))}
											</p>
											<HighlightedTitle title='Кол. корней' />
											<div
												className='d-flex p-1 align-items-center gap-2 rounded-1 border-1 border-light fs-6 text-black'
												style={{
													background: '#F8F9FA',
													borderColor: '#E7E7E7',
												}}>
												{data.roots_count}
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					)}
				</div>
				{!isPrint && (
					<div
						key={`${key_custom}-div-5`}
						style={{
							transform: data.transform ? 'rotate(180deg) scale(1.3)' : 'scale(1.3)',
							opacity: selectMany || selectManyAll ? 0.5 : 1,
							transition: '0.4s',
							pointerEvents: selectMany || selectManyAll ? 'none' : 'all',
						}}
						className='theeth-color-info col-12 d-flex justify-content-center align-items-center'>
						<div
							key={`${key_custom}-div-6`}
							className='cursor-pointer col-12 d-flex flex-wrap justify-content-center theeth-item-color-info theeth-item-color-info-one'
							onClick={() => {
								handleToothCircleClick(data);
								nullifyCards();
							}}
							onMouseEnter={() => {
								setShowToothInfo(data);
							}}
							style={{
								boxShadow: circleChosen ? getItemStyles(data, true) : '',
							}}>
							<div key={`${key_custom}-div-7`} className='col-6 p-0 m-0 h-50'>
								{enamel.top_left}
							</div>
							<div key={`${key_custom}-div-8`} className='col-6 p-0 m-0 h-50'>
								{enamel.top_right}
							</div>
							<div key={`${key_custom}-div-9`} className='col-6 p-0 m-0 h-50'>
								{enamel.bottom_left}
							</div>
							<div key={`${key_custom}-div-10`} className='col-6 p-0 m-0 h-50'>
								{enamel.bottom_right}
							</div>
							<div
								key={`${key_custom}-div-11`}
								style={{
									position: 'absolute',
									top: '0.6rem',
									bottom: '0.6rem',
									left: '0.6rem',
									right: '0.6rem',
									inset: '0.6rem',
								}}
								className='col-6'>
								{enamel.center}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TheethItemComponent;
