import React, { useContext, useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../../../components/bootstrap/Modal';
import Button from '../../../../../../components/bootstrap/Button';
import { formatDate, formatPrice } from '../../../../../../utils/functions';
import DentalContextFunctions from '../../../../../../contexts/dentalContextFunctions';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { StageService } from '../../../../../../custom/api/service/stage.service';
import { useQuery } from 'react-query';
import Loading from '../../../../../../custom/components/loading/Loading';

interface PrintModalCosProps {
	data: any;
	onClose: () => void;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
}

export const PrintModalCos: React.FC<PrintModalCosProps> = ({
	data,
	isOpen,
	setIsOpen,
	onClose,
}) => {
	const { setServicesFormData } = useContext(DentalContextFunctions);

	useEffect(() => {
		if (data?.services) setServicesFormData(JSON?.parse(JSON?.stringify(data?.services)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const [loading, setLoading] = useState<boolean>(false);
	const generatePDF = () => {
		setLoading(true);
		const contentElement = document.getElementById('pdf-content');
		if (!contentElement) {
			console.error('PDF content not found.');
			return;
		}
		const clonedContent = contentElement.cloneNode(true) as HTMLElement;

		const pdfPages = clonedContent.querySelectorAll('.pdf-page');
		pdfPages.forEach((page) => {
			const titleElement = document.createElement('h2');
			titleElement.style.textAlign = 'center';
			titleElement.textContent = 'ПЛАН ЛЕЧЕНИЯ';
			page.insertBefore(titleElement, page.firstChild);
		});

		const options = {
			margin: 0.2,
			filename: `Act_${data?.visit?.estimate?.act?.id}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 3, logging: false },
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['css'], before: '.pdf-page', avoid: ['div'] },
		};

		html2pdf()
			.set(options)
			.from(clonedContent)
			.save()
			.then(() => {
				setLoading(false);
			})
			.catch((err: any) => {});
	};

	const { data: stage }: { data: IStage | any; isLoading: boolean } = useQuery(
		['stage', data?.estimate?.stageUuid],
		() => StageService.findOne(data?.estimate?.stageUuid),
		{
			refetchOnWindowFocus: false,
			enabled: !!data?.estimate?.stageUuid,
		},
	);

	// const getServiceDetails = (service: any) => {
	// 	if (!service || !service.service_type) return '';

	// 	switch (service.service_type) {
	// 		case 'implant':
	// 			return [service.brand, service.size, service.implantName].filter(Boolean).join(' ');

	// 		case 'bridge':
	// 			return [service.restoration_type, service.fixation_type].filter(Boolean).join(' ');

	// 		default:
	// 			return null;
	// 	}
	// };

	return (
		<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} modalLevel={1056} size='xl'>
			<ModalHeader setIsOpen={setIsOpen}>
				<h5>Печатная форма</h5>
			</ModalHeader>
			<ModalBody
				className='modal-content'
				style={{
					overflow: 'auto',
					minHeight: '80vh',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<div
					id='pdf-content'
					style={{
						background: '#fff',
						color: '#000',
						width: '210mm',
						padding: '5mm 12mm 5mm 12mm',
						boxSizing: 'border-box',
						fontSize: '12pt',
						// zoom: 0.75,
						fontFamily: 'Tahoma',
					}}>
					<h2 style={{ textAlign: 'center' }}>ПЛАН ЛЕЧЕНИЯ</h2>
					<p>Акт № {data?.visit?.estimate?.act?.id}</p>
					<p>На основе сметы № {data?.visit?.estimate?.id}</p>
					<p>
						Пациент: {data?.visit?.patient?.name} {data?.visit?.patient?.middleName}
						{data?.visit?.patient?.surname}
					</p>
					<p>
						Дата создания:{' '}
						{data?.visit?.estimate?.act?.createdAt &&
							formatDate(new Date(data?.visit?.estimate?.act?.createdAt)).date2}
					</p>

					<hr />

					<div className='d-flex flex-column'>
						<span style={{ marginBottom: '2mm' }} className='fw-bold fs-3'>
							Анализ кожи
						</span>
						{data?.list?.rightMindalData?.map((item: any, index: number) => (
							<React.Fragment key={index}>
								<div className='d-flex col-12'>
									<div className='d-flex col-5'>
										<span className='fw-bold'>{item.title}:</span>
									</div>
									<div className='d-flex col-7'>
										<span>{item.selected}</span>
									</div>
								</div>
								<hr
									className='d-block'
									style={{
										margin: 0,
										opacity: 1,
										marginTop: '2mm',
										height: '2px', // Ensure it's interpreted as a pixel value
										backgroundColor: 'black',
									}}
								/>
							</React.Fragment>
						))}
						{data?.list?.globalData?.map((item: any, index: number) => (
							<React.Fragment key={index}>
								<div className='d-flex col-12'>
									<div className='d-flex col-5'>
										<span className='fw-bold'>{item.title}:</span>
									</div>
									<div className='d-flex col-7'>
										<span> {item.value}</span>
									</div>
								</div>
								<hr
									className='d-block'
									style={{
										margin: 0,
										opacity: 1,
										marginTop: '2mm',
										height: '2px', // Ensure it's interpreted as a pixel value
										backgroundColor: 'black',
									}}
								/>
							</React.Fragment>
						))}
					</div>

					<div
						className='d-flex flex-column'
						style={{
							marginTop: '5mm',
						}}>
						<span style={{ marginBottom: '2mm' }} className='fw-bold fs-3'>
							Состояние
						</span>
						{data?.list?.rightMindalData?.map((item: any, index: number) => (
							<React.Fragment key={index}>
								<div className='d-flex col-12'>
									<div className='d-flex col-5'>
										<span className='fw-bold'>{item.title}:</span>
									</div>
									<div className='d-flex col-7'>
										<span style={{ marginLeft: '3mm' }}> {item.selected}</span>
									</div>
								</div>
								<hr
									className='d-block'
									style={{
										margin: 0,
										opacity: 1,
										marginTop: '2mm',
										height: '2px', // Ensure it's interpreted as a pixel value
										backgroundColor: 'black',
									}}
								/>
							</React.Fragment>
						))}
					</div>
					<div style={{ marginBottom: 10 }}>
						<p
							style={{ margin: 0, padding: 0, marginTop: 15 }}
							className='fs-4 fw-bold'>
							Итоги осмотра:
						</p>
						<span style={{ margin: 0, padding: 0 }} className='fs-5'>
							{data?.list?.description}
						</span>
					</div>

					<div>
						<p
							style={{ margin: 0, padding: 0, marginTop: 15 }}
							className='fs-4 fw-bold'>
							Мероприятия
						</p>
						<span className='fs-5 p-0'>
							{stage?.title}
							<hr style={{ marginTop: 2 }} />
						</span>

						{data?.services?.length > 0 ? (
							<table
								style={{
									width: '100%',
									borderCollapse: 'collapse',
									marginBottom: '0',
								}}>
								<thead>
									<tr>
										<th>№</th>
										<th>Мероприятия</th>
										<th className='text-center'>Цена за ед.</th>
										<th className='text-center'>Кол-во</th>
										<th className='text-center'>Всего</th>
									</tr>
								</thead>
								<tbody>
									{data?.services?.map((service: any, index: number) => (
										<React.Fragment key={`${index}-${service?.event_uuid}`}>
											<tr>
												<td>{index + 1}</td>
												<td className='col-12' style={{ display: 'block' }}>
													{service?.title}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	key={`${i}-${service.event_uuid}-material`}>
																	{material.name} {material.size}
																</div>
															),
														)}
												</td>
												<td className='text-left col-2'>
													{formatPrice(service.price, 'AMD', 'ru-RU')}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-price`}>
																	{formatPrice(
																		material.price,
																		'AMD',
																		'ru-RU',
																	)}
																</div>
															),
														)}
												</td>
												<td className='text-center col-2'>
													{service?.count}{' '}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-count`}>
																	{material.count}
																</div>
															),
														)}
												</td>
												<td className='text-left col-1'>
													{formatPrice(service.amount, 'AMD', 'ru-RU')}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-amount`}>
																	{formatPrice(
																		material.amount,
																		'AMD',
																		'ru-RU',
																	)}
																</div>
															),
														)}
												</td>
											</tr>
											<tr>
												<td colSpan={5} style={{ padding: 0 }}>
													<div
														style={{
															borderTop: '1px solid #ccc',
															margin: '0',
														}}></div>
												</td>
											</tr>
										</React.Fragment>
									))}
								</tbody>
							</table>
						) : (
							<p className='fs-3 text-center'>нет услуг</p>
						)}
						{/* <p>Итого за этап: {stage?.stageTotal} AMD</p> */}
					</div>
					{data?.services?.length > 0 && (
						<div className='d-flex flex-column align-items-end justify-content-end'>
							<p style={{ marginTop: 10 }} className='fs-5 fw-bold text-right'>
								Всего:{' '}
								<span className='fs-5 fw-bold text-decoration-line-through'>
									{data?.visit?.estimate?.act?.amount}
								</span>
								<span className='fs-5 fw-bold'>
									- {data?.visit?.estimate?.discount_amount}{' '}
									{data?.visit?.estimate?.discount_type === 'percent' ? '%' : ''}
								</span>
							</p>
							<p style={{ marginBottom: 0 }} className='fs-3 fw-bold text-right'>
								Итого:{' '}
								{formatPrice(
									data?.visit?.estimate?.act?.total_amount,
									'AMD',
									'ru-RU',
								)}
							</p>
						</div>
					)}

					<hr style={{ marginTop: 0 }} />
					<p className='fw-bold'>
						После обследования и беседы с врачом мне понятен план, объём лечения,
						возможные осложнения на этапах лечения и с их учётом изменения плана
						лечения, этапов лечения, необходимость возможного дополнительного
						обследования, изменения сроков лечения, дополнительной оплаты.
						<br />Я получил(а) ответы на все мои вопросы и доверяю квалификации врача.
					</p>
					<br />
					<div className='d-flex justify-content-between'>
						<p className='fw-bold'>На лечение согласен (на)</p>
						<span> ___________________________</span>
					</div>
					<div className='d-flex justify-content-between'>
						<p className='fw-bold'>С условиями оплаты согласен (на)</p>
						<span> ___________________________</span>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					isDisable={loading}
					color='info'
					className='position-relative'
					isLight
					onClick={generatePDF}>
					<Loading loading={loading} />
					Скачать PDF
				</Button>
			</ModalFooter>
		</Modal>
	);
};
