import React, { FC } from 'react';
import Tooltips from '../../../../../../../components/bootstrap/Tooltips';
import Input from '../../../../../../../components/bootstrap/forms/Input';
import { formatPrice } from '../../../../../../../utils/functions';


interface IMaterial {
	data: any;
	changeData(...args: unknown[]): unknown;
	index: number;
	canEdit: boolean;
	disabled: boolean;
}

export const Material: FC<IMaterial> = ({ data, changeData, index, canEdit, disabled }) => {
	const countSpecificUUID = (arr: any, targetUUID: any) => {
		const count = arr.reduce((count: any, obj: any) => {
			return obj.uuid === targetUUID ? count + 1 : count;
		}, 0);
		if (!count) {
			return 1;
		} else {
			return count;
		}
	};

	return (
		<>
			<div className='col-12'>
				{data?.map((item: any, index_material: number) => (
					<span>
						{item.name && item.size && (
							<div className='col-12 d-flex justify-content-between '>
								<div className='col-5 d-flex'>
									<div className='col-3  d-flex align-items-center '>
										<span className='overflow-hidden w-100  d-flex align-items-center  text-center'>
											<Tooltips
												title={item?.name}
												placement='top'
												flip={['top']}>
												<span className='text-truncate p-2'>
													{item?.name}
												</span>
											</Tooltips>
										</span>
									</div>
									<div className=' d-flex align-items-center '>
										<span className='overflow-hidden w-100  d-flex align-items-center  text-center'>
											<Tooltips
												title={item?.size}
												placement='top'
												flip={['top']}>
												<span className='text-truncate p-2'>
													{item?.size}
												</span>
											</Tooltips>
										</span>
									</div>
								</div>
								<div className='col-5 d-flex justify-content-around'>
									<div className='col-5 d-flex justify-content-center align-items-center p-2'>
										<Input
											disabled={!disabled}
											name='price'
											value={item?.price}
											onChange={(e: any) => {
												changeData(e, 'material', index, index_material);
											}}
											className='w-100'
										/>
									</div>
									<div className='col-2 d-flex justify-content-center'>
										<span className='overflow-hidden w-100  text-center  d-flex align-items-center '>
											<Tooltips
												title={`${countSpecificUUID(data, item?.uuid)}`}
												placement='top'
												flip={['top']}>
												<span className='text-truncate  fw-bold text-center'>
													{countSpecificUUID(data, item?.uuid)}
												</span>
											</Tooltips>
										</span>
									</div>
									<div className='col-4 d-flex justify-content-center'>
										<span className='overflow-hidden w-100 text-center  d-flex align-items-center '>
											<Tooltips
												title={`${formatPrice(
													+countSpecificUUID(data, item?.uuid) *
														+item.price,
													'AMD',
													'ru-RU',
												)}`}
												placement='top'
												flip={['top']}>
												<span className='text-truncate  fw-bold'>
													{formatPrice(
														+countSpecificUUID(data, item?.uuid) *
															+item.price,
														'AMD',
														'ru-RU',
													)}
												</span>
											</Tooltips>
										</span>
									</div>
								</div>
							</div>
						)}
					</span>
				))}
			</div>
		</>
	);
};
