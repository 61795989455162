import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../../components/bootstrap/Modal';
import Button from '../../../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { ActService } from '../../../../../custom/api/service/act.service';
import qs from 'qs';
import { formatDate, formatPrice } from '../../../../../utils/functions';
import { notification, NotificationArgsProps } from 'antd';
import { ServiceComponent } from './ActComponents/ServicesComponent/ServiceComponent';
import Payment from './Payments';
import dayjs from 'dayjs';
import { PrintModal } from './ActPrintModal/PrintModal';
import { StageService } from '../../../../../custom/api/service/stage.service';
import { PrintModalCos } from './ActPrintModal/PrintModalCos';
import { PrintModalLor } from './ActPrintModal/PrintModalLor';
import { ConsultationListService } from '../../../../../custom/api/service/consulatation-list.service';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICreateActModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	setActVisit(...args: unknown[]): unknown;
	estimate: any;
	visit: any;
}

type PaymentItem = {
	status: string | null;
	date?: string; // or `date?: Date` if it's a Date object
	// other fields in the item object
};

const CreateActModal: FC<ICreateActModalProps> = ({
	isOpen,
	setIsOpen,
	estimate,
	visit,
	setActVisit,
}) => {
	const queryClient = useQueryClient();

	const { data: act } = useQuery<any, any>(
		['act', estimate?.uuid, isOpen],
		() => ActService.getActByEstimate(estimate?.uuid),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!estimate?.uuid,
		},
	);

	const { data: payments_data } = useQuery<any, any>(
		['payments_data', act?.id, isOpen],
		() =>
			ActService.getPayment(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						actId: act?.id,
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!act?.id,
		},
	);

	const [payment, setPayment] = useState<IStage>({
		actId: act?.id,
		estimateUuid: estimate?.uuid,
		payments: [
			{
				payment_type: 'личные средства',
				payment_amount: 0,
				status: null,
				date: dayjs().format('YYYY-MM-DD'),
			},
		],
	});

	const { data: stage }: { data: IStage | any; isLoading: boolean } = useQuery(
		['stage', estimate?.stageUuid],
		() => StageService.findOne(estimate?.stageUuid),
		{
			refetchOnWindowFocus: false,
			enabled: !!estimate?.stageUuid,
		},
	);

	const { data: consultation_list } = useQuery<any>(
		['consultation_list', stage?.uuid],
		async () => {
			return await ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage?.uuid,
							},
						},
					],
				}),
			);
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!stage?.uuid,
		},
	);

	useEffect(() => {
		if (payments_data && act && estimate) {
			const payments = payments_data?.data.data?.map((item: any) => ({
				payment_amount: item.payment_amount,
				payment_type: item.payment_type,
				status: item.status,
				date: dayjs(item.createdAt).format('YYYY-MM-DD'),
			}));
			setPayment((prev: any) => ({
				actId: act?.id,
				estimateUuid: estimate?.uuid,
				payments: [
					...payments,
					...(estimate.status !== 'оплаченный'
						? [
								{
									payment_type: 'личные средства',
									payment_amount: 0,
									status: null,
									date: dayjs().format('YYYY-MM-DD'),
								},
						  ]
						: []),
				],
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payments_data]);

	const [localServices, setLocalServices] = useState<any[]>([]);

	useEffect(() => {
		if (estimate?.services) {
			const updatedServices = estimate.services.map((service: any) => service.event);
			setLocalServices(updatedServices);
		}
	}, [estimate?.services]);

	const resetEstimateState = () => {
		setPayment((prev) => ({
			...prev,
			payments: [...prev.payments],
		}));
		setIsOpen(false);
	};

	const handleEstimateError = (error: any) => {
		const messages: { [key: string]: string } = {
			act_not_found: 'Акт не найден',
			payment_amount_cant_be_0: 'Общая сумма платеже должна быть больше нуля',
			payment_cant_be_greater_than_amount: 'Сумма платежей не может превышать сумму долга',
			estimat_not_found: 'Смета не найдена',
		};

		const message = messages[error.response.data.message];
		if (message) openNotificationService('bottomRight', message);
	};

	const { mutate: createPayment } = useMutation(
		(paymentData: any) => ActService.createPayment({ ...paymentData }),
		{
			onSuccess: (response) => {
				queryClient.invalidateQueries('visits');
				queryClient.invalidateQueries('visit');
				queryClient.invalidateQueries('estimate');
				queryClient.invalidateQueries('payments_data');
				queryClient.invalidateQueries('act');
				resetEstimateState();
			},
			onError: (error) => {
				handleEstimateError(error);
			},
		},
	);

	const [api, contextHolder] = notification.useNotification();

	const openNotificationService = useCallback(
		(placement: NotificationPlacement, description: string) => {
			api.info({
				message: 'Важно!',
				description,
				placement,
				className: 'ant-notification-custom',
				props: { style: { zIndex: 150000 } },
			});
		},
		[api],
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const data = payment?.payments
			?.filter((item: PaymentItem) => item.status === null)
			.map(({ date, ...rest }: PaymentItem) => rest);

		createPayment({
			...payment,
			payments: data,
		});
	};

	const calcAmount = () => {
		const payments_amount = payment?.payments?.reduce((acc: any, item: any) => {
			return acc + +item.payment_amount;
		}, 0);
		return act?.total_amount - payments_amount;
	};

	const reset = useCallback(() => {
		setPayment((prev) => ({
			actId: '',
			estimateUuid: '',
			payments: [],
		}));
		setActVisit(null);
		setIsOpen(false);
	}, [setActVisit, setIsOpen]);

	const [printOpen, setPrintOpen] = useState(false);
	const openPrintModal = () => {
		setPrintOpen(true);
	};
	return (
		<>
			<Modal isStaticBackdrop isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='xl'>
				<ModalHeader closeModalCustom={reset} setIsOpen={setIsOpen} className='p-4'>
					<></>
				</ModalHeader>
				<form onSubmit={handleSubmit} className='px-1'>
					<ModalBody className='px-4 pb-0'>
						<span>{contextHolder}</span>
						<div className='d-flex justify-content-between w-100 pb-4'>
							<div className='d-flex gap-3'>
								<span className='fs-3 fw-bold'>Акт №{act?.id}</span>
								<div className='d-flex gap-2'>
									<span className='fs-3 fw-bold'>Итого к оплате:</span>
									<span
										style={{
											color: !act?.total_amount_for_pay ? 'green' : 'red',
										}}
										className='fs-3 fw-bold'>
										{formatPrice(act?.total_amount, 'AMD', 'ru-RU')}
									</span>
								</div>
							</div>
							<Button color='info' isLight icon='Print' onClick={openPrintModal}>
								Распечатать
							</Button>
						</div>
						<div className='d-flex align-items-center gap-4'>
							<div className='d-flex flex-column gap-3'>
								<p className='m-0 fs-6'>
									Пациент:{' '}
									{`${visit?.patient?.name} ${visit?.patient?.middleName} ${visit?.patient?.surname}`}
								</p>
								<p className='m-0 fs-6'>
									Дата создания:{' '}
									{visit?.createdAt &&
										formatDate(new Date(visit.createdAt)).date2}
								</p>
							</div>
							<div className='d-flex flex-column gap-3'>
								<p className='m-0 fs-6'>
									На основе мед. случая №{visit?.medicalCase?.medicalCaseId}
								</p>
								<p className='m-0 fs-6'>
									План лечения: {visit?.medicalCase?.title}
								</p>
							</div>
						</div>
						<ServiceComponent
							isEstimate={false}
							choosenServices={[]}
							canEdit={false}
							data={localServices}
							changeData={() => {}}
						/>
						<div className='d-flex gap-2'>
							<span className='fs-3 fw-bold'>Оплаты:</span>
						</div>
						<Payment
							setPayment={setPayment}
							payment={payment}
							status={!act?.total_amount_for_pay}
						/>
						<div className='d-flex gap-2'>
							<span className='fs-3 fw-bold'>
								{!act?.total_amount_for_pay ? 'Оплачено:' : 'Остаток:'}
							</span>
							<span
								style={{
									color: !act?.total_amount_for_pay ? 'green' : 'red',
								}}
								className='fs-3 fw-bold'>
								{formatPrice(
									!act?.total_amount_for_pay ? act?.total_amount : calcAmount(),
									'AMD',
									'ru-RU',
								)}
							</span>
						</div>
						<hr />
					</ModalBody>
					{!!act?.total_amount_for_pay && (
						<ModalFooter className='px-4'>
							<div className='d-flex justify-content-between w-100'>
								<Button
									isDisable={
										!payment.payments.find((item: any) => item.status === null)
									}
									type='submit'
									className='fw-bold fs-5 w-100 p-2'
									color='info'>
									Принять оплату
								</Button>
							</div>
						</ModalFooter>
					)}
				</form>
			</Modal>

			{stage?.type === 'лор' && (
				<PrintModalLor
					data={{
						services: localServices,
						visit,
						estimate,
						list: consultation_list?.data[0]?.dentalFormula,
					}}
					onClose={() => setIsOpen(false)}
					isOpen={printOpen}
					setIsOpen={setPrintOpen}
				/>
			)}
			{stage?.type === 'косметология' && (
				<PrintModalCos
					data={{
						services: localServices,
						visit,
						estimate,
						list: consultation_list?.data[0]?.dentalFormula,
					}}
					onClose={() => setIsOpen(false)}
					isOpen={printOpen}
					setIsOpen={setPrintOpen}
				/>
			)}

			{stage?.type === 'стоматология' && (
				<PrintModal
					data={{ services: localServices, visit, estimate }}
					onClose={() => setIsOpen(false)}
					isOpen={printOpen}
					setIsOpen={setPrintOpen}
				/>
			)}
		</>
	);
};

export default CreateActModal;
