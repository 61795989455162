import { authHost } from '../axios';

export class MaterialService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<any[] | null> {
		const { data } = await authHost.get(`material?${query}`);
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`material/${uuid}`);
		return data;
	}

	static async create(data: any) {
		const visit_services = await authHost.post('material', data);
		return visit_services;
	}

	static async createFavorite(data: any) {
		const visit_services = await authHost.post('material', data);
		return visit_services;
	}

	static async update(uuid: string, data: any) {
		const visit_services = await authHost.patch(`material/${uuid}`, data);
		return visit_services;
	}
}
