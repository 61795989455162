import React, { FC, memo } from 'react';
import Button from '../../../../../../components/bootstrap/Button';

interface IResetComponentProps {
	reset(...args: unknown[]): unknown;
}

const ResetComponent: FC<IResetComponentProps> = memo(({ reset }) => {
	return (
		<>
			<Button color='info' onClick={reset} isLight>
				Сбросить фильтры
			</Button>
		</>
	);
});

export default ResetComponent;
