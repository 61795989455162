import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';
import TableComponent from '../../components/tables/TableComponent';
import { getFontSizeFromLocalStorage } from '../../../utils/functions';
import { TColor } from '@textnpayme/type/color-type';
import MaterialsFilterComponent from './components/UI/FilterComponent/MaterialsFilterComponent';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	materials: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
}

const MaterialsTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		materials,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
	}) => {
		const [searchIconClicked, setSearchIconClicked] = useState(false);
		const isLargeDesktop = useMediaQuery({ query: '(min-width: 1620px)' });
		const isSmallDesktop = useMediaQuery({ query: '(max-width: 1300px)' });
		const isTablet = useMediaQuery({ query: '(max-width: 1250px)' });
		const [canvasColor, setCanvasColor] = useState<TColor>('light');

		const getRootFontSize = () =>
			parseFloat(getComputedStyle(document.documentElement).fontSize);
		const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

		const updateFontSize = () => {
			const fontSizeValue = getFontSizeFromLocalStorage();
			setDynamicFont(fontSizeValue);
		};

		useEffect(() => {
			updateFontSize();
			const handleFontSizeChange = () => {
				updateFontSize();
			};
			window.addEventListener('fontSizeChange', handleFontSizeChange);

			return () => {
				window.removeEventListener('fontSizeChange', handleFontSizeChange);
			};
		}, []);

		const tableHeaders = [
			{ header: '№', key: 'index' },
			{ header: 'Название', key: 'item_name' },
			{ header: 'Код материала', key: 'code' },
			{ header: 'Бренд', key: 'brand' },
			{ header: 'Филиал', key: 'clinic' },
			{ header: 'Склад', key: 'store_type' },
			{ header: 'Тип материала', key: 'material_type' },
			{ header: 'Категория', key: 'category' },
			{ header: 'Стоимость', key: 'price' },
			{ header: 'Ед.изм', key: 'unit_measurment' },
			{ header: 'Размеры', key: 'size' },
			// { header: 'Срок годности', key: 'expiration_date' },
		];

		const reset = useCallback(() => {
			setValues({
				specialization: [],
				serviceName: [],
				name: undefined,
				order: 'DESC',
			});
			setFilter({
				clinic: '',
				treatment_type: '',
				category: '',
				subCategory: '',
				store_type: '',
			});
		}, [setFilter, setValues]);
		return (
			<>
				<Card stretch={isFluid}>
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						{/* <SubHeaderRightComponent /> turn back, when api is ready */}
					</div>
					<div className='table-row-header'>
						<div className='d-flex flex-row align-items-center w-full grow'>
							<MaterialsFilterComponent
								values={values}
								filter={filter}
								isLargeDesktop={isLargeDesktop}
								isSmallDesktop={isSmallDesktop}
								isUserDoctor={isUserDoctor}
								isTablet={isTablet}
								searchIconClicked={searchIconClicked}
								setValues={setValues}
								setFilter={setFilter}
								setSearchIconClicked={setSearchIconClicked}
								canvasColor={canvasColor}
								dynamicFont={dynamicFont}
							/>
						</div>
						<div>
							<ResetComponent reset={reset} />
						</div>
					</div>
					{materials && (
						<CardBody className='table-responsive' isScrollable={isFluid}>
							<TableComponent
								data={materials?.length ? materials : [materials]}
								headers={tableHeaders}
								visitDropDownProps={undefined}
							/>
						</CardBody>
					)}
					{materials?.meta?.count > perPage && (
						<PaginationButtons
							data={materials || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(materials?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>
			</>
		);
	},
);
MaterialsTable.defaultProps = {
	isFluid: false,
};

export default MaterialsTable;
