import React from 'react';

export const TotalSum: React.FC<{
	text?: any;
	status: string[];
	className?: string | {};
}> = ({ text, status, className }) => {
	const getColor = (status: string[] | null) => {
		if (status?.includes('Завершен')) {
			return 'success';
		} else if (
			status?.includes('Отменен') ||
			status?.includes('Калькуляция') ||
			status?.includes('Задолженность')
		) {
			return 'danger';
		} else if (status?.includes('В процессе')) {
			return 'info';
		} else if (status?.includes('Предстоящий')) {
			return 'warning';
		} else if (status?.includes('Не оплачен') && status?.includes('Завершен')) {
			return 'success';
		} else {
			return 'success';
		}
	};
	const symbolInPrice =
		status.length >= 1
			? status?.every((s) => s?.includes('Калькуляция') || s?.includes('Задолженность'))
				? '-'
				: '+'
			: '+';

	const color = getColor(status);
	const formatNumber = (number: number) => {
		const parts = number.toFixed(2).split('.');
		const integerPart = parts[0];
		const decimalPart = parts[1];
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formattedIntegerPart}.${decimalPart}`;
	};

	return (
		<div className={`d-flex align-items-center ${className}`}>
			<div
				className={`rounded-2 d-flex align-items-center justify-content-center border-0 gap-3`}
				style={{ maxWidth: '100%' }}>
				<span className={`text-${color} fs-2 fw-bold align-text-bottom`}>Итого</span>
				<span
					className={`text-${color} fs-2 fw-bold text-nowrap`}>{`${symbolInPrice}${formatNumber(
					Number(
						status?.includes('Задолженность') && status.length === 1
							? text?.total_amount_for_pay
							: text?.total_amount,
					),
				)} AMD`}</span>
			</div>
		</div>
	);
};

export const Summary: React.FC<{
	number: number;
	status: string[];
	className?: string | {};
}> = ({ number, status, className }) => {
	const getColor = (number: number) => {
		if (number > 0) {
			return '';
		} else if (number < 0) {
			return 'danger';
		} else {
			return 'info';
		}
	};
	const symbolInPrice = number < 0 ? '' : '+';

	const color = getColor(number);
	const formatNumber = (number: number) => {
		const parts = number.toFixed(2).split('.');
		const integerPart = parts[0];
		const decimalPart = parts[1];
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formattedIntegerPart}.${decimalPart}`;
	};

	return (
		<div className={`d-flex align-items-center ${className}`}>
			<div
				className={`rounded-2 d-flex align-items-center justify-content-center border-0 gap-3`}
				style={{ maxWidth: '100%' }}>
				<span
					style={{ color: number > 0 ?'#26BE00' : 'inherit'}}
					className={`text-${color} fs-2 fw-bold text-nowrap`}>{`${symbolInPrice}${formatNumber(
					Number(number),
				)} AMD`}</span>
			</div>
		</div>
	);
};
