import React, { FC, memo, useEffect, useState } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../../../components/bootstrap/Button';
import Icon from '../../../../../../../components/icon/Icon';
import { VisitStatusStyles } from '../../../../../../../utils/functions';
import useDarkMode from '../../../../../../../hooks/useDarkMode';
import CustomSelect from '../../../../../../components/Select/select';
import FormGroup from '../../../../../../../components/bootstrap/forms/FormGroup';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { ClinicService } from '../../../../../../../custom/api/service/clinic.service';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { CategoryService } from '../../../../../../../custom/api/service/category.service';

interface IStatusFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenStatus(...args: unknown[]): unknown;
	popperIsOpenStatus: any;
	values: any;
	dynamicFont: any;
	changeValues: any;
	disabled: boolean;
}

const FilialFilterComponent: FC<IStatusFilterComponentProps> = memo(
	({ values, setPopperIsOpenStatus, popperIsOpenStatus, handleCheckboxChange }) => {
		const { themeStatus } = useDarkMode();
		const DebouncedSearch = useDebounce(values);
		const { data: clinics } = useQuery<IClinic | null, Error>(
			['clinics', DebouncedSearch],
			() =>
				ClinicService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		return (
			<Dropdown isOpen={popperIsOpenStatus} setIsOpen={setPopperIsOpenStatus}>
				<DropdownToggle>
					<Button color={themeStatus} className='text-nowrap me-2'>
						Филиалу
					</Button>
				</DropdownToggle>
				<DropdownMenu style={{ width: '400px' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						{clinics &&
							clinics.data.map((clinic: any, index: number) => (
								<div className='form-check col-10'>
									<input
										className='form-check-input'
										type='checkbox'
										value={clinic.uuid}
										id='flexCheckDefault-0'
										onChange={(e: any) => handleCheckboxChange(e)}
										checked={values.clinic === clinic.uuid}
										name='clinic'
									/>
									<label
										className='form-check-label'
										htmlFor={`flexCheckDefault-${index}`}>
										<span>{clinic.name}</span>
									</label>
								</div>
							))}
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default FilialFilterComponent;
