/* eslint-disable array-callback-return */
import React, { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import { notification, NotificationArgsProps } from 'antd';

import DentalContext from '../../../../../contexts/dentalContext';
import { deepCopyAll } from '../../../../../utils/functions';
type NotificationPlacement = NotificationArgsProps['placement'];

type ItemType =
	| 'bridge'
	| 'referral'
	| 'implant'
	| 'event'
	| 'deletion'
	| 'milk_theeth'
	| 'empty'
	| 'cosmetology'
	| 'lor';

interface ITheethDropdownItemsProps {
	index: number;
	data: any;
	type: ItemType;
	disable?: boolean;
	all_data: any;
	setData: any;
	setIsOpen(...args: unknown[]): unknown;
}

export const TheethDropdownItems: FC<ITheethDropdownItemsProps> = memo(
	({ index, data, setData, disable, all_data, type, setIsOpen }) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotificationBridgePlace = useCallback(
			(placement: NotificationPlacement, description: string) => {
				api.info({
					message: 'Важно!',
					description,
					placement,
					className: 'ant-notification-custom',
					props: { style: { zIndex: 150000 } },
				});
			},
			[api],
		);
		const { dentalFormulaDrodown, setDentalFormulaDropdown } = useContext(DentalContext);

		const [disableDirection, setDisableDirection] = useState<string | null>(null);
		const [isModified, setIsModified] = useState<boolean>(false);

		const [localDentalFormula, setLocalDentalFormula] = useState<any>(
			deepCopyAll(dentalFormulaDrodown) || { top: [], bottom: [] },
		);
		const [localFormData, setLocalFormData] = useState<any>(
			JSON.parse(JSON.stringify(all_data)) || [],
		);

		useEffect(() => {
			if (JSON.stringify(dentalFormulaDrodown) !== JSON.stringify(localDentalFormula)) {
				setLocalDentalFormula(deepCopyAll(dentalFormulaDrodown) || { top: [], bottom: [] });
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [dentalFormulaDrodown]);

		useEffect(() => {
			if (JSON.stringify(localFormData) !== JSON.stringify(all_data)) {
				setLocalFormData(deepCopyAll(all_data) || []);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [all_data]);

		const generateSequence = useCallback(
			(start: string | number, end: string | number, direction?: string): string[] => {
				if (!start || !end) {
					return [''];
				}
				let result: string[] = [];
				const startId = start?.toString();
				const endId = end?.toString();

				if (!direction) {
					direction = startId?.[0] === '1' || endId?.[0] === '2' ? 'top' : 'bottom';
				}

				const formula = localDentalFormula?.[direction];
				if (!Array.isArray(formula)) {
					console.error(`Invalid direction or data structure: ${direction}`);
					return result;
				}

				const startIndex = formula.findIndex((tooth: any) => tooth?.id === startId);
				const endIndex = formula.findIndex((tooth: any) => tooth?.id === endId);

				if (startIndex < 0 || endIndex < 0) {
					return result;
				}

				if (startIndex < endIndex) {
					for (let i = startIndex + 1; i < endIndex; i++) {
						result.push(formula[i]?.id);
					}
				} else {
					for (let i = startIndex - 1; i > endIndex; i--) {
						result.push(formula[i]?.id);
					}
				}

				return result;
			},
			[localDentalFormula],
		);

		const checkBridgeOverlap = useCallback(
			(start: string, end: string, direction: string, index: number) => {
				const startIdx = localDentalFormula?.[direction]?.findIndex(
					(tooth: any) => tooth?.id === start,
				);
				const endIdx = localDentalFormula?.[direction]?.findIndex(
					(tooth: any) => tooth?.id === end,
				);
				if (startIdx === -1 || endIdx === -1 || !localFormData) {
					return false;
				}
				const isOverlapping = localFormData?.some((existingBridge: any) => {
					const existingStartIdx = localDentalFormula?.[direction]?.findIndex(
						(tooth: any) => tooth?.id === existingBridge?.start,
					);
					const existingEndIdx = localDentalFormula?.[direction]?.findIndex(
						(tooth: any) => tooth?.id === existingBridge?.end,
					);

					if (existingStartIdx === -1 || existingEndIdx === -1) {
						return false;
					}

					const sortedStartIndex = Math.min(startIdx, endIdx);
					const sortedEndIndex = Math.max(startIdx, endIdx);
					const existingSortedStartIndex = Math.min(existingStartIdx, existingEndIdx);
					const existingSortedEndIndex = Math.max(existingStartIdx, existingEndIdx);

					const overlapsStart =
						sortedStartIndex >= existingSortedStartIndex &&
						sortedStartIndex <= existingSortedEndIndex;
					const overlapsEnd =
						sortedEndIndex >= existingSortedStartIndex &&
						sortedEndIndex <= existingSortedEndIndex;
					const containsExisting =
						sortedStartIndex <= existingSortedStartIndex &&
						sortedEndIndex >= existingSortedEndIndex;

					if (overlapsStart || overlapsEnd || containsExisting) {
						openNotificationBridgePlace('bottomRight', 'На эти зубах уже есть мост');
						setDisableDirection('');
						return true;
					}

					return false;
				});

				return isOverlapping;
			},
			[localDentalFormula, localFormData, openNotificationBridgePlace],
		);

		const updateTeethData = useCallback(
			(
				value: number | string,
				index: number,
				direction: string,
				teethIndex: number,
				type: ItemType,
				eventUuid: string,
			) => {
				const updatedFormData = localFormData.map((item: any) => {
					if (item.event_uuid === eventUuid) {
						const directionIndex =
							item.theeth[0] === '1' || item.theeth[0] === '2' ? 'top' : 'bottom';
						const currentTeethIndex = localDentalFormula[directionIndex]?.findIndex(
							(tooth: any) => tooth.id === item.theeth,
						);

						if (currentTeethIndex !== -1) {
							localDentalFormula[directionIndex][currentTeethIndex] = {
								...localDentalFormula[directionIndex][currentTeethIndex],
								[type]: false,
							};
						}

						const updatedItem = {
							...item,
							theeth: value,
						};

						const updatedDentalFormula = [...localDentalFormula[direction]];
						updatedDentalFormula[teethIndex] = {
							...updatedDentalFormula[teethIndex],
							[type]: true,
						};

						setLocalDentalFormula((prev: any) => ({
							...prev,
							[direction]: updatedDentalFormula,
						}));

						return updatedItem;
					}

					return item; // Return unchanged item if event_uuid doesn't match
				});

				setLocalFormData(updatedFormData);
				setIsModified(true);
			},
			[localDentalFormula, localFormData],
		);

		// Example of checking service_type in the addBridgeTeeth function
		const addBridgeTeeth = useCallback(
			(value: number | string, index: number, direction: string, eventUuid: string) => {
				setLocalFormData((prev: any) => {
					if (!Array.isArray(prev)) return prev;

					const updatedFormData = prev.map((item) => {
						if (item.event_uuid !== eventUuid) return item; // Return unchanged item if event_uuid doesn't match

						const bridge = { ...item };

						if (!bridge?.start && !bridge?.end) {
							bridge.start = String(value);
						} else if (bridge?.start && !bridge?.end) {
							if (String(value) === bridge.start) return item; // Prevent start and end being the same

							const overlap = checkBridgeOverlap(
								bridge.start,
								String(value),
								direction,
								index,
							);

							if (!overlap) {
								bridge.end = String(value);

								const startIdx = localDentalFormula[direction].findIndex(
									(tooth: any) => tooth.id === bridge.start,
								);
								const endIdx = localDentalFormula[direction].findIndex(
									(tooth: any) => tooth.id === bridge.end,
								);

								if (startIdx > -1 && endIdx > -1) {
									const correct_start =
										startIdx > endIdx ? bridge.end : bridge.start;
									const correct_end =
										startIdx > endIdx ? bridge.start : bridge.end;
										bridge.start = correct_start;
										bridge.end = correct_end;
									const sequence = generateSequence(
										correct_start,
										correct_end,
										direction,
									);

									const updatedFormula = localDentalFormula[direction].map(
										(tooth: any) => {
											if (tooth.id === correct_start) {
												return {
													...tooth,
													bridge: true,
													bridge_position: 'start',
												};
											} else if (tooth.id === correct_end) {
												return {
													...tooth,
													bridge: true,
													bridge_position: 'end',
												};
											} else if (sequence.includes(tooth.id)) {
												return {
													...tooth,
													bridge: true,
													bridge_position: 'center',
												};
											}
											return {
												...tooth,
												bridge: false,
												bridge_position: null,
											};
										},
									);

									setLocalDentalFormula((prev: any) => ({
										...prev,
										[direction]: updatedFormula,
									}));
								} else {
									bridge.start = '';
									bridge.end = '';
								}
							} else {
								bridge.start = '';
								bridge.end = '';
							}
						}

						return {
							...item,
							...bridge,
						};
					});

					setIsModified(true);

					return updatedFormData;
				});
			},
			[checkBridgeOverlap, generateSequence, localDentalFormula],
		);

		const handleTeethUpdate = useCallback(
			(
				value: any,
				index: number,
				direction: string,
				teethIndex: number,
				type: ItemType,
				uuid: string,
			) => {
				if (type === 'bridge') {
					addBridgeTeeth(value, index, direction, uuid);
				} else {
					updateTeethData(value, index, direction, teethIndex, type, uuid);
				}
			},
			[addBridgeTeeth, updateTeethData],
		);

		const disableButton = useCallback(
			(dataItemCheck: any, type: string, direction: string) => {
				return dataItemCheck[type] || disableDirection === direction;
			},
			[disableDirection],
		);

		const classColor = useCallback(
			(
				dataCheck: any,
				dataItemCheck: any,
				direction: string,
				type: string,
				eventUuid: string,
			) => {
				const matchingItem = localFormData.find(
					(item: any) => item.event_uuid === eventUuid,
				);

				if (!matchingItem) return '';

				const isInRange = generateSequence(matchingItem?.start, matchingItem?.end).includes(
					dataItemCheck?.id,
				);

				if (type === 'bridge' && matchingItem?.start && matchingItem?.end && isInRange) {
					return 'bg-info';
				}

				if (
					type !== 'bridge' &&
					(matchingItem?.theeth === dataItemCheck.id || dataItemCheck?.[type])
				) {
					return 'bg-info';
				}

				return '';
			},
			[localFormData, generateSequence],
		);

		const styleButton = useCallback(
			(dataCheck: any, dataItemCheck: any, type: string, eventUuid: string) => {
				const matchingItem = localFormData.find(
					(item: any) => item.event_uuid === eventUuid,
				);

				if (!matchingItem) {
					return {
						backgroundColor: '#FFFFFF',
						border: '2px solid #0D6EFD',
					};
				}

				if (
					type === 'bridge' &&
					(matchingItem?.start === dataItemCheck?.id ||
						matchingItem?.end === dataItemCheck?.id)
				) {
					return {
						backgroundColor: '#19ABED', // Highlight color for start or end
						border: 'none',
					};
				}

				return {
					backgroundColor: '#FFFFFF',
					border: '2px solid #0D6EFD',
				};
			},
			[localFormData],
		);

		const textButton = useCallback(
			(
				dataCheck: any,
				dataItemCheck: any,
				type: string,
				direction: string,
				eventUuid: string,
			) => {
				const matchingItem = localFormData.find(
					(item: any) => item.event_uuid === eventUuid,
				);

				if (!matchingItem) {
					return 'text-dark';
				}

				if (type === 'bridge') {
					const isStartOrEnd =
						matchingItem?.start === dataItemCheck?.id ||
						matchingItem?.end === dataItemCheck?.id;

					const isInRange = generateSequence(
						matchingItem?.start,
						matchingItem?.end,
						direction,
					).includes(dataItemCheck?.id);

					if (isStartOrEnd || isInRange) {
						return 'text-light';
					}
				} else if (type !== 'bridge') {
					if (dataCheck?.theeth === dataItemCheck?.id || dataItemCheck?.[type]) {
						return 'text-light';
					}
				}

				return 'text-dark';
			},
			[localFormData, generateSequence],
		);

		const setTheeth = useCallback(
			(
				theeth_item: any,
				index: any,
				direction: any,
				theeth_index: any,
				type: any,
				uuid: string,
			) => {
				handleTeethUpdate(theeth_item, index, direction, theeth_index, type, uuid);
				setDisableDirection(direction);
			},
			[handleTeethUpdate],
		);

		const save = useCallback(() => {
			setData(JSON.parse(JSON.stringify(localFormData)));
			setDentalFormulaDropdown(deepCopyAll(localDentalFormula));
			// setLocalDentalFormula(deepCopyAll(localDentalFormula));
			setIsOpen(false);
			setDisableDirection('');
		}, [localDentalFormula, localFormData, setData, setDentalFormulaDropdown, setIsOpen]);

		const cancel = useCallback(() => {
			setData(JSON.parse(JSON.stringify(all_data)));
			setDentalFormulaDropdown(deepCopyAll(dentalFormulaDrodown));
			setIsOpen(false);
			setDisableDirection('');
		}, [all_data, dentalFormulaDrodown, setData, setDentalFormulaDropdown, setIsOpen]);

		return (
			<div className='d-flex align-items-center justify-content-center flex-column gap-2 p-1'>
				<span>{contextHolder}</span>
				<div className='col-12 d-flex gap-2'>
					{localDentalFormula?.top?.map((theeth_top: any, theeth_index_top: number) => (
						<Button
							key={`${index}-top-${theeth_top.id}`}
							isOutline={false}
							isDisable={disableButton(theeth_top, type, 'bottom') || disable}
							onClick={() => {
								setTheeth(
									theeth_top?.id,
									index,
									'top',
									theeth_index_top,
									type,
									data?.event_uuid,
								);
							}}
							className='m-0 d-flex p-2 border-0 justify-content-center align-items-center'
							isLight>
							<div
								key={`${index}-top`}
								className={`d-flex p-2 align-items-center justify-content-center ${classColor(
									data,
									theeth_top,
									'top',
									type,
									data?.event_uuid,
								)}`}
								style={{
									width: 25,
									height: 25,
									borderRadius: 3,
									backgroundColor: styleButton(
										data,
										theeth_top,
										type,
										data?.event_uuid,
									).backgroundColor,
									border: styleButton(data, theeth_top, type, data?.event_uuid)
										.border,
								}}>
								<p
									className={`m-0 fs-6  ${textButton(
										data,
										theeth_top,
										type,
										'top',
										data?.event_uuid,
									)}`}>
									{theeth_top?.id}
								</p>
							</div>
						</Button>
					))}
				</div>
				<div className='col-12 d-flex gap-2'>
					{localDentalFormula?.bottom?.map(
						(theeth_bottom: any, theeth_index_bottom: number) => (
							<Button
								isOutline={false}
								isDisable={disableButton(theeth_bottom, type, 'top') || disable}
								key={`${index}-bottom-${theeth_bottom.id}`}
								onClick={() => {
									setTheeth(
										theeth_bottom?.id,
										index,
										'bottom',
										theeth_index_bottom,
										type,
										data?.event_uuid,
									);
								}}
								className='m-0 d-flex p-2 border-0 justify-content-center align-items-center'
								isLight>
								<div
									key={`${index}-bottom`}
									className={`d-flex p-2 align-items-center justify-content-center ${classColor(
										data,
										theeth_bottom,
										'bottom',
										type,
										data?.event_uuid,
									)}`}
									style={{
										width: 25,
										height: 25,
										borderRadius: 3,
										backgroundColor: styleButton(
											data,
											theeth_bottom,
											type,
											data?.event_uuid,
										).backgroundColor,
										border: styleButton(
											data,
											theeth_bottom,
											type,
											data?.event_uuid,
										).border,
									}}>
									<p
										className={`m-0 fs-6  ${textButton(
											data,
											theeth_bottom,
											type,
											'bottom',
											data?.event_uuid,
										)}`}>
										{theeth_bottom?.id}
									</p>
								</div>
							</Button>
						),
					)}
				</div>
				<div className='col-12 d-flex justif gap-2'>
					<Button
						onClick={save}
						color='success'
						isLight
						className='col'
						isDisable={!isModified || disable}>
						Сохранить
					</Button>
					<Button
						onClick={cancel}
						color='danger'
						isLight
						className='col'
						isDisable={!isModified || disable}>
						Отменить
					</Button>
				</div>
			</div>
		);
	},
);
