import React, { FC, memo, useState } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import Icon from '../../../../../components/icon/Icon';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import useDarkMode from '../../../../../hooks/useDarkMode';
import ReactInputMask from 'react-input-mask';

interface ISearchFilterCompnentProps {
	searchIconClicked: any;
	setSearchIconClicked(...args: unknown[]): unknown;
	handleCheckboxChange(...args: unknown[]): unknown;
	isLargeDesktop: any;
	isSmallDesktop: any;
	values: any;
	isTablet: any;
}

const SearchFilterCompnent: FC<ISearchFilterCompnentProps> = memo(
	({
		isTablet,
		isLargeDesktop,
		isSmallDesktop,
		setSearchIconClicked,
		handleCheckboxChange,
		values,
		searchIconClicked,
	}) => {
		const [search, setSearch] = useState('');
		const { themeStatus } = useDarkMode();
		return (
			<>
				{!isTablet ? (
					<>
						<Icon
							icon='Search'
							size='2x'
							color='primary'
							className='me-2'
							style={{ cursor: 'pointer' }}
							onClick={() => setSearchIconClicked(!searchIconClicked)}
						/>
						<Input
							color={themeStatus}
							type='search'
							name='visitSearch'
							value={values?.visitSearch}
							onChange={(e: any) => {
								handleCheckboxChange(e);
							}}
							placeholder={
								'Поиск пациента по Имени Фамилии Отчеству или по номеру ЭМК'
							}
							style={{
								width: isLargeDesktop ? 500 : isSmallDesktop ? 200 : 100,
							}}
						/>
					</>
				) : (
					<Dropdown isOpen={searchIconClicked} setIsOpen={setSearchIconClicked}>
						<DropdownToggle>
							<Icon
								icon='Search'
								size='2x'
								color='primary'
								className='me-2'
								style={{ cursor: 'pointer' }}
								onClick={() => setSearchIconClicked(!searchIconClicked)}
							/>
						</DropdownToggle>
						<DropdownMenu style={{ width: '400px', padding: '15px 0 15px 0' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexWrap: 'wrap',
								}}>
								<FormGroup
									style={{ marginTop: '15px' }}
									id='surname'
									label='Поиск пациента по Имени Фамилии Отчеству или по номеру ЭМК'
									className='col-10'>
									<Input
										color={themeStatus}
										type='search'
										value={search}
										onChange={(e: any) => {
											setSearch(e.target.value);
										}}
									/>
								</FormGroup>
							</div>
						</DropdownMenu>
					</Dropdown>
				)}
			</>
		);
	},
);
export default SearchFilterCompnent;
