import React, { FC, memo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../../../../hooks/useDarkMode';
import { TColor } from '../../../../../../type/color-type';
import { IVisit } from '../../../../../../custom/api/interface/visit.interface';
import { VisitService } from '../../../../../../custom/api/service/visit.service';
import * as qs from 'qs';
import { dayjsLocalizer, Views } from 'react-big-calendar';
import dayjs from 'dayjs';
import { getViews } from '../../../../../../components/extras/calendarHelper';
import { useQuery } from 'react-query';
import CalendarComponent from '././CalendarComponent/CalendarComponent';
import { calculateDuration, joinTimeAndDate } from '../../../../../../utils/functions';

const localizer = dayjsLocalizer(dayjs);
const now = new Date();

interface IHeaderProps {
	isUserDoctor: boolean;
	setTimePickerIsOpen(...args: unknown[]): unknown;
	setIsOpenSubmit(...args: unknown[]): unknown;
	setVisitData(...args: unknown[]): unknown;
	setChooseTiemModal(...args: unknown[]): unknown;
	openNotification(...args: unknown[]): unknown;
	setStartTime(...args: unknown[]): unknown;
	setDuration(...args: unknown[]): unknown;
	setEndTime(...args: unknown[]): unknown;
	setEnd(...args: unknown[]): unknown;
	setStart(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setViewMode(...args: unknown[]): unknown;
	usersList: any;
	prevVisit: any;
	visitData: any;
	viewMode: any;
	date: any;
	setDate: any;
	unitType: any;
}

const Calendar: FC<IHeaderProps> = memo(
	({
		isUserDoctor,
		usersList,
		setDuration,
		setTimePickerIsOpen,
		setChooseTiemModal,
		setVisitData,
		setIsOpenSubmit,
		openNotification,
		setEnd,
		setStart,
		changeValues,
		setStartTime,
		setEndTime,
		setViewMode,
		prevVisit,
		visitData,
		viewMode,
		date,
		setDate,
		unitType,
	}) => {
		const { darkModeStatus } = useDarkMode();
		const views = getViews();

		const eventStyleGetter = useCallback(
			(event: { color?: TColor }, start: any, end: any, isSelected: boolean) => {
				const isActiveEvent = start <= now && end >= now;
				const isPastEvent = end < now;
				const color = isActiveEvent ? 'success' : event.color;

				return {
					className: classNames({
						[`bg-l${darkModeStatus ? 'o25' : '10'}-${color} text-${color}`]: color,
						'border border-success': isActiveEvent,
						'opacity-50': isPastEvent,
					}),
				};
			},
			[darkModeStatus],
		);

		const { data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
			['visits', usersList, isUserDoctor],
			() =>
				VisitService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'patient',
							},
							{
								association: 'medicalCase',
							},
							{
								association: 'services',
							},
							{
								association: 'estimate',
								include: [
									{
										association: 'act',
									},
								],
							},
							{
								association: 'user',
								...(!isUserDoctor && usersList.length
									? { where: { uuid: usersList } }
									: { where: { uuid: [''] } }),
								...(isUserDoctor && { where: { uuid: usersList } }),
							},
						],
						filterMeta: {
							status: [
								'Предстоящий',
								'В процессе',
								'Завершен',
								'В ожидании',
								'Визит завершен',
								'Калькуляция',
								'Задолженность',
							],
						},
					}),
				),
			{
				refetchOnWindowFocus: false,
				enabled: !!(!isUserDoctor && usersList.length),
			},
		);

		useEffect(() => {
			if (visits?.data && !prevVisit) {
				setVisitData((prev: any) => {
					const existingVisit = prev.find((visit: any) => visit.id === 'visitDate');
					if (existingVisit) {
						return [...visits.data, existingVisit];
					} else {
						return [...visits.data];
					}
				});
			}

			if (visits?.data && prevVisit) {
				setVisitData(
					visits.data.map((i: any) => {
						if (i?.uuid === prevVisit?.uuid) {
							return {
								uuid: i.uuid,
								id: 'visitDate',
								visitData: visitData,
								setVisitData: setVisitData,
								prevVisit: prevVisit || null,
								changeValues: changeValues,
								start: new Date(i.startDate),
								end: new Date(i.endDate),
								status: null,
							};
						}
						return i;
					}),
				);
			}

			if (!visits?.data) {
				setVisitData([]);
			}
		}, [visits, prevVisit, setVisitData]);

		const isDateBeforeNow = useCallback((date: Date | string): boolean => {
			const givenDate = new Date(date);
			const currentDate = new Date();
			return givenDate > currentDate;
		}, []);

		const formatTime = (dateTime: any) => {
			return dateTime.toISOString();
		};

		const handleSelect = useCallback(
			(data: any) => {
				if (!isDateBeforeNow(data.slots[0])) {
					return openNotification(
						'bottomRight',
						'Вы не можете выбрать прошедшую дату для визита',
					);
				}

				if (!usersList) {
					return openNotification(
						'bottomRight',
						'Нужно выбрать врача, а потом время и дату',
					);
				}
				if (isDateBeforeNow(data.slots[0])) {
					setTimePickerIsOpen(true);
					setChooseTiemModal(data.slots[0]);
					setStartTime(dayjs(formatTime(data.start)).format('HH:mm'));
					setDuration(
						calculateDuration(
							dayjs(formatTime(data.start)).format('HH:mm'),
							dayjs(formatTime(data.end)).format('HH:mm'),
						),
					);
					changeValues(
						'startDate',
						joinTimeAndDate(
							dayjs(formatTime(data.start)).format('HH:mm'),
							data.start.toISOString(),
						),
					);
					setStart(
						joinTimeAndDate(
							dayjs(formatTime(data.start)).format('HH:mm'),
							data.start.toISOString(),
						),
					);
					changeValues(
						'visitDate',
						joinTimeAndDate(
							dayjs(formatTime(data.start)).format('HH:mm'),
							data.start.toISOString(),
						),
					);
					changeValues(
						'endDate',
						joinTimeAndDate(
							dayjs(formatTime(data.end)).format('HH:mm'),
							data.start.toISOString(),
						),
					);
					setEnd(
						joinTimeAndDate(
							dayjs(formatTime(data.end)).format('HH:mm'),
							data.start.toISOString(),
						),
					);
				}
			},
			[
				changeValues,
				isDateBeforeNow,
				openNotification,
				setChooseTiemModal,
				setDuration,
				setEnd,
				setStart,
				setStartTime,
				setTimePickerIsOpen,
				usersList,
			],
		);

		const handleViewMode = useCallback(
			(e: dayjs.ConfigType) => {
				setDate(dayjs(e).toDate());
				setViewMode(Views.DAY);
			},
			[setDate, setViewMode],
		);

		return (
			<div className='row h-100'>
				<CalendarComponent
					viewMode={viewMode}
					setDate={setDate}
					localizer={localizer}
					date={date}
					views={views}
					// unitType={unitType}
					prevVisit={prevVisit}
					visitData={visitData}
					// setViewMode={setViewMode}
					setTimePickerIsOpen={setTimePickerIsOpen}
					setIsOpenSubmit={setIsOpenSubmit}
					handleSelect={handleSelect}
					handleViewMode={handleViewMode}
					eventStyleGetter={eventStyleGetter}
				/>
			</div>
		);
	},
);

export default Calendar;
