import React, { FC, useState } from 'react';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../../components/bootstrap/OffCanvas';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationService } from '../../../../../custom/api/service/notification.service';
import * as qs from 'qs';
import { INotification } from '../../../../../custom/api/interface/notification.interface';
import Icon from '../../../../../components/icon/Icon';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import { IVisit } from '../../../../../custom/api/interface/visit.interface';
import PaginationButtons, { PER_COUNT_FILTER } from '../../../../../components/PaginationButtons';
import NotificationComponentItem from './NotificationComponentItem';

interface INotificationComponentActProps {
	setIsOpen(...args: unknown[]): unknown;
	isOpen: boolean;
}

const NotificationComponentAct: FC<INotificationComponentActProps> = ({ setIsOpen, isOpen }) => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const { data: notifications_act }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_act', currentPage, perPage],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: currentPage,
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visit',
								where: {
									status: ['Задолженность', 'Завершен'],
								},
							},
						],

						// filterMeta: {
						// 	type: 'Не оплачен',
						// },
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	const queryClient = useQueryClient();

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			queryClient.invalidateQueries('visits');
		},
		onError: (error: any) => {},
	};

	const { mutate } = useMutation(
		(uuid: string) => NotificationService.readNot(uuid),
		queryOptions,
	);

	const { mutate: chnageStatus } = useMutation(
		(data: IVisit) => VisitService.update(data.uuid, data),
		queryOptions,
	);

	const changeStatusNot = async (data: any) => {
		await mutate(data);
	};

	const handleStatusChange = (status: string, patientUuid: string, uuid: string) => {
		chnageStatus({
			uuid: uuid,
			patientUuid: patientUuid,
			status: status,
		});
		queryClient.invalidateQueries({ queryKey: ['notifications_act'] });
		queryClient.invalidateQueries({ queryKey: ['notifications_act_count'] });
	};

	return (
		<>
			<OffCanvas
				isBackdrop={true}
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={isOpen}
				setOpen={setIsOpen}
				style={{
					width: '30vw',
					transform: 'translateX(0%) translateY(0%) translateZ(0px)',
					padding: '1.5rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}>
				<OffCanvasHeader setOpen={setIsOpen}>
					<div className='d-flex gap-3'>
						<Icon icon='ReceiptLong' size='2x' color='info' />
						<OffCanvasTitle id='offcanvasExampleLabel'>
							Уведомления Сметы
						</OffCanvasTitle>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody className='p-0 d-flex flex-column justify-content-between'>
					<div className='d-flex flex-column align-items-center'>
						{notifications_act?.data?.length > 0 ? (
							notifications_act?.data?.map((i: any, index: number) => (
								<NotificationComponentItem
									key={`${index}-${i?.uuid}`}
									not={i}
									handleStatusChange={handleStatusChange}
									chnageStatus={changeStatusNot}
								/>
							))
						) : (
							<p>У вас нет новых уведомлений</p>
						)}
					</div>
				</OffCanvasBody>
				{notifications_act?.data?.length > perPage && (
					<PaginationButtons
						data={notifications_act || []}
						label='customers'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
						totalPage={Math.ceil(notifications_act?.meta?.count / perPage) || 1}
					/>
				)}
			</OffCanvas>
		</>
	);
};

export default NotificationComponentAct;
