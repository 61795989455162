import React, { FC, memo } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../../../components/bootstrap/forms/Input';
import useDarkMode from '../../../../../../../hooks/useDarkMode';

interface ICodeFilterComponentProps {
	handleInputChange(...args: unknown[]): unknown;
	setPopperIsOpenCode(...args: unknown[]): unknown;
	popperIsOpenCode: any;
	values: any;
}

const CodeFilterComponent: FC<ICodeFilterComponentProps> = memo(
	({ values, setPopperIsOpenCode, popperIsOpenCode, handleInputChange }) => {
		const { themeStatus } = useDarkMode();
		return (
			<Dropdown isOpen={popperIsOpenCode} setIsOpen={setPopperIsOpenCode}>
				<DropdownToggle>
					<Button color={themeStatus} className='text-nowrap me-2'>
						Коду
					</Button>
				</DropdownToggle>
				<DropdownMenu style={{ width: '400px', padding: '15px 0 15px 0' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						<FormGroup
							style={{ marginTop: '15px' }}
							id='surname'
							label='Введите id или код услуги'
							className='col-10'>
							<Input
								name='code'
								type={'search'}
								onChange={(e: any) => handleInputChange(e)}
								value={values.code}
							/>
						</FormGroup>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default CodeFilterComponent;
