import React, { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { UserService } from '../../../../custom/api/service/user.service';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import TimePickerModal from './SchedualModalComponents/TimePickerModal';
import User from './SchedualModalComponents/User/User';
import Calendar from './SchedualModalComponents/Calendar/Calendar';
import SearchUser from './SchedualModalComponents/User/Header/Search/SearchUser';
import TimePickerSave from './SchedualModalComponents/TimePickerComopnent/TimePickerSave';
import DeletationSubmit from '../../visit/VisistPageComponents/Modals/DeletationSubmit';
import { getUnitType } from '../../../../components/extras/calendarHelper';
import { View as TView, Views } from 'react-big-calendar';
import AuthContext from '../../../../contexts/authContext';
import { getUserFullName } from '../../../../utils/functions';
import { MedicalCasesService } from '../../../../custom/api/service/medicalCase.service';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IScheduleModalProps {
	visitData: any;
	prevVisit?: any;
	isOpen: boolean;
	values: any;
	setIsOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setVisitData(...args: unknown[]): unknown;
	clinicUuid: string | undefined;
	medicalCaseUuid: any;
}

const ScheduleModal: FC<IScheduleModalProps> = memo(
	({
		visitData,
		prevVisit,
		changeValues,
		setVisitData,
		values,
		isOpen,
		setIsOpen,
		clinicUuid,
		medicalCaseUuid,
	}) => {
		const [userSearch, setUserSearch] = useState('');
		const [search, setSearch] = useState<any>({});
		const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
		const [api, contextHolder] = notification.useNotification();
		const [isOpenSubmit, setIsOpenSubmit] = useState(false);
		const [chooseTimeModal, setChooseTiemModal] = useState<Date>(new Date());
		const [timePickerIsOpen, setTimePickerIsOpen] = useState<boolean>(false);
		const [start, setStart] = useState<any>();
		const [end, setEnd] = useState<any>();
		const [startTime, setStartTime] = useState<any>();
		const [endTime, setEndTime] = useState<any>();
		const [duration, setDuration] = useState<number | null>(null);

		const { data: medicalCase } = useQuery(
			['medicalCase'],
			() => MedicalCasesService.findOne(medicalCaseUuid, qs.stringify({})),
			{ refetchOnWindowFocus: true, enabled: !!medicalCaseUuid },
		);

		const openNotification = (placement: NotificationPlacement, description: string) => {
			api.info({
				message: `Важно !`,
				description,
				placement,
			});
		};

		const [usersList, setUsersList] = useState<string>('');

		const { data: user_role } = useQuery(
			['user_role'],
			() => UserService.getProfile(qs.stringify({})),
			{ refetchOnWindowFocus: true },
		);

		useEffect(() => {
			setIsUserDoctor(
				user_role?.roles?.some(
					(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
				),
			);
		}, [user_role]);

		useEffect(() => {
			if (isUserDoctor) {
				changeValues('userUuid', user_role?.uuid);
				changeValues(
					'userFullName',
					`${user_role?.name} ${user_role?.surname} ${user_role?.middleName}`,
				);
				setUsersList(user_role?.uuid);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isUserDoctor]);

		useEffect(() => {
			// const visit = visitData.find((item: any) => item.id !== 'visitDate');
			if (!!prevVisit?.userUuid) {
				setUsersList(prevVisit?.userUuid);
				changeValues('userUuid', prevVisit?.userUuid);
				changeValues('userFullName', getUserFullName(prevVisit.user));
				changeValues('startDate', prevVisit?.startDate);
				changeValues('endDate', prevVisit?.endDate);
				changeValues('visitDate', prevVisit?.visitDate);

				// setStartTime('');
				// setEndTime('');
			}
			if (!start && !end && !startTime && !endTime && usersList) {
				setUsersList('');
				changeValues('userFullName', '');
				changeValues('userUuid', '');
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [prevVisit]);

		const deletionSubmit = useCallback(() => {
			if (!!prevVisit) {
				changeValues('startDate', prevVisit?.startDate);
				changeValues('endDate', prevVisit?.endDate);
				changeValues('visitDate', prevVisit?.visitDate);
				setVisitData((prev: any) =>
					prev.map((i: any) => {
						if (i?.uuid === prevVisit?.uuid) {
							return {
								uuid: i.uuid,
								id: 'visitDate',
								visitData: visitData,
								setVisitData: setVisitData,
								prevVisit: !!prevVisit ? prevVisit : null,
								changeValues: changeValues,
								start: new Date(prevVisit.startDate),
								end: new Date(prevVisit.endDate),
								status: null,
							};
						}
						return i;
					}),
				);
				return;
			}
			setVisitData((prev: any) => prev.filter((i: any) => i.id !== 'visitDate'));
			changeValues('visitDate', '');
			changeValues('endDate', '');
			changeValues('startDate', '');
			setStart('');
			setEnd('');
			setStartTime('');
			setEndTime('');
			setDuration(null);
		}, [changeValues, prevVisit, setVisitData, visitData]);

		const changeSearchValue = useCallback((key: string, value: string | number) => {
			setSearch((prev: any) => ({ ...prev, [key]: value }));
		}, []);

		const [viewMode, setViewMode] = useState<TView>(Views.DAY);
		const [date, setDate] = useState(new Date());
		const unitType = getUnitType(viewMode);
		useEffect(() => {
			setIsUserDoctor(
				user_role?.roles?.some(
					(role: any) => role?.role === 'mainDoctor' || role?.role === 'doctor',
				),
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const { fontSize } = useContext(AuthContext);

		useEffect(() => {
			if (fontSize === 'Большой' || fontSize === 'Очень большой') {
				setViewMode(Views.DAY);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [fontSize, JSON.parse(localStorage.getItem('fontSize') || '{}').name]);

		const resetFilter = useCallback(() => {
			setSearch({});
			setUsersList('');

			changeValues('subCategoryUuid', '');
			changeValues('categoriesUuid', '');
			changeValues(
				'clinicUuid',
				!!medicalCase && medicalCaseUuid ? medicalCase.clinicUuid : '',
			);
			changeValues('userUuid', '');
			setVisitData((prev: any) => prev.filter((i: any) => i.id !== 'visitDate'));
			changeValues('visitDate', '');
			changeValues('endDate', '');
			changeValues('startDate', '');
			setStart('');
			setEnd('');
			setStartTime('');
			setEndTime('');
			setDuration(null);
		}, [changeValues, medicalCase, medicalCaseUuid, setVisitData]);

		useEffect(() => {
			if (prevVisit?.userUuid === usersList) {
				setVisitData((prev: any) => [
					...prev,
					{
						id: 'visitDate',
						visitData: visitData,
						name: 'asdasd',
						setVisitData: setVisitData,
						prevVisit: !!prevVisit ? prevVisit : null,
						changeValues: changeValues,
						start: new Date(start),
						end: new Date(end),
					},
				]);
			} else {
				setVisitData((prev: any) => prev.filter((i: any) => i.id !== 'visitDate'));
			}
		}, [usersList]);

		return (
			<>
				<Modal isStaticBackdrop isCentered isOpen={isOpen} setIsOpen={setIsOpen} fullScreen>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<span className='fs-3 fw-bold'>
							{isUserDoctor ? 'График Врачa' : 'График Врачей'}
						</span>
					</ModalHeader>
					<ModalBody className='px-4'>
						<span>{contextHolder}</span>
						<SearchUser
							resetFilter={resetFilter}
							userSearch={userSearch}
							search={search}
							isUserDoctor={isUserDoctor}
							setSearch={setUserSearch}
							values={values}
							changeSearchValue={changeSearchValue}
							changeValues={changeValues}
							searchValues={search}
							setUsersList={setUsersList}
							setViewMode={setViewMode}
							viewMode={viewMode}
							date={date}
							setDate={setDate}
							unitType={unitType}
						/>
						<User
							search={userSearch}
							changeValues={changeValues}
							values={values}
							isUserDoctor={isUserDoctor}
							setSearch={setUserSearch}
							setUsersList={setUsersList}
							usersList={usersList}
						/>
						<Calendar
							setDuration={setDuration}
							setVisitData={setVisitData}
							isUserDoctor={isUserDoctor}
							setEnd={setEnd}
							setStart={setStart}
							changeValues={changeValues}
							openNotification={openNotification}
							setTimePickerIsOpen={setTimePickerIsOpen}
							setIsOpenSubmit={setIsOpenSubmit}
							usersList={usersList}
							prevVisit={prevVisit}
							visitData={visitData}
							setChooseTiemModal={setChooseTiemModal}
							setStartTime={setStartTime}
							setEndTime={setEndTime}
							viewMode={viewMode}
							setViewMode={setViewMode}
							date={date}
							setDate={setDate}
							unitType={unitType}
						/>
					</ModalBody>
					<ModalFooter>
						<TimePickerSave
							values={values}
							isUserDoctor={isUserDoctor}
							setIsOpen={setIsOpen}
							changeValues={changeValues}
							setUsersList={setUsersList}
						/>
					</ModalFooter>
				</Modal>

				<TimePickerModal
					chooseTimeDate={chooseTimeModal}
					visitData={visitData}
					setVisitData={setVisitData}
					changeValues={changeValues}
					isOpen={timePickerIsOpen}
					setIsOpen={setTimePickerIsOpen}
					prevVisit={prevVisit}
					usersList={usersList}
					isUserDoctor={isUserDoctor}
					setStart={setStart}
					setEnd={setEnd}
					setStartTime={setStartTime}
					setEndTime={setEndTime}
					start={start}
					end={end}
					startTime={startTime}
					endTime={endTime}
					duration={duration}
					setDuration={setDuration}
				/>

				<DeletationSubmit
					setIsOpen={setIsOpenSubmit}
					isOpen={isOpenSubmit}
					submit={deletionSubmit}
				/>
			</>
		);
	},
);

export default ScheduleModal;
