import { authHost } from '../axios';
import { IUser } from '../interface/user.interface';

export class UserService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any): Promise<{ data: IUser[] } | null> {
		const { data } = await authHost.get(`user?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`user/${uuid}?${query}`);
		return data;
	}
	static async getProfile(query?: any, p0?: { refetchOnWindowFocus: boolean }) {
		try {
			const { data } = await authHost.get(`user/profile?${query}`);
			return data;
		} catch (e: any) {
			if (
				e.response.data.message === 'reset_your_password' &&
				window.location.href !== '/createPassword'
			) {
				window.location.href = '/createPassword?error=reset_your_password';
			}
		}
	}

	static async create(data: IUser) {
		const user = await authHost.post('user', data);
		return user;
	}

	static async update(uuid: string, data: IUser) {
		const user = await authHost.patch(`user/${uuid}`, data);
		return user;
	}
}
