import React, { FC, useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import NewPatientSecondStep from './NewPatientSecondStep';
import { ValidatedInput } from '../../../custom/components/inputs/ValidatedInput';

interface INewPatientVisitModalProps {
	isOpen: any;
	setIsOpen(...args: unknown[]): unknown;
}
const phoneMask = /^\+?\d{1,3}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,3}[-.\s]?\d{1,3}[-.\s]?\d{3,7}$/;

const nameMask = /^[A-Za-zА-Яа-яЁёԱ-Ֆա-ֆ\-]{3,}$/;

const NewPatientVisitModal: FC<INewPatientVisitModalProps> = ({ isOpen, setIsOpen }) => {
	const [patientNext, setPatientNext] = useState(true);
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setPatientNext(false);
	};
	const [isFormValid, setIsFormValid] = useState(false);
	const [values, setValues] = useState<IPatientCreate>({
		phone: '+374',
		name: '',
		surname: '',
		middleName: '',
		know: '',
	});

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};
	useEffect(() => {
		if (values.phone && values.name && values.surname && values.middleName) {
			const isValid =
				values?.phone.match(phoneMask) &&
				values.name.match(nameMask) &&
				values.surname.match(nameMask) &&
				values.middleName.match(nameMask);
			setIsFormValid(Boolean(isValid));
		}
	}, [values]);

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' type='md' isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					{patientNext
						? `1. Новый пациент`
						: ` 2. Выберите откуда пациент узнал о клинике`}
				</ModalTitle>
			</ModalHeader>
			<form onSubmit={handleSubmit}>
				{(patientNext && (
					<ModalBody className='px-4 d-flex flex-column gap-4'>
						<ValidatedInput
							id='surname'
							label='Фамилия'
							changeValues={changeValues}
							value={values.surname}
							validationMask={nameMask}
						/>
						<ValidatedInput
							id='name'
							label='Имя'
							changeValues={changeValues}
							value={values.name}
							validationMask={nameMask}
						/>
						<ValidatedInput
							id='middleName'
							label='Отчество'
							changeValues={changeValues}
							value={values.middleName}
							validationMask={nameMask}
						/>
						<ValidatedInput
							id='phone'
							label='Номер телефона'
							changeValues={changeValues}
							value={values.phone}
							validationMask={phoneMask}
						/>
					</ModalBody>
				)) || (
					<NewPatientSecondStep
						setValues={setValues}
						changeValues={changeValues}
						values={values}
						setIsOpen={setIsOpen}
						setPatientNext={setPatientNext}
					/>
				)}

				{patientNext && (
					<ModalFooter className='px-4 pb-4 '>
						<Button
							color='info'
							type='submit'
							className='col-12'
							isDisable={!isFormValid}
							isOutline={!isFormValid}>
							Cоздать нового пациента
						</Button>
					</ModalFooter>
				)}
			</form>
		</Modal>
	);
};

export default NewPatientVisitModal;
