import React, { useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { demoPagesMenu } from '../../../menu';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons, { PER_COUNT } from '../../../components/PaginationButtons';
import AddPatientModal from './AddPatientModal';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { PatientService } from './../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { useDebounce } from '../../../hooks/useDebounce';
import TableComponent from '../../../custom/components/tables/TableComponent';
import Header from './patientComponent/Header/Header';
import CreateVisitPatient from '../medicalCard/MedicalCard/MedicalCardComponents/CreateVisit/CreateVisitPatient';

const PatientsPage = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);
	const [patientSearch, setPatientSearch] = useState('');
	const [patient, setPatient] = useState<string>('');
	const [patientSort, setPatientSort] = useState<boolean>();
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const DebiuncedPatinetSearch = useDebounce(patientSearch);

	const { data: patients }: { data: IPatient[] | any; isLoading: boolean } = useQuery(
		['patients', currentPage, patientSort, perPage, DebiuncedPatinetSearch],
		() =>
			PatientService.findAll(
				qs.stringify({
					queryMeta: {
						order: patientSort ? { name: 'ASC' } : { createdAt: 'DESC' },
						paginate: true,
						limit: perPage,
						page: currentPage,
					},
					includeMeta: [
						{
							association: 'visit',
						},
					],
					filterMeta: {
						...(patientSearch && { websearchQuery: { searchVector: patientSearch } }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const visitDropDownProps = {
		setEventAdding: setUpcomingEventsEditOffcanvas,
		setPatientId: setPatient,
	};
	const tableHeaders = [
		{ header: '№ карты пациента', key: 'id_patient' },
		{ header: 'Пациент', key: 'patientNameTable' },
		{ header: 'Номер телефона', key: 'patientPhone' },
		{ header: 'Кол-во визитов', key: 'visitCountPatient' },
		// { header: 'Долг', key: 'debtPatient' },
		{ header: 'Статус', key: 'patient_status' },
		{ header: 'Действие', key: 'action' },
	];

	return (
		<PageWrapper title={demoPagesMenu.crm.subMenu.customersList.text}>
			<Header
				search={patientSearch}
				setSearch={setPatientSearch}
				sort={patientSort}
				setSort={setPatientSort}
				setEditModalStatus={setEditModalStatus}
			/>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody className='table-responsive'>
								<TableComponent
									headers={tableHeaders}
									data={patients?.data}
									visitDropDownProps={visitDropDownProps}
								/>
							</CardBody>
							<PaginationButtons
								data={patients?.data || []}
								label='customers'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
								totalPage={Math.ceil(patients?.meta?.count / perPage) || 1}
							/>
						</Card>
					</div>
				</div>
			</Page>
			<AddPatientModal setIsOpen={setEditModalStatus} isOpen={editModalStatus} />
			<CreateVisitPatient
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
				patient={patient}
			/>
		</PageWrapper>
	);
};

export default PatientsPage;
