import React from 'react';
import { medicalCardPages } from '../../../../menu';
import Button from '../../../../components/bootstrap/Button';
import Avatar from '../../../../components/Avatar';
import UserImage3 from '../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../assets/img/wanna/wanna3.webp';
import Name from './Name';

export const NameWithAvatar: React.FC<{
	user: any;
	patientUuid: string | undefined;
	dynamicFont: number;
	themeStatus: any;
	setIsOpen?: any;
}> = ({ user, patientUuid, dynamicFont = 16, themeStatus, setIsOpen }) => {
	return (
		<Button
			onClick={() => {
				if (setIsOpen) {
					setIsOpen(false);
				}
			}}
			tag='a'
			to={`../${medicalCardPages?.customerID?.path}/${patientUuid}`}
			className='d-flex p-0 align-items-center gap-3'>
			<div className='flex-shrink-0'>
				<Avatar
					src={user.image ? `https://backend.amracode.am${user.image}` : UserImage3}
					srcSet={
						user?.image ? `https://backend.amracode.am${user?.image}` : UserImage3Webp
					}
					size={3 * dynamicFont}
				/>
			</div>
			<Name user={user} themeStatus={themeStatus} />
		</Button>
	);
};
