import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UserService } from '../../../../../../custom/api/service/user.service';
import * as qs from 'qs';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import useDarkMode from '../../../../../../hooks/useDarkMode';
import UserCarousel from './UserComponents/UserCarousel';
import UserList from './UserComponents/UserList';
import { IUser } from '../../../../../../custom/api/interface/user.interface';
import { validatePhoneNumber } from '../../../../../../utils/functions';
import { PhoneNumberUtil } from 'google-libphonenumber';

interface IHeaderProps {
	search: any;
	values: any;
	isUserDoctor: boolean;
	setSearch(...args: unknown[]): unknown;
	setUsersList(...args: unknown[]): unknown;
	usersList: any;
	changeValues(...args: unknown[]): unknown;
}

const User: FC<IHeaderProps> = memo(
	({ search, changeValues, setSearch, isUserDoctor, values, usersList, setUsersList }) => {
		const { themeStatus } = useDarkMode();
		const debouncedvalues = useDebounce(values);
		const [isValid, setIsValid] = useState(false);
		const [parts, setParts] = useState<any>('');
		const DebiuncedPatinetSearch = useDebounce(search);

		const selectUser = useCallback(
			(user: any) => {
				if (user.uuid && user.name) {
					if (usersList !== user.uuid) {
						setUsersList(user.uuid);
						changeValues(
							'userFullName',
							`${user?.name} ${user?.surname} ${user?.middleName}`,
						);
						changeValues('userUuid', user?.uuid);
					} else {
						setUsersList('');
						changeValues('userFullName', '');
						changeValues('userUuid', '');
					}
				}
			},
			[changeValues, setUsersList, usersList],
		);

		const validatePhoneNumber = (phoneNumber: string): void => {
			const phoneUtil = PhoneNumberUtil.getInstance();
			const number = phoneNumber.replace(/\+/g, '');
			try {
				const parsedNumber = phoneUtil.parse(number);
				setIsValid(phoneUtil.isValidNumber(parsedNumber));
			} catch (e) {
				const allRegions: string[] = phoneUtil.getSupportedRegions();
				let isValidForAnyRegion: boolean = false;
				for (const region of allRegions) {
					try {
						const parsedNumber = phoneUtil.parse(number, region);
						if (phoneUtil.isValidNumber(parsedNumber)) {
							isValidForAnyRegion = true;
							break;
						}
					} catch (e) {
						continue;
					}
				}
				setIsValid(isValidForAnyRegion);
			}
		};

		useEffect(() => {
			if (search.includes('+')) {
				validatePhoneNumber(search);
			}
			if (search) {
				setParts(search.split(' '));
			}
		}, [search]);

		const { data: users }: { data: IUser[] | any; isLoading: boolean } = useQuery(
			['users', DebiuncedPatinetSearch, debouncedvalues],
			() =>
				UserService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'roles',
								where: {
									role: ['mainDoctor', 'doctor'],
									clinicUuid: values.clinicUuid,
								},
							},

							{
								...(search &&
									/\d/.test(search) &&
									!isValid && {
										association: 'passport',
										where: {
											or: [
												{
													passportNumber: {
														iLike: `%${search}%`,
													},
												},
												{
													ssCardId: {
														iLike: `%${search}%`,
													},
												},
											],
										},
									}),
							},
							{
								association: 'category',

								...(!!values?.categoriesUuid && {
									where: {
										uuid: values?.categoriesUuid,
										...(values?.subCategoryUuid && {
											categoryUuid: values?.subCategoryUuid,
										}),
									},
								}),
							},
						],
						filterMeta: {
							...(search &&
								/\d/.test(search) &&
								isValid && { phone: { iLike: `%${search}%` } }),
							...(search &&
								!/\d/.test(search) &&
								!isValid &&
								((parts.length === 1 && {
									surname: { iLike: `%${parts[0]}%` },
								}) ||
									(parts.length === 2 && {
										and: [
											{ name: { iLike: `%${parts[1]}%` } },
											{ surname: { iLike: `%${parts[0]}%` } },
										],
									}) ||
									(parts.length === 3 && {
										and: [
											{ name: { iLike: `%${parts[1]}%` } },
											{ surname: { iLike: `%${parts[0]}%` } },
											{ middleName: { iLike: `%${parts[2]}%` } },
										],
									}))),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		useEffect(() => {
			if (!users?.data.length) {
				setUsersList('');
				changeValues('userFullName', ``);
				changeValues('userUuid', '');
			}
		}, [changeValues, setUsersList, users?.data]);

		return (
			<>
				{!isUserDoctor && (
					<div className='row m-0 g-3'>
						{users?.data?.length ? (
							users.data.length >= 18 ? (
								<UserCarousel
									users={users.data}
									usersList={usersList}
									themeStatus={themeStatus}
									selectUser={selectUser}
								/>
							) : (
								<UserList
									users={users?.data}
									usersList={usersList}
									themeStatus={themeStatus}
									selectUser={selectUser}
								/>
							)
						) : (
							<div className='d-flex justify-content-center'>
								<h3>Врачи не найдены</h3>
							</div>
						)}
					</div>
				)}
			</>
		);
	},
);

export default User;
