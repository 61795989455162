import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import ChoosePatient from './steps/ChoosePatient';
import { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import ChooseMedicalCase from './steps/ChooseMedicalCase';
import CreateVisit from './steps/CreateVisit';
import NewPatientVisitModal from './NewPatientVisitModal';
import Wizard, { WizardItem } from '../../../../components/Wizard';

interface IPlanVisitModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
}
const PlanVisitModal: FC<IPlanVisitModalProps> = ({ isOpen, setIsOpen }) => {
	const [isPatientChoosen, setIsPatientChoosen] = useState(false);
	const [isNewPatientChoosen, setIsNewPatientChoosen] = useState(false);
	const [choosenPatientId, setChoosenPatientId] = useState(null);
	const [patientCreated, setPatientCreated] = useState(false);
	const [newPatientIsOpen, setNewPatientIsOpen] = useState(false);
	const [patientCreatedId, setPatientCreatedId] = useState('');
	const [stageUuid, setStageUuid] = useState<null | string>(null);
	const [serviceId, setServiceId] = useState<any>([]);
	const [createVisit, setCreateVisit] = useState(false);
	const [medicalCaseUuid, setMedicalCaseUuid] = useState<null | string>(null);
	const [clinicUuid, setClinicUuid] = useState('');
	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		let newStep;
		if (createVisit) {
			newStep = 2;
		} else if (isPatientChoosen || isNewPatientChoosen) {
			newStep = 1;
		} else {
			newStep = 0;
		}

		setCurrentStep(newStep);
	}, [createVisit, isPatientChoosen, isNewPatientChoosen]);

	return (
		<>
			<Modal
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				closeModalCustom={() => {
					setIsPatientChoosen(false);
					setIsNewPatientChoosen(false);
					setChoosenPatientId(null);
					setPatientCreatedId('');
					setPatientCreated(false);
					setCreateVisit(false);
					setStageUuid(null);
				}}
				size={isNewPatientChoosen ? 'lg' : 'xl'}
				isCentered>
				<ModalHeader
					setIsOpen={setIsOpen}
					closeModalCustom={() => {
						setIsPatientChoosen(false);
						setIsNewPatientChoosen(false);
						setChoosenPatientId(null);
						setPatientCreatedId('');
						setPatientCreated(false);
						setStageUuid(null);
						setCreateVisit(false);
					}}
					style={{ padding: '1.5rem 2.25rem' }}>
					<div className='d-flex align-items-center gap-3'>
						<Icon icon='Alarm' color='primary' style={{ fontSize: '3rem' }} />
						<p style={{ fontSize: '1.75rem', fontWeight: 600, margin: 0 }}>
							Запланировать визит
						</p>
					</div>
				</ModalHeader>
				<div
					className='d-flex flex-row align-items-center justify-content-center'
					style={{ padding: '1.5rem 2.25rem' }}>
					<SubHeaderLeft className='col-4'>
						{createVisit && <h3 className='m-0'>3. Создайте визит</h3>}
						{!createVisit && !isPatientChoosen && !isNewPatientChoosen && (
							<h3 className='m-0'>1. Выберите пациента</h3>
						)}
						{!createVisit && isPatientChoosen && !isNewPatientChoosen && (
							<h3 className='m-0'>2. Выберите мед. случай</h3>
						)}
						{!createVisit && isNewPatientChoosen && !isPatientChoosen && (
							<h3 className='col-10 m-0'>
								2. Выберите откуда пациент узнал о клинике
							</h3>
						)}
					</SubHeaderLeft>
					<SubHeaderRight className='col-8'>
						<Wizard
							isHeader={false}
							color={'primary'}
							stretch={false}
							showButtons={false}
							current={currentStep}
							isClickable={false}
							style={{
								minWidth: 200,
								background: 'unset',
								border: 'unset',
								boxShadow: 'none',
								margin: '0rem 1.5rem',
								padding: 0,
							}}>
							<WizardItem id={'1'} title={'Выбор пациента'} className='wi-size'>
								{''}
							</WizardItem>
							<WizardItem id={'2'} title={'Выбор мед. случая'} className='wi-size'>
								{''}
							</WizardItem>
							<WizardItem id={'3'} title={'Создание визита'} className='wi-size'>
								{''}
							</WizardItem>
						</Wizard>
					</SubHeaderRight>
				</div>
				<ModalBody style={{ padding: '1.5rem 2.25rem' }}>
					{!isPatientChoosen && !isNewPatientChoosen && (
						<ChoosePatient
							patientCreated={patientCreated}
							setPatientCreated={setPatientCreated}
							setPatientCreatedId={setPatientCreatedId}
							setChoosenPatientId={setChoosenPatientId}
							setIsNewPatientChoosen={setIsNewPatientChoosen}
							setIsOpen={setIsOpen}
							setIsPatientChoosen={setIsPatientChoosen}
							setPatientId={setPatientCreatedId}
							setNewPatientIsOpen={setNewPatientIsOpen}
							setCreateVisit={setCreateVisit}
							newPatientIsOpen={newPatientIsOpen}
						/>
					)}
					{!patientCreated && (
						<NewPatientVisitModal
							setIsNewPatientChoosen={setIsNewPatientChoosen}
							setPatientCreatedId={setPatientCreatedId}
							patientCreated={patientCreated}
							setPatientCreated={setPatientCreated}
							newPatientIsOpen={newPatientIsOpen}
							setIsPatientChoosen={setIsPatientChoosen}
							setCreateVisit={setCreateVisit}
							setNewPatientIsOpen={setNewPatientIsOpen}
						/>
					)}

					{(isNewPatientChoosen || isPatientChoosen) &&
						!createVisit &&
						isPatientChoosen && (
							<ChooseMedicalCase
								setCloseModal={setIsOpen}
								setServiceId={setServiceId}
								setMedicalCaseUuid={setMedicalCaseUuid}
								setStageUuid={setStageUuid}
								setPatientCreatedId={setPatientCreatedId}
								patientId={patientCreatedId}
								setCreateVisit={setCreateVisit}
								setClinicUuid={setClinicUuid}
								setIsPatientChoosen={setIsPatientChoosen}
								isPatientChoosen={isPatientChoosen}
							/>
						)}

					{(isNewPatientChoosen || isPatientChoosen) && createVisit && (
						<CreateVisit
							setPatientCreated={setPatientCreated}
							patientCreated={patientCreated}
							setPatientCreatedId={setPatientCreatedId}
							setChoosenPatientId={setChoosenPatientId}
							choosenPatientId={choosenPatientId}
							setIsNewPatientChoosen={setIsNewPatientChoosen}
							isNewPatientChoosen={isNewPatientChoosen}
							setIsPatientChoosen={setIsPatientChoosen}
							setCreateVisit={setCreateVisit}
							setServiceId={setServiceId}
							setIsOpen={setIsOpen}
							patientId={patientCreatedId}
							serviceId={serviceId}
							medicalCaseUuid={medicalCaseUuid}
							clinicUuid={clinicUuid}
							stageUuid={stageUuid}
						/>
					)}
				</ModalBody>
			</Modal>
		</>
	);
};

export default PlanVisitModal;
