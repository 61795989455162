import React, { FC, memo, useEffect, useState } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../../../components/bootstrap/Button';
import Icon from '../../../../../../../components/icon/Icon';
import { VisitStatusStyles } from '../../../../../../../utils/functions';
import useDarkMode from '../../../../../../../hooks/useDarkMode';
import CustomSelect from '../../../../../../components/Select/select';
import FormGroup from '../../../../../../../components/bootstrap/forms/FormGroup';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { ClinicService } from '../../../../../../api/service/clinic.service';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { CategoryService } from '../../../../../../api/service/category.service';

interface ISpecializationFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenStatus(...args: unknown[]): unknown;
	popperIsOpenStatus: any;
	values: any;
	dynamicFont: any;
	changeValues: any;
	disabled: boolean;
}

const SpecializationFilterComponent: FC<ISpecializationFilterComponentProps> = memo(
	({ values, setPopperIsOpenStatus, popperIsOpenStatus, handleCheckboxChange, disabled }) => {
		const { themeStatus } = useDarkMode();
		const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
		const [subCategoryOpen, setSubCategoryOpen] = useState(false);
		const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
			['categories'],
			() =>
				CategoryService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'subCategory',
							},
						],
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const categories = categoriesWithSubCategories?.data
			.filter((item: any) => !item.categoryUuid)
			.map((category: any) => ({
				...category,
				categoriesUuid: category.uuid,
			}));

		const subCategories = categoriesWithSubCategories?.data
			.filter((item: any) => item.categoryUuid)
			.map((subCategory: any) => ({
				...subCategory,
				subCategoryUuid: subCategory.uuid,
			}));

		const filteredSubCategories = subCategories?.filter(
			(subCategory: any) => subCategory.categoryUuid === selectedCategory,
		);

		useEffect(() => {
			setSelectedCategory(values.categoriesUuid);
		}, [values.categoriesUuid]);
		return (
			<>
				{!disabled && (
					<Dropdown isOpen={popperIsOpenStatus} setIsOpen={setPopperIsOpenStatus}>
						<DropdownToggle>
							<Button color={themeStatus} className='text-nowrap me-2'>
								Категории
							</Button>
						</DropdownToggle>
						<DropdownMenu style={{ width: '400px' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexWrap: 'wrap',
								}}>
								{categories &&
									categories.map((category: any, index: number) => (
										<div className='form-check col-10' key={`${index}-${category.uuid}`} >
											<input
												className='form-check-input'
												type='checkbox'
												value={category.uuid}
												id={`flexCheckDefault-${index}`}
												onChange={(e: any) => {
													handleCheckboxChange(e);
													setSelectedCategory(category.uuid);
												}}
												checked={
													values.category === category.categoriesUuid
												}
												name='category'
											/>
											<label
												className='form-check-label'
												htmlFor={`flexCheckDefault-${index}`}>
												<span>{category.name.rus}</span>
											</label>
										</div>
									))}
							</div>
						</DropdownMenu>
					</Dropdown>
				)}
				{values.category && selectedCategory && filteredSubCategories?.length > 0 && (
					<Dropdown isOpen={subCategoryOpen} setIsOpen={setSubCategoryOpen}>
						<DropdownToggle>
							<Button color={themeStatus} className='text-nowrap me-2'>
								Подспециализации
							</Button>
						</DropdownToggle>
						<DropdownMenu style={{ width: '400px' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexWrap: 'wrap',
								}}>
								{filteredSubCategories.map((subCategory: any, index: number) => (
									<div className='form-check col-10' key={index}>
										<input
											className='form-check-input'
											type='checkbox'
											value={subCategory.subCategoryUuid}
											id={`flexSubCheck-${index}`}
											onChange={(e: any) => handleCheckboxChange(e)}
											checked={
												values.subCategory === subCategory.subCategoryUuid
											}
											name='subCategory'
										/>
										<label
											className='form-check-label'
											htmlFor={`flexSubCheck-${index}`}>
											{subCategory.name.rus}
										</label>
									</div>
								))}
							</div>
						</DropdownMenu>
					</Dropdown>
				)}
			</>
		);
	},
);
export default SpecializationFilterComponent;
