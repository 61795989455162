import { authHost } from '../axios';
import { IFavorite } from '../interface/favorite.interface';
import { IVisit } from '../interface/visit.interface';

export class ServiceService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IVisit[] | null> {
		const { data } = await authHost.get(`visit_services?${query}`);
		return data;
	}

	static async findOne(uuid: string) {
		const { data } = await authHost.get(`visit_services/${uuid}`);
		return data;
	}

	static async findActServices(uuid: string) {
		const { data } = await authHost.get(`stage_service/getServices/${uuid}`);
		return data;
	}

	static async create(data: IVisit) {
		const visit_services = await authHost.post('visit_services', data);
		return visit_services;
	}

	static async createFavorite(data: IFavorite) {
		const visit_services = await authHost.post('visit_services/favorite', data);
		return visit_services;
	}

	static async update(uuid: string, data: IVisit) {
		const visit_services = await authHost.patch(`visit_services/${uuid}`, data);
		return visit_services;
	}
}
