import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../../menu';
import MaterialsTable from './MaterialsTable';
import PlanVisitModal from '../visit/planVisit/PlanVisitModal';
import Header from './SubHeaderComponent/Header';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import { PER_COUNT_FILTER, PER_COUNT } from '../../../components/PaginationButtons';
import { MaterialService } from '../../api/service/materials-service.service';
import { IService } from '../../api/interface/service.interface';
import { UserService } from '../../api/service/user.service';

const MaterialsPage = () => {
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const [materialsSort, setmaterialsSort] = useState<any>({});
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({})),
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		serviceName: undefined,
		name: undefined,
		order: { createdAt: 'DESC' },
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		treatment_type: '',
		category: '',
		subCategory: '',
		store_type: '',
	});

	useEffect(() => {
		setValues((prev) => ({
			...prev,
			order: materialsSort ? { name: 'ASC' } : { createdAt: 'DESC' },
		}));
	}, [materialsSort]);

	const DebouncedSearch = useDebounce(values);
	const filterDebounce = useDebounce(filter);

	const { data: materials }: { data: any[] | any; isLoading: boolean } = useQuery(
		[
			'materials',
			DebouncedSearch,
			servicesCurrentPage,
			servicesPerPage,
			filterDebounce,
			materialsSort,
		],
		() =>
			MaterialService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: servicesPerPage,
						page: DebouncedSearch?.serviceName?.length ? 1 : servicesCurrentPage,
						order: values.order,
					},

					includeMeta: [
						{
							association: 'clinic',
							...(!!filter.clinic && {
								where: {
									uuid: filter.clinic,
								},
							}),
						},
						{
							association: 'category',
							...(!!filter.category && {
								where: {
									categoryUuid: filter.category,
								},
							}),
						},
					],
					filterMeta: {
						...(values.serviceName
							? /\d/.test(values.serviceName)
								? {
										or: [
											{ brand: { iLike: `%${values.serviceName}%` } },
											{ name: { iLike: `%${values.serviceName}%` } },
											{ code: { iLike: `%${values.serviceName}%` } },
										],
								  }
								: { name: { iLike: `%${values.serviceName}%` } }
							: {}),
						...(!!filter.material && {
							material: filter.material,
						}),
						...(!!filter.store_type && {
							store_type: filter.store_type,
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['servicesCount', DebouncedSearch],
		() =>
			MaterialService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					// filterMeta: {
					// 	...(values.serviceName && {
					// 		...(values.serviceName && /\d/.test(values.serviceName)
					// 			? { name: { iLike: `%${values.serviceName}%` } }
					// 			: { title: { iLike: `%${values.serviceName}%` } }),
					// 	}),
					// },
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			<Header
				search={values.serviceName}
				setSearch={setValues}
				sort={materialsSort}
				setSort={setmaterialsSort}
			/>
			<Page container='fluid'>
				<div />
				<MaterialsTable
					isFluid
					setValues={setValues}
					values={values}
					materials={materials?.data}
					isUserDoctor={isUserDoctor}
					setCurrentPage={setCurrentPage}
					setPerPage={setPerPage}
					currentPage={currentPage}
					perPage={perPage}
					filter={filter}
					setFilter={setFilter}
				/>
			</Page>
			<PlanVisitModal
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
			/>
		</PageWrapper>
	);
};

export default MaterialsPage;
