import React, { FC } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import { useQuery } from 'react-query';
import { NotificationService } from '../../../../../api/service/notification.service';
import * as qs from 'qs';
import { INotification } from '../../../../../api/interface/notification.interface';
import Popovers from '../../../../../../components/bootstrap/Popovers';

interface INotificationActItemProps {
	styledBtn: any;
	setIsOpen(...args: unknown[]): unknown;
}

const NotificationActItem: FC<INotificationActItemProps> = ({ styledBtn, setIsOpen }) => {
	const { data: notifications_act_count }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_act_count'],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visit',
								includeMeta: [
									{
										association: 'visit',
										where: {
											status: ['Задолженность', 'Завершен'],
										},
									},
								],
							},
						],
						filterMeta: {
							isRead: false,
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	return (
		<div className='col-auto fs-6 d-flex align-items-center justify-content-center'>
			<Popovers trigger='hover' desc='Смета'>
				<div className='not_circle'>
					<Button
						{...styledBtn}
						icon='ReceiptLong'
						onClick={() => setIsOpen(true)}
						className='not_circle fs-6 d-flex align-items-center justify-content-center'>
						{notifications_act_count?.meta?.count > 0 && (
							<span className='span_not_circle'>
								{notifications_act_count?.meta?.count}
							</span>
						)}
					</Button>
				</div>
			</Popovers>
		</div>
	);
};

export default NotificationActItem;
