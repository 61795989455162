import React, { FC, useCallback, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import EVENT_STATUS from '../../../../../common/data/enumEventStatus';
import StatusSubmitModal from '../../../../../custom/pages/visit/VisistPageComponents/Modals/StatusSubmitModal';

const iconPicker = (title: string) => {
	const lowerCaseTitle = title.toLowerCase();

	if (lowerCaseTitle.includes('визит')) {
		return 'Alarm';
	} else if (lowerCaseTitle.includes('смета')) {
		return 'ReceiptLong';
	} else {
		return '';
	}
};

interface INotificationComponentItemItemProps {
	not: any;
	handleStatusChange(...args: unknown[]): unknown;
	chnageStatus(...args: unknown[]): unknown;
}
const NotificationComponentItem: FC<INotificationComponentItemItemProps> = ({
	not,
	handleStatusChange,
	chnageStatus,
}) => {
	const { themeStatus } = useDarkMode();
	const [notBody, setNotBody] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState<boolean>(false);

	const handelReade = useCallback(() => {
		if (!not.isRead && not) {
			chnageStatus(not?.uuid);
		}
	}, [chnageStatus, not]);
	const notColor = () => {
		if (not?.visit.status === 'Завершен') {
			return 'success';
		} else if (not?.visit.status === 'Отменен' || not?.visit.status === 'Не оплачен') {
			return 'danger';
		} else if (not.isRead) {
			return 'info';
		} else {
			return 'dark';
		}
	};
	const isDark = () => {
		return themeStatus === 'light' ? 'l25-' : 'lo25-';
	};

	return (
		<>
			<div key={not.uuid} className='col-12 rounded-3'>
				<Card
					onMouseEnter={() => {
						handelReade();
					}}
					className={`mb-10 rounded-3
						 ${`border-unset bg-${isDark()}${notColor()}`} shadow-none`}>
					<CardHeader
						className={` ${
							notBody && 'pb-0'
						} bg-${isDark()}${notColor()} d-flex justify-content-between rounded-3 cursor-pointer`}
						onClick={() => {
							// changeStatusNot({ uuid: not.uuid });
							if (notBody) {
								setNotBody(false);
							} else {
								setNotBody(true);
							}
						}}>
						<div className='d-flex align-items-center gap-3'>
							<Icon
								icon={iconPicker(not?.title)}
								size='2x'
								color={notColor() ? notColor() : null}
							/>
							<CardLabel iconColor='info'>
								<CardTitle
									className={`${
										themeStatus === 'light'
											? `text-${notColor()}`
											: 'text-light'
									}`}>
									{not?.title}
								</CardTitle>
							</CardLabel>
						</div>
						<Icon
							icon={notBody ? `ArrowDropUp` : `ArrowDropDown`}
							size='2x'
							color={notColor() ? notColor() : null}
						/>
					</CardHeader>
					{notBody && (
						<CardBody className='pt-0'>
							<FormGroup
								aria-disabled={not?.visit?.status === EVENT_STATUS.APPROVED.name}
								id='date'
								label='Описание'
								labelClassName={`${
									themeStatus === 'light' ? `text-${notColor()}` : 'text-light'
								}`}
								className={`${
									themeStatus === 'light' ? `text-${notColor()}` : 'text-light'
								}`}>
								<>
									<p dangerouslySetInnerHTML={{ __html: not?.description }}></p>
									{/* {not?.type === EVENT_STATUS.NOT_PAID.name &&
										not?.visit?.status !== EVENT_STATUS.APPROVED.name && (
											<Button
												onClick={() => {
													// handleStatusChange('Завершен', not?.visit?.uuid);
													setStatusModal(true);
												}}
												color={not.isRead ? `danger` : 'light'}
												isLight
												className='text-nowrap me-2 col-12'>
												Оплатить
											</Button>
										)} */}
									{/* <p>
										Статус -{' '}
										{not?.visit?.status === EVENT_STATUS.APPROVED.name &&
										not?.visit?.status === EVENT_STATUS.NOT_PAID.name
											? 'Оплачено'
											: 'Не оплачен'}
									</p> */}
								</>
							</FormGroup>
						</CardBody>
					)}
				</Card>
			</div>

			<StatusSubmitModal
				uuid={not?.visit?.uuid}
				patientUuid={not?.visit?.patientUuid}
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/>
		</>
	);
};

export default NotificationComponentItem;
