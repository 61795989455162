import React, { FC, useCallback, useEffect, useState } from 'react';
import { CardBody } from '../../../../components/bootstrap/Card';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import Services from './Components/Services';
import Divider from './Components/Devider';
import ChoosenServices from './Components/ChoosenServices';

import { notification, NotificationArgsProps } from 'antd';
type NotificationPlacement = NotificationArgsProps['placement'];

interface IServiceChooseModalProps {
	serviceDropdownInfo?: any;
	stage: any;
	medicalCard?: boolean;
	isStage?: boolean;
	createVisit?: boolean;
	setChosenValues?(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
	setIsNoServices?: any;
	setChosenServicesCount?: any;
	formRef?: any;
	services_visit?: any;
}

const ServiceChooseComponent: FC<IServiceChooseModalProps> = ({
	services_visit,
	serviceDropdownInfo,
	setServiceUuid,
	stage,
	medicalCard,
	setChosenValues,
	isStage,
	createVisit,
	formRef,
	setIsNoServices,
	setChosenServicesCount,
}) => {
	const [values, setValues] = useState<IService>({
		services: serviceDropdownInfo ?? [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const [choosenServices, setChosenService] = useState<any[]>([]);
	const [localChoosenServices, setLocalChosenService] = useState<any[]>([...choosenServices]);
	const [choosenServicesData, setChoosenServiceData] = useState<any[]>([]);
	const [services, setServices] = useState<any[]>([]);
	const [displayedServices, setDisplayedServices] = useState<any>([]);

	const [api, contextHolder] = notification.useNotification();
	const openNotificationService = useCallback(
		(placement: NotificationPlacement, description: string) => {
			api.info({
				message: 'Важно!',
				description,
				placement,
				className: 'ant-notification-custom',
				props: { style: { zIndex: 150000 } },
			});
		},
		[api],
	);

	const handleCheckboxChange = useCallback(
		(e: any, item?: any, type?: string) => {
			const name = e.target.name;
			if (e.target.type === 'checkbox') {
				const itemExists = choosenServicesData.some((service: any) => {
					if (service.uuid === item.uuid) {
						return item?.event_uuid === service?.event_uuid;
					}
					return false;
				});

				if (!itemExists) {
					setChoosenServiceData((prev: any[]) => {
						const newData = [...prev, item];

						if (setServiceUuid) {
							setServiceUuid(newData);
						}
						if (setChosenValues) {
							setChosenValues({ services: newData });
						}
						return newData;
					});
				} else if (itemExists) {
					setChoosenServiceData((prev: any[]) => {
						const data = [
							...prev.filter((service: any) => {
								if (service.uuid === item.uuid) {
									return item?.event_uuid !== service?.event_uuid;
								}
								return true;
							}),
						];

						if (setServiceUuid) {
							setServiceUuid([...data]);
						}

						if (setChosenValues) {
							setChosenValues({ services: [...data] });
						}

						return [...data];
					});
				}
			}
			if (e.target.type === 'search') {
				setValues((prev) => ({
					...prev,
					[name]: e.target.value,
				}));
			}
		},
		[choosenServicesData, setChosenValues, setServiceUuid],
	);

	const hendelDeleteService = useCallback(
		(uuid: string | undefined, index: number) => {
			if (!uuid || !values?.services) return;
			setChoosenServiceData((prev: any) => {
				if (!prev) return prev;
				const updatedServices = prev.filter(
					(value: any, idx: number) => value.event_uuid !== uuid,
				);
				setValues((prevValues) => ({
					...prevValues,
					services: updatedServices.map((item: any) => item?.uuid),
				}));
				if (setChosenValues)
					setChosenValues({ services: JSON.parse(JSON.stringify(updatedServices)) });
				return updatedServices;
			});
		},
		[setChosenValues, values?.services],
	);

	useEffect(() => {
		setDisplayedServices([]);
	}, [stage]);

	useEffect(() => {
		if (services_visit?.length > 0 && setChosenValues) {
			const services = services_visit?.map((item: any) => item.event);
			setChosenService(JSON.parse(JSON.stringify(services)));
			setChoosenServiceData(JSON.parse(JSON.stringify(services)));
			setChosenValues({ services: JSON.parse(JSON.stringify(services)) });
		} else {
			setChosenService([]);
			setChoosenServiceData([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [services_visit]);

	return (
		<>
			{services ? (
				<form ref={formRef} style={{ width: '100%', padding: 0 }}>
					<CardBody className='col-12 d-flex w-100 p-0 justify-content-between'>
						<span>{contextHolder}</span>
						<Services
							displayedServices={displayedServices}
							setDisplayedServices={setDisplayedServices}
							setServices={setServices}
							setChosenService={setChosenService}
							setLocalChosenService={setLocalChosenService}
							localChoosenServices={localChoosenServices}
							choosenServices={choosenServices}
							choosenServicesData={choosenServicesData}
							createVisit={createVisit}
							isStage={isStage}
							handleCheckboxChange={handleCheckboxChange}
							medicalCard={medicalCard}
							stage={stage}
							setChosenServicesCount={setChosenServicesCount}
							setIsNoServices={setIsNoServices}
						/>
						<Divider />
						<ChoosenServices
							setChosenService={setChosenService}
							setLocalChosenService={setLocalChosenService}
							localChoosenServices={localChoosenServices}
							choosenServices={choosenServices}
							choosenServicesData={choosenServicesData}
							createVisit={createVisit}
							isStage={isStage}
							hendelDeleteService={hendelDeleteService}
							services={services}
						/>
					</CardBody>
				</form>
			) : (
				<></>
			)}
		</>
	);
};

export default ServiceChooseComponent;
