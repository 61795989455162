import React, { FC } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import  {SubHeaderRight } from '../../../../../../layout/SubHeader/SubHeader';

interface IHeaderProps {
    setEditModalStatus(...args: unknown[]): unknown;
}

const NewPatient: FC<IHeaderProps> = ({
    setEditModalStatus,
}) => {
	return (
			<SubHeaderRight>
				<Button
					icon='PersonAdd'
					color='primary'
					isLight
					onClick={() => setEditModalStatus(true)}>
					Новый пациент{' '}
				</Button>
			</SubHeaderRight>
	);
};

export default NewPatient;
