import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import { authPage } from '../../../menu';
import login, { IClinicProps, getAllClinics } from '../../api/api';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';
import { useMutation } from 'react-query';

const LoginPage = () => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [clinicUuid, setClinicUuid] = useState<string>('');
	const [selectedBranch, setSelectedBranch] = useState('Clinic 1');
	const [clinics, setClinics] = useState<IClinicProps[]>([]);
	const [error, setError] = useState('');

	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	useEffect(() => {
		getAllClinics().then((data: any) => {
			setClinics(data.data);
			if (data.data.length > 0) {
				setSelectedBranch(data.data[0].name);
				setClinicUuid(data.data[0].uuid);
			}
		});
	}, []);

	const { mutate } = useMutation(
		(inputDto: { email: string; password: string; clinicUuid: string }) =>
			login(inputDto.email, inputDto.password, inputDto.clinicUuid),
		{
			onSuccess: (response, formData) => {
				if (setUser) {
					setUser(email);
				}
				handleOnClick();
			},
			onError: (error: any) => {
				setError(error?.response?.data?.message);
			},
		},
	);
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		mutate({ email, password, clinicUuid });
	};

	const handleChange = async (event: any) => {
		setSelectedBranch(event.target.value);
		const selectedClinic = clinics.find((clinic) => clinic.name === event.target.value);
		if (selectedClinic) {
			setClinicUuid(selectedClinic.uuid);
			localStorage.setItem('clinicUuid', selectedClinic.uuid);
		}
	};

	return (
		<PageWrapper isProtected={false} title='Sign Up' className='custom-login-wrapper'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={300} />
									</Link>
								</div>
								<div className='text-center h1 fw-bold mt-4'>Добро пожаловать</div>
								<div className='text-center h4 text-muted mb-4'>
									Выполните вход
								</div>{' '}
								<form className='row g-4' onSubmit={handleSubmit}>
									<div className='col-12'>
										<FormGroup
											id='loginUuid'
											isFloating
											label='Филиал'
											className='mb-2'>
											<Select
												required
												value={selectedBranch}
												onChange={handleChange}
												ariaLabel=''>
												{clinics.map((clinic) => (
													<Option key={clinic.name} value={clinic.name}>
														{clinic.name}
													</Option>
												))}
											</Select>
										</FormGroup>
										{error && (
											<div
												className={`text-danger ${
													error ? 'visible' : 'invisible'
												}`}
												style={{ textAlign: 'center', width: '100%' }}>
												{error}
											</div>
										)}
										<FormGroup
											id='loginEmail'
											isFloating
											label='Эл. почта'
											className='mb-2'>
											<Input
												type='email'
												value={email}
												required
												onChange={(e: any) => setEmail(e.target.value)}
												placeholder='Email'
											/>
										</FormGroup>
										<FormGroup
											id='loginPassword'
											isFloating
											label='Пароль'
											className='mb-2'>
											<Input
												type='password'
												required
												value={password}
												onChange={(e: any) => setPassword(e.target.value)}
												placeholder='Password'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											type='submit'
											color='warning'
											className='w-100 py-3 mb-3'>
											Войти
										</Button>
										<Link
											to={`${authPage.resetPassword.path}`}
											className='col-12 d-flex justify-content-center mb-2 text-decoration-none'>
											Забыли пароль?
										</Link>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default LoginPage;
