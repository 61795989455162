import React, { FC, useEffect, useState } from 'react';
import { Summary } from '../../../../../../custom/components/tables/columns/TotalSum';
import Button, { ButtonGroup } from '../../../../../../components/bootstrap/Button';
import Input from '../../../../../../components/bootstrap/forms/Input';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import HighlightedTitle from '../../../../../../custom/pages/medicalCard/medicalCaseComponents/UI/HighlightedTitle';

interface Material {
	price: number;
	count: number;
}

interface Item {
	amount: number;
	materials?: Material[];
}

interface IActDiscount {
	discount: number | null;
	setDiscount: (value: number | null) => void;
	countValues: Item[][];
	isShort: boolean;
	setValues?: (values: any) => void;
	values?: any;
}

export const ActDiscount: FC<IActDiscount> = ({
	discount,
	setDiscount,
	countValues,
	isShort,
	setValues,
	values,
}) => {
	const [isDiscountPercent, setIsDiscountPercent] = useState(false);
	const [localDiscount, setLocalDiscount] = useState<number | null>(null);

	useEffect(() => {
		if (discount !== null) {
			setLocalDiscount(discount);
		}
	}, [discount]);

	const countSpecificUUID = (array: any[], targetUUID: string): number => {
		return array?.filter((item) => item.uuid === targetUUID).length || 1;
	};

	useEffect(() => {
		setIsDiscountPercent(values?.discount_type === 'percent');
	}, [values?.discount_type]);

	const priceSums = (items: Item[]): number => {
		return items?.reduce((total, item) => {
			const itemAmount = Number(item.amount) || 0;
			const materialsTotal =
				item.materials?.reduce(
					(materialTotal, material) =>
						materialTotal + (material.price * material.count || 0),
					0,
				) || 0;
			return total + itemAmount + materialsTotal;
		}, 0);
	};

	const [totalPrice, setTotalPrice] = useState(0);
	const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(0);

	useEffect(() => {
		const updatedTotalPrice = countValues.map(priceSums).reduce((acc, curr) => acc + curr, 0);
		setTotalPrice(updatedTotalPrice);

		const updatedTotalPriceWithDiscount = discount
			? isDiscountPercent
				? updatedTotalPrice - (discount / 100) * updatedTotalPrice
				: updatedTotalPrice - discount
			: updatedTotalPrice;
		setTotalPriceWithDiscount(updatedTotalPriceWithDiscount);
	}, [countValues, discount, isDiscountPercent]);

	const validateDiscount = (value: number, maxPrice: number): number => {
		let cleanValue = Math.max(0, value);

		if (isDiscountPercent) {
			cleanValue = Math.min(cleanValue, 100);
		} else {
			cleanValue = Math.min(cleanValue, maxPrice);
		}

		return cleanValue;
	};

	const debouncedDiscount = useDebounce(localDiscount);

	useEffect(() => {
		setDiscount(debouncedDiscount);

		if (setValues) {
			setValues((prev: any) => ({
				...prev,
				discount_amount: debouncedDiscount,
			}));
		}
	}, [debouncedDiscount]);

	useEffect(() => {
		const validatedDiscount = validateDiscount(Number(discount || 0), totalPrice);
		if (validatedDiscount !== discount) {
			setDiscount(validatedDiscount);
		}
	}, [discount, totalPrice, isDiscountPercent]);

	return (
		<div className='d-flex flex-column justify-content-end align-items-end pe-3'>
			<h2>Сумма Этапа:</h2>
			<Summary
				number={totalPriceWithDiscount}
				status={['success']}
				className='bg-transparent'
			/>
			{totalPrice !== totalPriceWithDiscount && (
				<p
					className='text-danger fs-4 fs-bold m-0'
					style={{ textDecoration: 'line-through' }}>
					{`${totalPrice} AMD`}
				</p>
			)}
			<div className='col-5 d-flex gap-2 align-items-end'>
				<div className='w-100'>
					<HighlightedTitle title='Размер скидки' />
					{!isShort ? (
						<Input
							placeholder='Введите скидку'
							onChange={(e: any) => {
								const value = e.target.value === '' ? null : Number(e.target.value);
								setLocalDiscount(
									value !== null ? validateDiscount(value, totalPrice) : null,
								);
							}}
							type='number'
							value={localDiscount ?? ''}
						/>
					) : (
						<p>{localDiscount ?? ''}</p>
					)}
				</div>
				<ButtonGroup className='col-5'>
					<Button
						isDisable={isShort}
						color='info'
						isLight={isDiscountPercent}
						className='col-2'
						onClick={() => {
							setIsDiscountPercent(false);
							setValues?.((prev: any) => ({
								...prev,
								discount_type: 'amount',
							}));
						}}>
						AMD
					</Button>
					<Button
						isDisable={isShort}
						color='info'
						isLight={!isDiscountPercent}
						className='col-2'
						onClick={() => {
							setIsDiscountPercent(true);
							setValues?.((prev: any) => ({
								...prev,
								discount_type: 'percent',
							}));
						}}>
						%
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
};
