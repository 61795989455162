import Tooltips from '../../../../components/bootstrap/Tooltips';
import { VisitStatusStyles } from '../../../../utils/functions';
import React from 'react';

const Pill: React.FC<{
	visitDate?: string;
	text?: string;
	status: string;
	className?: string | {};
}> = ({ visitDate, text, status, className }) => {
	const dateStyles = VisitStatusStyles(status)?.dateStyles;
	return (
		<div className={`d-flex align-items-center ${className}`}>
			{dateStyles && (
				<div
					className={`date-frame text-center ${visitDate ? 'rounded-pill' : 'rounded-2'}`}
					style={{ ...dateStyles, maxWidth: text ? '8.5rem' : '13rem' }}>
					{visitDate ? (
						<span className='text-nowrap fw-bold fs-4'>
							{`${new Date(visitDate).toTimeString().slice(0, 5)}`}
						</span>
					) : (
						<Tooltips placement={'top'} title={text}>
							<span className='text-nowrap fw-bold fs-4 4 text-truncate'>{text}</span>
						</Tooltips>
					)}

					<span className='text-nowrap fs-4'>
						{visitDate
							? `${new Date(visitDate).toDateString().split(' ')[1]} ${
									new Date(visitDate).toDateString().split(' ')[2]
							  }`
							: null}
					</span>
				</div>
			)}
		</div>
	);
};
export default Pill;
