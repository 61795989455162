/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DentalContext from './dentalContext';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { TC_TL } from '../assets/svg/TC_TL';
import { TC_TR } from '../assets/svg/TC_TR';
import { TC_BL } from '../assets/svg/TC_BL';
import { TC_BR } from '../assets/svg/TC_BR';
import { TC_C } from '../assets/svg/TC_C';
import { copyObjectExcludingFields, deepCopyAll, generateUuid } from '../utils/functions';
import FormDataContext from './formDataContext';

const SurfaceSVG: FC<{
	startColor: string | undefined;
	endColor: string | undefined;
	fill: string | undefined;
	surface: string;
}> = ({ startColor, endColor, fill, surface }) => (
	<>
		{surface === 'top_left' && (
			<TC_TL startColor={startColor} endColor={endColor} fill={fill} />
		)}
		{surface === 'top_right' && (
			<TC_TR startColor={startColor} endColor={endColor} fill={fill} />
		)}
		{surface === 'bottom_left' && (
			<TC_BL startColor={startColor} endColor={endColor} fill={fill} />
		)}
		{surface === 'bottom_right' && (
			<TC_BR startColor={startColor} endColor={endColor} fill={fill} />
		)}
		{surface === 'center' && <TC_C startColor={startColor} endColor={endColor} fill={fill} />}
	</>
);

type NotificationPlacement = NotificationArgsProps['placement'];
export interface IDentalContextFunctionsProps {
	contextHolder: any;
	generateSequence: any;
	handleTeethUpdate: any;
	localFormData: any;
	setLocalFormData: any;
	localDentalFormula: any;
	setLocalDentalFormula: any;
	updateTeethData: any;
	checkBridgeOverlap: any;
	isModified: any;
	setIsModified: any;
	checkDirection: any;
	changeItemInfo: any;
	deleteItem: any;
	addItem: any;
	save: any;
	cancel: any;
	getInfoForCreateVisit: any;
	updateDentalFormulaFromBackend: any;
	updateDentalTheethDropDown: any;
	// updateDentalTheethDropDownLocal: any;
	setServicesFormData: any;
	addItemAndUpdate: any;
	setDisease: any;
	getServicesUuids: any;
	setRootsCount: any;
	updateDentalFormulaFromArray: any;
	updateDentalFormula: any;
	updateDentalFormulaDropdownFromDentalFormula: any;
	addDiseaseUpdate: any;
	resetDisease: any;
	resetDentalFormula: any;
}
const DentalContextFunctions = createContext<IDentalContextFunctionsProps>(
	{} as IDentalContextFunctionsProps,
);

interface IDentalContextFunctionsProviderProps {
	children: ReactNode;
}
export const DentalContextFunctionsProvider: FC<IDentalContextFunctionsProviderProps> = ({
	children,
}) => {
	const {
		dentalFormula,
		setDentalFormula,
		setSelectMany,
		setSelectManyAll,
		setIntermediateItems,
		setSelectedItems,
		setSelectedItemsAll,
		implant_default,
		event_default,
		default_dental_formula,
		dentalFormulaDrodown,
		setDentalFormulaDropdown,
		distalSurfaces,
	} = useContext(DentalContext);

	const { formData, setFormData } = useContext(FormDataContext);

	const [localDentalFormula, setLocalDentalFormula] = useState({ ...dentalFormula });
	const [localFormData, setLocalFormData] = useState({ ...formData });
	const [api, contextHolder] = notification.useNotification();

	const [isModified, setIsModified] = useState(false);
	const generateSequence = (
		start: string | number,
		end: string | number,
		direction?: string,
	): string[] => {
		let result: string[] = [];

		const startId = start?.toString();
		const endId = end?.toString();
		let formula;
		if (!direction && startId && endId) {
			direction = startId[0] === '1' || endId[0] === '2' ? 'top' : 'bottom';
		}
		if (direction) {
			formula = localDentalFormula[direction];
		}

		if (!Array.isArray(formula)) {
			console.error(`Invalid direction or data structure: ${direction}`);
			return result;
		}

		const startIndex = formula.findIndex((tooth: any) => tooth.id === startId);
		const endIndex = formula.findIndex((tooth: any) => tooth.id === endId);

		if (startIndex < 0 || endIndex < 0) {
			return result;
		}

		if (startIndex < endIndex) {
			for (let i = startIndex + 1; i < endIndex; i++) {
				result.push(formula[i].id);
			}
		} else {
			for (let i = startIndex - 1; i > endIndex; i--) {
				result.push(formula[i].id);
			}
		}

		return result;
	};

	const updateTeethData = (
		value: number | string,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		if (!formData[type] || !formData[type][index]) {
			console.error(`Invalid type or index: ${type}, ${index}`);
			return;
		}

		const directionIndex =
			formData[type][index]?.theeth[0] === '1' || formData[type][index]?.theeth[0] === '2'
				? 'top'
				: 'bottom';

		const currentTeethIndex = dentalFormula[directionIndex]?.findIndex(
			(item: any) => item.id === formData[type][index]?.theeth,
		);

		if (currentTeethIndex !== -1) {
			dentalFormula[directionIndex][currentTeethIndex] = {
				...dentalFormula[directionIndex][currentTeethIndex],
				[type]: false,
			};
		}
		const updatedFormData = [...formData[type]];
		updatedFormData[index] = {
			...updatedFormData[index],
			theeth: value,
		};
		const updatedDentalFormula = [...dentalFormula[direction]];
		updatedDentalFormula[teethIndex] = {
			...updatedDentalFormula[teethIndex],
			[type]: true,
		};
		setDentalFormula((prev: any) => ({
			...prev,
			[direction]: updatedDentalFormula,
		}));

		setFormData((prev: any) => {
			setLocalFormData({ ...prev, [type]: updatedFormData });
			return {
				...prev,
				[type]: updatedFormData,
			};
		});

		setIsModified(true);
	};

	const addBridgeTeeth = (value: number | string, index: number, direction: string) => {
		setLocalFormData((prev: any) => {
			const updatedBridge = [...prev.bridge];
			const bridge = { ...updatedBridge[index] };

			if (!bridge.start && !bridge.end) {
				bridge.start = String(value);
			} else if (bridge.start && !bridge.end) {
				if (String(value) === bridge.start) {
					return prev;
				}
				if (!checkBridgeOverlap(bridge.start, String(value), direction, index)) {
					bridge.end = String(value);

					const startIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.start,
					);
					const endIdx = localDentalFormula[direction].findIndex(
						(item: any) => item.id === bridge.end,
					);

					if (startIdx > -1 && endIdx > -1) {
						const correct_start = startIdx > endIdx ? bridge.end : bridge.start;
						const correct_end = startIdx > endIdx ? bridge.start : bridge.end;
						bridge.start = correct_start;
						bridge.end = correct_end;
						const sequence = generateSequence(correct_start, correct_end, direction);

						const updatedFormula = localDentalFormula[direction].map((tooth: any) => {
							if (tooth.id === correct_start) {
								return { ...tooth, bridge: true, bridge_position: 'start' };
							} else if (tooth.id === correct_end) {
								return { ...tooth, bridge: true, bridge_position: 'end' };
							} else if (sequence.includes(tooth.id)) {
								return { ...tooth, bridge: true, bridge_position: 'center' };
							}
							return tooth;
						});

						setLocalDentalFormula((prev: any) => ({
							...prev,
							[direction]: updatedFormula,
						}));
					} else {
						bridge.start = '';
						bridge.end = '';
					}
				} else {
					bridge.start = '';
					bridge.end = '';
				}
			}

			updatedBridge[index] = bridge;
			return { ...prev, bridge: updatedBridge };
		});

		setIsModified(true);
	};
	const handleTeethUpdate = (
		value: any,
		index: number,
		direction: string,
		teethIndex: number,
		type: ItemType,
	) => {
		if (type === 'bridge') {
			addBridgeTeeth(value, index, direction);
		} else {
			updateTeethData(value, index, direction, teethIndex, type);
		}
	};
	const openNotificationBridgePlace = (placement: NotificationPlacement, description: string) => {
		api.info({
			message: 'Важно!',
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};

	const checkBridgeOverlap = (start: string, end: string, direction: string, index: number) => {
		const bridge = { ...localFormData.bridge[index] };
		const startIdx = localDentalFormula[direction]?.findIndex(
			(tooth: any) => tooth.id === start,
		);
		const endIdx = localDentalFormula[direction]?.findIndex((tooth: any) => tooth.id === end);

		const sorted_start_index = startIdx > endIdx ? endIdx : startIdx;
		const sorted_end_index = endIdx > startIdx ? endIdx : startIdx;

		if (sorted_start_index === -1 || sorted_end_index === -1) return false;

		const isOverlapping = formData?.bridge?.some((existingBridge: any) => {
			const existingStartIdx = localDentalFormula[direction]?.findIndex(
				(tooth: any) => tooth.id === existingBridge.start,
			);
			const existingEndIdx = localDentalFormula[direction]?.findIndex(
				(tooth: any) => tooth.id === existingBridge.end,
			);

			const overlapsStart =
				sorted_start_index >= existingStartIdx && sorted_start_index <= existingEndIdx;
			const overlapsEnd =
				sorted_end_index >= existingStartIdx && sorted_end_index <= existingEndIdx;
			const containsExisting =
				sorted_start_index <= existingStartIdx && sorted_end_index >= existingEndIdx;

			if (overlapsStart || overlapsEnd || containsExisting) {
				bridge.start = '';
				bridge.end = '';
				openNotificationBridgePlace('bottomRight', 'На эти зубах уже есть мост');
				return true;
			}
			setIsModified(false);
			return false;
		});

		return isOverlapping;
	};
	// Bridge-specific logic should remain separate

	const checkDirection = (
		direction: string,
		type: 'referral' | 'implant' | 'event' | 'bridge',
	) => {
		return formData[type].some(
			(item: any) => dentalFormula[direction]?.some((tooth: any) => tooth.id === item.theeth),
		);
	};

	type ItemType =
		| 'bridge'
		| 'referral'
		| 'implant'
		| 'event'
		| 'deletion'
		| 'milk_theeth'
		| 'empty'
		| 'cosmetology'
		| 'lor';

	const addItemAndUpdate = (
		type: ItemType,
		defaultItem: any,
		valuesData: any,
		services_data?: any,
	) => {
		const value = deepCopyAll(valuesData);
		if (value.length > 1 && type === 'bridge') {
			const generated_event_uuid = generateUuid();
			const generated_event_uuid_material = generateUuid();

			const index_bridge = [...localFormData.bridge, { ...defaultItem }].length - 1;
			setFormData((prev: any) => {
				const updatedBridge = [...prev[type], { ...defaultItem }];
				const direction = value[0][0] === '1' || value[0][0] === '2' ? 'top' : 'bottom';
				const bridge = {
					...updatedBridge[index_bridge],
					material_event_uuid: generated_event_uuid_material,
					event_uuid: generated_event_uuid,
				};
				if (!!!bridge.start && !!!bridge.end) {
					bridge.start = String(value[0]);
				}
				if (!!bridge.start && !!!bridge.end) {
					if (String(value[1]) === bridge.start) {
						return prev;
					}
					if (
						!checkBridgeOverlap(bridge.start, String(value[1]), direction, index_bridge)
					) {
						bridge.end = String(value[1]);

						const startIdx = localDentalFormula[direction].findIndex(
							(item: any) => item.id === value[0],
						);
						const endIdx = localDentalFormula[direction].findIndex(
							(item: any) => item.id === value[1],
						);

						if (startIdx > -1 && endIdx > -1) {
							const correct_start = startIdx > endIdx ? bridge.end : bridge.start;
							const correct_end = startIdx > endIdx ? bridge.start : bridge.end;
							bridge.start = correct_start;
							bridge.end = correct_end;
							const sequence = generateSequence(
								correct_start,
								correct_end,
								direction,
							);

							const updatedFormula = localDentalFormula[direction].map(
								(tooth: any) => {
									if (tooth.id === correct_start) {
										return { ...tooth, [type]: true, bridge_position: 'start' };
									} else if (tooth.id === correct_end) {
										return { ...tooth, [type]: true, bridge_position: 'end' };
									} else if (sequence.includes(tooth.id)) {
										return {
											...tooth,
											[type]: true,
											bridge_position: 'center',
										};
									}
									return tooth;
								},
							);

							setLocalDentalFormula((prev: any) => ({
								...prev,
								[direction]: updatedFormula,
							}));
							setDentalFormula((prev: any) => ({
								...prev,
								[direction]: updatedFormula,
							}));
						} else {
							bridge.start = '';
							bridge.end = '';
						}
					} else {
						bridge.start = '';
						bridge.end = '';
					}
				}

				let event: any = [];
				const service = services_data?.find((item: any) => item.service_type === 'bridge');
				if (services_data && services_data.length > 0 && service) {
					event = [
						...prev.event,
						{
							...event_default,
							start: bridge.start,
							end: bridge.end,
							...service,
							price: service.price,
							amount: service.price,
							count: '1',
							event_uuid: generated_event_uuid,
							materials: [],
						},
					];
				} else {
					event = [
						...prev.event,
						{
							...event_default,
							start: bridge.start,
							end: bridge.end,
							count: '1',
							event_uuid: generated_event_uuid,
							materials: [],
							service_type: type,
						},
					];
				}

				updatedBridge[index_bridge] = bridge;

				setLocalFormData((prev: any) => ({
					...prev,
					event,
					[type]: updatedBridge,
				}));

				return { ...prev, event, [type]: updatedBridge };
			});

			// Reset the selection after update
			setSelectMany(false);
			setIsModified(false);
			setIntermediateItems([]);
			setSelectedItems([]);
			setSelectedItemsAll([]);
		}

		// For non-bridge types
		if (type !== 'bridge') {
			const formData_copy = { ...formData };
			const dentalFormula_copy = { ...dentalFormula };
			if (services_data === 'lor' || services_data === 'cosmetology') {
				const defaultItem_copy = JSON.parse(JSON.stringify(defaultItem));
				const generated_event_uuid = generateUuid();
				formData_copy[type] = [
					...(formData_copy[type] || []),
					{
						...defaultItem_copy,
						theeth: '',
						event_uuid: generated_event_uuid,
					},
				];
				setSelectMany(false);
				setIsModified(false);
				setIntermediateItems([]);
				setSelectedItems([]);
				setSelectManyAll(false);
				setSelectedItemsAll([]);
			}
			if (services_data !== 'lor' || services_data !== 'cosmetology') {
				value.forEach((val: string, index_val: number) => {
					const defaultItem_copy = JSON.parse(JSON.stringify(defaultItem));
					const generated_event_uuid = generateUuid();
					const toothId = val.toString();
					const isExisting = formData_copy[type]?.some(
						(item: any) => item.theeth === toothId,
					);

					if (isExisting) {
						return openNotificationBridgePlace(
							'bottomRight',
							// `Этот зуб уже выбран для типа ${type}, зуб - ${val}`,
							`Этот зуб уже выбран для данного типа услуг ${val}`,
						);
					}
					const direction = val[0] === '1' || val[0] === '2' ? 'top' : 'bottom';
					const teethIndex = dentalFormula_copy[direction]?.findIndex(
						(item: any) => item.id === val,
					);

					formData_copy[type] = [
						...(formData_copy[type] || []),
						{
							...defaultItem_copy,
							theeth: val,
							event_uuid: generated_event_uuid,
						},
					];
					dentalFormula_copy[direction][teethIndex] = {
						...dentalFormula_copy[direction][teethIndex],
						[type]: true,
					};
					let event: any = [...formData_copy.event];
					const service = Array.isArray(services_data)
						? services_data.find((item: any) => item.service_type === type)
						: null;
					if (services_data && services_data.length > 0 && service) {
						if (type !== 'referral' && type !== 'empty' && type !== 'milk_theeth') {
							event = [
								...event,
								{
									...event_default,
									theeth: val,
									...service,
									price: service?.price,
									amount: service?.price,
									count: '1',
									event_uuid: generated_event_uuid,
									materials: [],
								},
							];
						}
					} else {
						if (type !== 'referral' && type !== 'empty' && type !== 'milk_theeth') {
							event = [
								...event,
								{
									...event_default,
									theeth: val,
									count: '1',
									price: 0,
									amount: 0,
									event_uuid: generated_event_uuid,
									service_type: type,
									materials: [],
								},
							];
						}
					}

					formData_copy.event = [...event];

					setSelectMany(false);
					setIsModified(false);
					setIntermediateItems([]);
					setSelectedItems([]);
					setSelectManyAll(false);
					setSelectedItemsAll([]);
				});
			}

			setDentalFormula(dentalFormula_copy);
			setFormData(formData_copy);
		}
	};

	const addItem = (type: ItemType, defaultItem: any) => {
		setFormData((prev: any) => ({
			...prev,
			[type]: [...prev[type], { ...defaultItem }],
		}));
	};

	const deleteItem = (type: ItemType, uuid: string) => {
		if (uuid) {
			const updatedItems = formData[type].filter((item: any) => item.event_uuid !== uuid);
			setFormData((prev: any) => ({ ...prev, [type]: updatedItems }));

			if (type !== 'bridge' && type !== 'event') {
				const itemToDelete = formData[type]?.find((item: any) => item.event_uuid === uuid);
				if (itemToDelete.theeth) {
					const direction =
						itemToDelete.theeth[0] === '1' || itemToDelete.theeth[0] === '2'
							? 'top'
							: 'bottom';
					let formula = [...dentalFormula[direction]];
					const theethIdx = formula.findIndex((item) => item.id === itemToDelete.theeth);
					formula[theethIdx] = {
						...formula[theethIdx],
						[type]: false,
					};
					setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					const updatedItemsEvent = formData.event.filter(
						(item: any) => item.event_uuid !== itemToDelete.event_uuid,
					);
					setFormData((prev: any) => ({ ...prev, event: updatedItemsEvent }));
					setLocalFormData((prev: any) => ({ ...prev, event: updatedItemsEvent }));
				}
			}
			const itemToDelete = formData[type]?.find((item: any) => item.event_uuid === uuid);
			if (type === 'event') {
				if (
					itemToDelete?.service_type === 'implant' ||
					itemToDelete?.service_type === 'lor' ||
					itemToDelete?.service_type === 'deletion' ||
					itemToDelete?.service_type === 'cosmetology'
				) {
					const direction =
						itemToDelete.theeth[0] === '1' || itemToDelete.theeth[0] === '2'
							? 'top'
							: 'bottom';
					let formula = [...dentalFormula[direction]];
					const theethIdx = formula.findIndex((item) => item.id === itemToDelete.theeth);
					formula[theethIdx] = {
						...formula[theethIdx],
						[itemToDelete.service_type]: false,
					};
					setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					const updatedItems = formData[itemToDelete?.service_type].filter(
						(item: any, idx: number) => item.event_uuid !== itemToDelete.event_uuid,
					);
					setFormData((prev: any) => ({
						...prev,
						[itemToDelete?.service_type]: updatedItems,
					}));
					setLocalFormData((prev: any) => ({
						...prev,
						[itemToDelete?.service_type]: updatedItems,
					}));
				}
				if (
					itemToDelete?.service_type === 'bridge' &&
					itemToDelete?.start &&
					itemToDelete?.end
				) {
					const direction =
						itemToDelete.start[0] === '1' || itemToDelete.start[0] === '2'
							? 'top'
							: 'bottom';
					let formula = [...localDentalFormula[direction]];
					const startIdx = formula.findIndex((item) => item.id === itemToDelete.start);
					const endIdx = formula.findIndex((item) => item.id === itemToDelete.end);

					if (startIdx > -1 && endIdx > -1) {
						formula[startIdx] = {
							...formula[startIdx],
							bridge: false,
							bridge_position: null,
						};
						formula[endIdx] = {
							...formula[endIdx],
							bridge: false,
							bridge_position: null,
						};

						for (let i = startIdx + 1; i < endIdx; i++) {
							formula[i] = { ...formula[i], bridge: false, bridge_position: null };
						}

						setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
						const updatedItems = formData.bridge.filter(
							(item: any, idx: number) => item.event_uuid !== itemToDelete.event_uuid,
						);
						setFormData((prev: any) => ({ ...prev, bridge: updatedItems }));
						setLocalFormData((prev: any) => ({ ...prev, bridge: updatedItems }));
					}
					if (itemToDelete?.theeth && itemToDelete?.service_type === 'event') {
						const direction =
							itemToDelete.theeth[0] === '1' || itemToDelete.theeth[0] === '2'
								? 'top'
								: 'bottom';
						let formula = [...dentalFormula[direction]];
						const theethIdx = formula.findIndex(
							(item) => item.id === itemToDelete.theeth,
						);
						formula[theethIdx] = {
							...formula[theethIdx],
							[itemToDelete.service_type]: false,
						};
						setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
						setLocalDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					}
				}
			}
		}
		if (type === 'bridge') {
			const itemToDelete = formData[type]?.filter((item: any) => item.event_uuid === uuid);
			if (itemToDelete.start && itemToDelete.end) {
				const direction =
					itemToDelete.start[0] === '1' || itemToDelete.start[0] === '2'
						? 'top'
						: 'bottom';
				let formula = [...localDentalFormula[direction]];
				const startIdx = formula.findIndex((item) => item.id === itemToDelete.start);
				const endIdx = formula.findIndex((item) => item.id === itemToDelete.end);

				if (startIdx > -1 && endIdx > -1) {
					formula[startIdx] = {
						...formula[startIdx],
						bridge: false,
						bridge_position: null,
					};
					formula[endIdx] = {
						...formula[endIdx],
						bridge: false,
						bridge_position: null,
					};

					for (let i = startIdx + 1; i < endIdx; i++) {
						formula[i] = {
							...formula[i],
							bridge: false,
							bridge_position: null,
						};
					}

					setDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					setLocalDentalFormula((prev: any) => ({ ...prev, [direction]: formula }));
					const updatedItems = formData.event.filter(
						(item: any, idx: number) => item?.event_uuid !== itemToDelete.event_uuid,
					);
					setFormData((prev: any) => ({ ...prev, event: updatedItems }));
				}
			}
		}
	};

	const save = (type: ItemType, index: number, direction: string) => {
		if (type !== 'bridge') {
			if (direction) {
				dentalFormula[direction][
					dentalFormula[direction].findIndex(
						(item: any) => item.id === formData[type][index].theeth,
					)
				] = {
					...dentalFormula[direction][
						dentalFormula[direction].findIndex(
							(item: any) => item.id === formData[type][index].theeth,
						)
					],
					[type]: true,
				};
				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: dentalFormula[direction],
				}));
			}
		} else {
			setFormData(localFormData);
			setDentalFormula(localDentalFormula);
		}

		setIsModified(false);
	};

	const changeItemInfo = (type: ItemType, e: any, index: number) => {
		const { value, name } = e.target;
		setFormData((prev: any) => {
			const updatedItems = prev[type].map((item: any, idx: number) =>
				idx === index ? { ...item, [name]: value } : item,
			);
			return { ...prev, [type]: updatedItems };
		});
	};

	const cancel = (type: ItemType, index: number, direction: string) => {
		if (type !== 'bridge') {
			const updatedDentalFormula = { ...dentalFormula };
			if (direction) {
				const toothIndex = updatedDentalFormula[direction].findIndex(
					(item: any) => item.id === formData.event[index].theeth,
				);

				if (toothIndex !== -1) {
					updatedDentalFormula[direction][toothIndex] = {
						...updatedDentalFormula[direction][toothIndex],
						event: false,
					};
				}

				setDentalFormula((prev: any) => ({
					...prev,
					[direction]: updatedDentalFormula[direction],
				}));
			}

			const updatedFormData = { ...formData };

			updatedFormData.event[index] = {
				...updatedFormData.event[index],
				theeth: '',
			};

			setFormData((prev: any) => ({
				...prev,
				event: updatedFormData.event,
			}));
		} else {
			setLocalFormData(formData);
			setLocalDentalFormula(dentalFormula);
			setIsModified(false);
		}
		setIsModified(false);
	};

	const updateDentalFormulaFromBackend = (formDataFromBackend: any) => {
		setFormData(JSON.parse(JSON.stringify(formDataFromBackend)));
		setLocalFormData(JSON.parse(JSON.stringify(formDataFromBackend)));
		let updatedDentalFormula = { ...default_dental_formula };
		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_theeth',
		];
		updateDentalFormulaFromArray(formDataFromBackend?.info, updatedDentalFormula);

		propertiesToUpdate.forEach((property) => {
			formDataFromBackend[property]?.forEach((item: any) => {
				if (!item?.start && !item?.theeth) return;

				const toothId = item?.start || item?.theeth;

				const direction = toothId[0] === '1' || toothId[0] === '2' ? 'top' : 'bottom';

				const toothIndex = updatedDentalFormula[direction].findIndex(
					(tooth: any) => tooth?.id === toothId,
				);

				if (toothIndex > -1) {
					updatedDentalFormula[direction][toothIndex] = {
						...updatedDentalFormula[direction][toothIndex],
						[property]: true,
					};

					if (property === 'bridge') {
						let bridgeStart = item?.start;
						let bridgeEnd = item?.end;

						// Skip if bridgeStart or bridgeEnd is empty
						if (!bridgeStart || !bridgeEnd) return;

						const startIdx = updatedDentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeStart,
						);
						const endIdx = updatedDentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeEnd,
						);

						if (startIdx > -1 && endIdx > -1) {
							const correct_start = startIdx > endIdx ? bridgeEnd : bridgeStart;

							const correct_end = startIdx > endIdx ? bridgeStart : bridgeEnd;
							bridgeStart = correct_start;
							bridgeEnd = correct_end;
							const sequence = generateSequence(
								correct_start,
								correct_end,
								direction,
							);
							updatedDentalFormula[direction] = updatedDentalFormula[direction].map(
								(tooth: any) => {
									if (tooth.id === correct_start) {
										return { ...tooth, bridge: true, bridge_position: 'start' };
									} else if (tooth.id === correct_end) {
										return { ...tooth, bridge: true, bridge_position: 'end' };
									} else if (sequence.includes(tooth.id)) {
										return {
											...tooth,
											bridge: true,
											bridge_position: 'center',
										};
									}
									return tooth;
								},
							);
						}
					}
				}
			});
		});

		setDentalFormula(updatedDentalFormula);
		setLocalDentalFormula(updatedDentalFormula);
	};

	const updateDentalTheethDropDown = (formDataFromBackend: any) => {
		const new_data = JSON.parse(formDataFromBackend);
		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_theeth',
		];

		propertiesToUpdate.forEach((property) => {
			new_data.forEach((item: any) => {
				if (!item.start && !item.theeth) return;
				const toothId = item.start || item.theeth;

				const direction = toothId[0] === '1' || toothId[0] === '2' ? 'top' : 'bottom';

				const toothIndex = dentalFormula[direction].findIndex(
					(tooth: any) => tooth.id === toothId,
				);

				if (toothIndex > -1) {
					if (property === 'bridge') {
						let bridgeStart = item?.start;
						let bridgeEnd = item?.end;

						// Skip if bridgeStart or bridgeEnd is empty
						if (!bridgeStart || !bridgeEnd) return;

						const startIdx = dentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeStart,
						);
						const endIdx = dentalFormula[direction].findIndex(
							(tooth: any) => tooth.id === bridgeEnd,
						);

						if (startIdx > -1 && endIdx > -1) {
							const correct_start = startIdx > endIdx ? bridgeEnd : bridgeStart;
							const correct_end = startIdx > endIdx ? bridgeStart : bridgeEnd;
							bridgeStart = correct_start;
							bridgeEnd = correct_end;
							const sequence = generateSequence(
								correct_start,
								correct_end,
								direction,
							);
							dentalFormula[direction] = dentalFormula[direction].map(
								(tooth: any) => {
									if (tooth.id === correct_start) {
										return { ...tooth, bridge: true, bridge_position: 'start' };
									} else if (tooth.id === correct_end) {
										return { ...tooth, bridge: true, bridge_position: 'end' };
									} else if (sequence.includes(tooth.id)) {
										return {
											...tooth,
											bridge: true,
											bridge_position: 'center',
										};
									}
									return tooth;
								},
							);
						}
					} else {
						dentalFormula[direction][toothIndex] = {
							...dentalFormula[direction][toothIndex],
							[property]: true,
						};
					}
				}
			});
		});

		setDentalFormula(dentalFormula);
		setLocalDentalFormula(dentalFormula);
	};

	const updateDentalFormula = (dentalFormula_json: any) => {
		const new_data = JSON.parse(dentalFormula_json);
		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_theeth',
		];

		const propertiesDirection = ['top', 'bottom'];
		propertiesDirection.forEach((direction) => {
			propertiesToUpdate.forEach((property) => {
				new_data[direction].forEach((item: any) => {
					const toothIndex = dentalFormula[direction].findIndex(
						(tooth: any) => tooth.id === item.id,
					);
					if (toothIndex > -1) {
						dentalFormula[direction][toothIndex] = {
							...dentalFormula[direction][toothIndex],
							...item,
						};
					}
				});
			});
		});

		setDentalFormula(dentalFormula);
		setLocalDentalFormula(dentalFormula);
	};

	const updateDentalFormulaDropdownFromDentalFormula = () => {
		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_teeth', // Fixed typo: "milk_theeth" -> "milk_teeth"
		];
		const propertiesDirection = ['top', 'bottom'];

		// Clone dentalFormulaDrodown to avoid mutating state directly
		const updatedDentalFormulaDropdown = { ...dentalFormulaDrodown };
		propertiesDirection.forEach((direction) => {
			propertiesToUpdate.forEach((property) => {
				dentalFormulaDrodown[direction].forEach((item: any) => {
					const toothIndex = updatedDentalFormulaDropdown[direction].findIndex(
						(tooth: any) => tooth.id === item.id,
					);
					if (toothIndex > -1) {
						updatedDentalFormulaDropdown[direction][toothIndex] = {
							...updatedDentalFormulaDropdown[direction][toothIndex],
							...copyObjectExcludingFields(item),
						};
					}
				});
			});
		});
		setDentalFormulaDropdown(dentalFormulaDrodown);
		return dentalFormulaDrodown;
	};

	const setServicesFormData = (data: any) => {
		setFormData((prev: any) => {
			const newState = JSON.parse(JSON.stringify(prev));
			data.forEach((item: any) => {
				const generated_event_uuid = generateUuid();
				const generated_event_uuid_material = generateUuid();

				if (
					item.service_type &&
					item.service_type !== 'implant' &&
					item.service_type !== 'event'
				) {
					newState[item.service_type] = [
						...(newState[item.service_type] || []),
						{ ...item, event_uuid: generated_event_uuid },
					];
				} else if (item.service_type === 'implant') {
					newState[item.service_type] = [
						...(newState[item.service_type] || []),
						{
							...implant_default,
							...item,
							event_uuid: generated_event_uuid,
							material_event_uuid: generated_event_uuid_material,
						},
					];
				}

				newState.event = [
					...(newState?.event || []),

					{ ...item, event_uuid: generated_event_uuid, materials: [] },
				];
			});
			setLocalFormData({ ...newState });
			updateDentalFormulaFromBackend({ ...newState });
			return newState;
		});
	};

	const getServicesUuids = (data: any) => {
		const form_uuids = formData.event.map((item: any) => ({
			uuid: item.uuid,
			...(item.start && item.end
				? { start: item.start, end: item.end }
				: { theeth: item.theeth }),
		}));
		const data_uuids = data?.event?.map((item: any) => ({
			uuid: item.uuid,
			...(item.start && item.end
				? { start: item.start, end: item.end }
				: { theeth: item.theeth }),
		}));

		return form_uuids
			.filter((formItem: any) => {
				const bool_item = !data_uuids.some((dataItem: any) => {
					if (formItem.uuid === dataItem.uuid) {
						return formItem.event_uuid === dataItem.event_uuid;
					}
				});
				if (!bool_item) {
				} else {
					return bool_item;
				}
				return !data_uuids.some((dataItem: any) => {
					if (formItem.uuid === dataItem.uuid) {
						return formItem.event_uuid === dataItem.event_uuid;
					}
				});
			})
			.map((item: any) => item.uuid);
	};

	const setServicesLoaclData = (data: any, local_data: any) => {
		let newState = { ...local_data };
		if (data.service_type && data.service_type !== 'implant' && data.service_type !== 'event') {
			newState[data.service_type] = [...(newState[data.service_type] || []), data];
		} else if (data.service_type === 'implant') {
			newState[data.service_type] = [
				...(newState[data.service_type] || []),
				{ ...implant_default, ...data },
			];
		}

		return newState;
	};

	const getInfoForCreateVisit = (data: any, consultation_list: any) => {
		const services_data = JSON.parse(JSON.stringify(data ?? []));
		let new_form_data = JSON.parse(consultation_list);
		let uuidf = services_data.reduce((acc: string[], formItem: any) => {
			const existingEventIndex = new_form_data?.event?.findIndex(
				(event: any) => event.event_uuid === formItem.event_uuid,
			);
			if (existingEventIndex !== -1) {
				new_form_data.event[existingEventIndex] = {
					materials: [],
					...new_form_data.event[existingEventIndex],
					...formItem,
				};
			} else {
				new_form_data.event.push({ materials: [], ...formItem });
				new_form_data = setServicesLoaclData({ ...formItem }, new_form_data);
				acc.push(formItem.uuid);
			}

			return acc;
		}, []);

		return {
			uuids: uuidf,
			data: new_form_data,
		};
	};

	const setDisease = ({ id, position, diseaseName, color, startColor, endColor }: any) => {
		const direction = id[0] === '1' || id[0] === '2' ? 'top' : 'bottom';
		const updatedDentalFormula = { ...dentalFormula };
		const toothIndex = updatedDentalFormula[direction].findIndex(
			(tooth: any) => tooth.id === id,
		);

		if (toothIndex > -1) {
			const updatedTooth = { ...updatedDentalFormula[direction][toothIndex] };
			const distalSurface = updatedTooth.disease.distal[position];
			const updatedDistalSurface = Array.isArray(distalSurface) ? distalSurface : [];

			if (!updatedDistalSurface.includes(diseaseName)) {
				updatedTooth.disease.distal[position] = [...updatedDistalSurface, diseaseName];
			}

			updatedTooth.enamel = {
				...updatedTooth.enamel,
				[position]: (
					<SurfaceSVG
						surface={position}
						fill={color}
						startColor={startColor}
						endColor={endColor}
					/>
				),
			};

			updatedDentalFormula[direction][toothIndex] = updatedTooth;

			if (updatedDentalFormula !== dentalFormula) {
				setDentalFormula(updatedDentalFormula);
				setLocalDentalFormula(updatedDentalFormula);
				return updatedDentalFormula;
			}
		}
	};

	interface Surface {
		side: string;
		title: string;
	}

	interface Item {
		id: string;
		side: string;
		diseaseName: string;
		surfaceServices?: Surface[];
	}

	interface Tooth {
		id: string;
		disease: {
			distal: Record<string, string[]>;
			root: any;
		};
		enamel: Record<string, any>;
	}

	interface DentalFormula {
		top: Tooth[];
		bottom: Tooth[];
	}

	const updateDentalFormulaFromArray = (
		items: Item[],
		dentalFormula_copy: DentalFormula,
	): DentalFormula => {
		const updatedDentalFormula = { ...dentalFormula_copy };

		if (!Array.isArray(items)) {
			return updatedDentalFormula;
		}

		items.forEach((item: any) => {
			const direction = item.id[0] === '1' || item.id[0] === '2' ? 'top' : 'bottom';
			const toothIndex = updatedDentalFormula[direction]?.findIndex(
				(tooth) => tooth.id === item.id,
			);

			if (toothIndex > -1) {
				const updatedTooth = { ...updatedDentalFormula[direction][toothIndex] };

				const distalSurface = { ...updatedTooth.disease.distal };
				let distalChannels = [...updatedTooth.disease.root];
				let enamelData = { ...updatedTooth.enamel };

				if (Array.isArray(item.surfaceServices)) {
					item.surfaceServices.forEach((surface: any) => {
						distalSurface[surface.side] = [
							...(distalSurface[surface.side] || []),
							surface.title,
						];
						const distalSurfaceMatch = distalSurfaces.find(
							(d) => d.label === surface.title,
						);

						enamelData[surface.side] = (
							<SurfaceSVG
								surface={surface.side}
								fill={distalSurfaceMatch?.color}
								startColor={distalSurfaceMatch?.startColor}
								endColor={distalSurfaceMatch?.endColor}
							/>
						);
					});
				}

				if (Array.isArray(item.channelsServices)) {
					item.channelsServices.forEach((item_root: any) => {
						distalChannels = [
							...distalChannels,
							{
								surfaceTypeId: item_root?.surfaceTypeId,
								label: item_root.label,
							},
						];
					});
				}

				updatedTooth.disease.distal = distalSurface;
				updatedTooth.enamel = enamelData;
				updatedTooth.disease.root = distalChannels;
				updatedDentalFormula[direction][toothIndex] = updatedTooth;
			}
		});

		return updatedDentalFormula;
	};

	const resetDentalFormula = (services: string) => {
		const copy_dental_formula = deepCopyAll(default_dental_formula);
		const services_to_be = JSON.parse(services);
		const propertiesToUpdate = [
			'bridge',
			'implant',
			'event',
			'referral',
			'empty',
			'deletion',
			'milk_theeth',
		];
		services_to_be.forEach((item: any) => {
			const toothId = item.start || item.theeth;
			if (!toothId) return;

			const direction = toothId[0] === '1' || toothId[0] === '2' ? 'top' : 'bottom';
			const toothIndex = copy_dental_formula[direction].findIndex(
				(tooth: any) => tooth.id === toothId,
			);

			if (toothIndex > -1) {
				propertiesToUpdate.forEach((property) => {
					if (item.service_type === property) {
						if (property === 'bridge') {
							let bridgeStart = item?.start;
							let bridgeEnd = item?.end;

							if (!bridgeStart || !bridgeEnd) return;

							const startIdx = copy_dental_formula[direction].findIndex(
								(tooth: any) => tooth.id === bridgeStart,
							);
							const endIdx = copy_dental_formula[direction].findIndex(
								(tooth: any) => tooth.id === bridgeEnd,
							);

							if (startIdx > -1 && endIdx > -1) {
								const correct_start = startIdx > endIdx ? bridgeEnd : bridgeStart;

								const correct_end = startIdx > endIdx ? bridgeStart : bridgeEnd;
								bridgeStart = correct_start;
								bridgeEnd = correct_end;
								const sequence = generateSequence(
									correct_start,
									correct_end,
									direction,
								);
								copy_dental_formula[direction] = copy_dental_formula[direction].map(
									(tooth: any) => {
										if (tooth.id === correct_start) {
											return {
												...tooth,
												bridge: true,
												bridge_position: 'start',
											};
										} else if (tooth.id === correct_end) {
											return {
												...tooth,
												bridge: true,
												bridge_position: 'end',
											};
										} else if (sequence.includes(tooth.id)) {
											return {
												...tooth,
												bridge: true,
												bridge_position: 'center',
											};
										}
										return tooth;
									},
								);
							}
						} else {
							copy_dental_formula[direction][toothIndex] = {
								...copy_dental_formula[direction][toothIndex],
								[property]: true,
							};
						}
					}
				});
			}
		});

		setDentalFormula(copy_dental_formula);
		setLocalDentalFormula(copy_dental_formula);
	};

	const resetDisease = (id: string, position?: string) => {
		const direction = id[0] === '1' || id[0] === '2' ? 'top' : 'bottom';
		const updatedDentalFormula = { ...dentalFormula };
		const toothIndex = updatedDentalFormula[direction].findIndex(
			(tooth: any) => tooth.id === id,
		);

		if (toothIndex > -1) {
			const updatedTooth = { ...updatedDentalFormula[direction][toothIndex] };
			const distalSurface = updatedTooth.disease.distal;
			if (position) {
				if (updatedTooth.disease.distal[position]) {
					delete distalSurface[position];
				}

				if (updatedTooth.enamel[position]) {
					delete updatedTooth.enamel[position];
				}
			} else {
				const updatedDistalSurface = Array.isArray(distalSurface) ? distalSurface : [];
				updatedTooth.disease.distal = updatedDistalSurface;
				updatedTooth.enamel = {
					...updatedTooth.enamel,
				};
				updatedDentalFormula[direction][toothIndex] = updatedTooth;
			}

			if (updatedDentalFormula !== dentalFormula) {
				setDentalFormula(updatedDentalFormula);
				setLocalDentalFormula(updatedDentalFormula);
				return updatedDentalFormula;
			}
		}
	};

	const setRootsCount = ({ id, rootsCount }: { id: string; rootsCount: number }) => {
		// const direction = id[0] === '1' || id[0] === '2' ? 'top' : 'bottom';
		// const updatedDentalFormula = { ...dentalFormula };
		// const toothIndex = updatedDentalFormula[direction].findIndex(
		// 	(tooth: any) => tooth.id === id,
		// );
		// if (toothIndex > -1) {
		// 	updatedDentalFormula[direction][toothIndex] = {
		// 		...updatedDentalFormula[direction][toothIndex],
		// 		roots_count: rootsCount,
		// 	};
		// 	if (updatedDentalFormula !== dentalFormula) {
		// 		setDentalFormula(updatedDentalFormula);
		// 		setLocalDentalFormula(updatedDentalFormula);
		// 		return updatedDentalFormula;
		// 	} else {
		// 		return dentalFormula;
		// 	}
		// }
	};

	type DiseaseType = 'canal' | 'root' | 'gum';

	const addDiseaseUpdate = (
		type: DiseaseType,
		diseaseValue: string | {},
		value: [any],
		services_data?: any,
	) => {
		if (value.length > 0) {
			const direction = value[0][0] === '1' || value[0][0] === '2' ? 'top' : 'bottom';

			setLocalFormData((prev: any) => {
				const teethIndex = localDentalFormula[direction]?.findIndex(
					(item: any) => item.id === value[0],
				);

				if (teethIndex !== -1) {
					const updatedDentalFormula = [...localDentalFormula[direction]];
					const updatedTooth = { ...updatedDentalFormula[teethIndex] };
					updatedTooth.disease = {
						...updatedTooth.disease,
						[type]: updatedTooth.disease[type]
							.filter((disease: any) => disease !== diseaseValue)
							.concat(diseaseValue),
					};

					updatedDentalFormula[teethIndex] = updatedTooth;

					setLocalDentalFormula((prev: any) => ({
						...prev,
						[direction]: updatedDentalFormula,
					}));

					setDentalFormula((prev: any) => ({
						...prev,
						[direction]: updatedDentalFormula,
					}));
				}

				const service = services_data?.find(
					(item: any) =>
						item.service_type === 'disease' || item.service_type === 'treatment',
				);

				const event = [
					...prev.event,
					{
						...(!!service && { ...service }),
						...event_default,
						theeth: value[0],
						count: '1',
					},
				];

				// setFormData((prev: any) => ({
				// 	...prev,
				// 	event: event,
				// }));

				return { ...prev, event: event };
			});

			setIsModified(false);
			setIntermediateItems([]);
		}
	};

	const value = useMemo(
		() => ({
			contextHolder,
			localFormData,
			setLocalFormData,
			localDentalFormula,
			updateDentalFormulaFromBackend,
			updateDentalTheethDropDown,
			updateDentalFormula,
			updateDentalFormulaDropdownFromDentalFormula,
			// updateDentalTheethDropDownLocal,
			setLocalDentalFormula,
			generateSequence,
			handleTeethUpdate,
			updateTeethData,
			getServicesUuids,
			isModified,
			getInfoForCreateVisit,
			checkBridgeOverlap,
			setIsModified,
			checkDirection,
			resetDentalFormula,
			deleteItem,
			addItem,
			changeItemInfo,
			save,
			setServicesFormData,
			cancel,
			addItemAndUpdate,
			setDisease,
			setRootsCount,
			addDiseaseUpdate,
			updateDentalFormulaFromArray,
			resetDisease,
		}),
		[contextHolder, isModified, localDentalFormula, localFormData],
	);

	return (
		<DentalContextFunctions.Provider value={value}>{children}</DentalContextFunctions.Provider>
	);
};

DentalContextFunctionsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DentalContextFunctions;
