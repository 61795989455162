import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useDebounce } from '../../../../../hooks/useDebounce';
import Icon from '../../../../../components/icon/Icon';
import { deepCopyAll } from '../../../../../utils/functions';
import dayjs from 'dayjs';

interface IPaymentProps {
	setPayment: (payment: any) => void;
	payment: any;
	status: any;
}

const Payment: FC<IPaymentProps> = memo(({ payment, setPayment, status }) => {
	const [localPayment, setLocalPayment] = useState<any[]>(
		payment.payments || [
			{
				payment_type: 'личные средства',
				payment_amount: 0,
				status: null,
				date: dayjs().format('YYYY-MM-DD'),
			},
		],
	);

	useEffect(() => {
		setLocalPayment(
			payment.payments || [
				{
					payment_type: 'личные средства',
					payment_amount: 0,
					status: null,
					date: dayjs().format('YYYY-MM-DD'),
				},
			],
		);
	}, [payment.payments]);

	const paymentDebounce = useDebounce(localPayment);

	useEffect(() => {
		setPayment((prev: any) => ({ ...prev, payments: paymentDebounce }));
	}, [paymentDebounce, setPayment]);

	const addItem = useCallback(() => {
		setLocalPayment((prev) => [
			...prev,
			{
				payment_type: 'личные средства',
				payment_amount: 0,
				status: null,
				date: dayjs().format('YYYY-MM-DD'),
			},
		]);
	}, []);
	const changeValues = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
			const { name, value } = e.target;
			setLocalPayment((prev) => {
				const updatedPayments = [...prev];
				updatedPayments[index] = { ...updatedPayments[index], [name]: value };
				return updatedPayments;
			});
		},
		[],
	);

	const deleteItem = useCallback((index: number) => {
		setLocalPayment((prev) => {
			const updatedPayments = deepCopyAll(prev);
			if (
				updatedPayments.filter((item: any) => item.status === null).length <= 1 &&
				updatedPayments[index].status === null
			) {
				return prev;
			}
			return updatedPayments.filter((_: any, i: any) => i !== index);
		});
	}, []);

	return (
		<div className='col-10'>
			{localPayment.map((payment, index) => (
				<div
					style={{ height: 50 }}
					key={`${index}-${payment?.uuid}`}
					className='d-flex w-100 gap-4 align-items-center'>
					<div className='col-2'>
						<Input
							style={{ textAlign: 'center' }}
							disabled={true}
							type='date'
							value={payment.date}
							name='date'
							required
						/>
					</div>
					<div className='col-2'>
						<select
							disabled={payment.status || status}
							name='payment_type'
							value={payment.payment_type}
							onChange={(e: any) => changeValues(e, index)}
							className='form-select'>
							<option value='страховка'>Страховка</option>
							<option value='личные средства'>Личные средства</option>
						</select>
					</div>

					<div className='col-2'>
						<Input
							disabled={payment.status || status}
							type='number'
							name='payment_amount'
							value={payment.payment_amount}
							onChange={(e: any) => changeValues(e, index)}
							required
						/>
					</div>

					{payment.status !== 'оплаченный' && (
						<div className='d-flex m-1  border-0'>
							<Button
								style={{
									borderRadius: 0,
									borderTopLeftRadius: 10,
									borderBottomLeftRadius: 10,
								}}
								isDisable={payment.status === 'оплаченный' || status}
								onClick={addItem}
								className='col-6 h-50 delete-add-dental add-dental'>
								<Icon icon={'AddCircle'} color={'info'} size={'lg'} />
							</Button>
							<Button
								style={{
									borderRadius: 0,
									borderTopRightRadius: 10,
									borderBottomRightRadius: 10,
								}}
								isDisable={payment.status === 'оплаченный' || status}
								onClick={() => deleteItem(index)}
								className='col-6 h-50 delete-add-dental delete-dental'>
								<Icon icon={'Delete'} color={'danger'} size={'lg'} />
							</Button>
						</div>
					)}
				</div>
			))}
		</div>
	);
});

export default Payment;
