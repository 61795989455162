import React, { FC, memo } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import { TotalSum } from '../../../../../custom/components/tables/columns/TotalSum';
import { useQuery } from 'react-query';
import { ActService } from '../../../../../custom/api/service/act.service';
import * as qs from 'qs';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface IAmountComponentProps {
	reset(...args: unknown[]): unknown;
	values: any;
	dateDay: Date | null;
	allDayButton: boolean;
	isUserDoctor: boolean;
	dateInterval: any;
	filterQuery: any;
	filterQueryMeta: any;
	isSmallDesktop: boolean;
}

const AmountComponent: FC<IAmountComponentProps> = memo(
	({
		reset,
		values,
		dateDay,
		allDayButton,
		isUserDoctor,
		dateInterval,
		filterQuery,
		filterQueryMeta,
		isSmallDesktop,
	}) => {
		const DebounsedSearch = useDebounce(values);
		const { data: actAmount } = useQuery(
			['actAmount', DebounsedSearch, dateDay, allDayButton, dateInterval, isUserDoctor],
			() =>
				ActService.actFilterCount(
					qs.stringify({
						includeMeta: [
							{
								association: 'estimate',
								include: [
									{
										association: 'visit',
										required: true,
										include: filterQuery,
										where: filterQueryMeta,
									},
								],
							},
						],
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		return (
			<>
				{!!actAmount?.data && <TotalSum text={actAmount?.data} status={values?.statuses} />}
				<Button color='info' onClick={reset} isLight>
					Сбросить все {isSmallDesktop ? 'выбранные' : ''} даты и фильтры
				</Button>
			</>
		);
	},
);

export default AmountComponent;
