import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export interface IFormDataContextProps {
	formData: any;
	setFormData: any;
	default_form_data: any;
}
const FormDataContext = createContext<IFormDataContextProps>({} as IFormDataContextProps);

interface IFormDataContextProviderProps {
	children: ReactNode;
}

export const FormDataContextProvider: FC<IFormDataContextProviderProps> = ({ children }) => {	
	const [formData, setFormData] = useState({
		info_theeth: [],
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		dicount: null,
		dicount_type_is_percent: false,
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		event_lor: [],
		event_cosmetology: [],
		referral: [],
		referral_lor: [],
		referral_cosmetolog: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
		globalData: [],
		rightMindalData: [],
		leftMindalData: [],
		right: [],
		left: [],
	});

	const default_form_data = {
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		event_lor: [],
		event_cosmetology: [],
		referral: [],
		referral_lor: [],
		referral_cosmetolog: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
		globalData: [],
		rightMindalData: [],
		leftMindalData: [],
		rightData: [],
		leftData: [],
	};

	const value = useMemo(
		() => ({
			formData,
			default_form_data,
			setFormData,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formData],
	);

	return <FormDataContext.Provider value={value}>{children}</FormDataContext.Provider>;
};

// Define PropTypes for FormDataContextProvider component

// Export FormDataContextProvider component
FormDataContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FormDataContext;
