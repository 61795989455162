import React, { FC } from 'react';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import { TColor } from '../../../../type/color-type';
import OffCanvasComponentDoctor from './OffCanvasComponentCard/OffCanvasComponentDoctor';
import OffCanvasComponentAct from './OffCanvasComponentCard/OffCanvasComponentAct';
import OffCanvasComponentVisit from './OffCanvasComponentCard/OffCanvasComponentVisit';
import OffCanvasComponentService from './OffCanvasComponentCard/OffCanvasComponentService';
import OffCanvasComponentMedicalCase from './OffCanvasComponentCard/OffCanvasComponentMedicalCase';
import OffCanvasComponentPatient from './OffCanvasComponentCard/OffCanvasComponentPatient';

interface IOffCanvasComponentProps {
	setEventAdding(...args: unknown[]): unknown;
	eventAdding: any;
	canvasData: any;
	canvasColor: TColor;
}

const OffCanvasComponent: FC<IOffCanvasComponentProps> = ({
	canvasColor,
	setEventAdding,
	eventAdding,
	canvasData,
}) => {
	return (
		<OffCanvas
			setOpen={(status: boolean) => {
				setEventAdding(status);
			}}
			isOpen={eventAdding}
			titleId='canvas-title'
			style={{ width: '25dvw' }}>
			<OffCanvasHeader
				setOpen={(status: boolean) => {
					setEventAdding(status);
				}}
				className='ms-4'
				style={{ marginRight: '2rem' }}>
				<OffCanvasTitle id='canvas-title'>Информация о визите</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody   className='px-4'>
				<div className='row g-4'>
					<div className='col-12'>
						{canvasData?.estimate?.act && (
							<OffCanvasComponentAct
								canvasData={canvasData}
								canvasColor={canvasColor}
							/>
						)}
						<OffCanvasComponentVisit
							canvasData={canvasData}
							canvasColor={canvasColor}
						/>

						<OffCanvasComponentService
							canvasData={canvasData}
							canvasColor={canvasColor}
						/>

						<OffCanvasComponentMedicalCase
							canvasData={canvasData}
							canvasColor={canvasColor}
						/>
						<OffCanvasComponentDoctor
							canvasData={canvasData}
							canvasColor={canvasColor}
						/>

						<OffCanvasComponentPatient
							canvasData={canvasData}
							canvasColor={canvasColor}
						/>
					</div>
				</div>
			</OffCanvasBody>
		</OffCanvas>
	);
};
export default OffCanvasComponent;
