import React, { FC, memo, useState } from 'react';
import PaginationButtons from '../../../../../components/PaginationButtons';
import { IService } from '../../../../../custom/api/interface/service.interface';
import useDarkMode from '../../../../../hooks/useDarkMode';
import * as qs from 'qs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ServicesFiltration from './ServicesFiltration';
import { ServicesListButton } from '../../components/ServicesListButton';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import { IVisit } from '../../../../../custom/api/interface/visit.interface';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { useDebounce } from '../../../../../hooks/useDebounce';
// import { literal } from '';

interface IServicesProps {
	setChoosenService(...args: unknown[]): unknown;
	setLocalChosenService(...args: unknown[]): unknown;
	setServicesPerPage(...args: unknown[]): unknown;
	localChoosenServices: any;
	choosenServices: any;
	createVisit: any;
	isStage: any;
	medicalCard: any;
	stage: any;
	values: any;
	stage_type: string;
	filter: any;
	servicesPerPage: any;
	setFilter(...args: unknown[]): unknown;
	handleCheckboxChange(...args: unknown[]): unknown;
}

const Services: FC<IServicesProps> = memo(
	({
		handleCheckboxChange,
		choosenServices,
		setChoosenService,
		localChoosenServices,
		setServicesPerPage,
		setLocalChosenService,
		stage_type,
		medicalCard,
		stage,
		createVisit,
		isStage,
		setFilter,
		values,
		filter,
		servicesPerPage,
	}) => {
		const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
		const { themeStatus } = useDarkMode();
		const changeChosenServicesValue = (
			key: string,
			value: string | number,
			index: number,
			uuid: string,
		) => {
			setChoosenService((prev: any) => {
				const updatedServices = [...prev];
				if (updatedServices[index]) {
					updatedServices[index] = {
						...updatedServices[index],
						[key]: value,
					};
				}
				setLocalChosenService([...updatedServices]);
				return updatedServices;
			});
		};

		const filterDebounce = useDebounce(filter);
		const DebounsedSearch = useDebounce(values);
		const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
			[
				'services',
				DebounsedSearch,
				servicesCurrentPage,
				servicesPerPage,
				isStage,
				stage,
				medicalCard,
				filterDebounce,
			],
			() =>
				ServiceService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: servicesPerPage,
							page: DebounsedSearch?.serviceName.length ? 1 : servicesCurrentPage,
							order: { createdAt: 'DESC' },
						},

						includeMeta: [
							{
								association: 'user',
							},
							{
								association: 'clinic',
								...(!!filter.clinic && {
									where: {
										uuid: filter.clinic,
									},
								}),
							},
							{
								association: 'category',
								...(!!filter.category && {
									where: {
										uuid: filter.category,
									},
								}),
							},
						],
						filterMeta: {
							...(stage_type === 'стоматология' && {
								service_type: ['bridge', 'event', 'deletion', 'implant'],
							}),
							...(stage_type === 'косметология' && {
								service_type: 'cosmetology',
							}),
							...(stage_type === 'лор' && {
								service_type: 'lor',
							}),
							...(values.serviceName && {
								...(values.serviceName && /\d/.test(values.serviceName)
									? { serviceId: { iLike: `%${values.serviceName}%` } }
									: { title: { iLike: `%${values.serviceName}%` } }),
							}),
							...(!!filter.treatment_type && {
								treatment_type: filter.treatment_type,
							}),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const { data: visits_services }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
			['visits_services', stage],
			() =>
				VisitService.findAll(
					qs.stringify({
						filterMeta: {
							stageUuid: stage,
						},
						includeMeta: [
							{
								association: 'services',
							},
						],

						queryMeta: {
							order: { createdAt: 'DESC' },
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				enabled: !!stage,
			},
		);

		const queryClient = useQueryClient();
		const { mutate: addFavorite } = useMutation(
			(inputDto: any) => ServiceService.createFavorite(inputDto),
			{
				onSuccess: () => {
					queryClient.invalidateQueries('services');
				},
				onError: (error) => {
					console.error(error); // Using console.error for error logging
				},
			},
		);

		return (
			<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
				<span className='fs-5 fw-bold'>Список Услуг</span>
				<div className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
					<div className='d-flex flex-column gap-2 mb-3'>
						<ServicesFiltration
							setFilter={setFilter}
							handleCheckboxChange={handleCheckboxChange}
							filter={filter}
							values={values}
						/>
						{services?.data.length ? (
							services?.data?.map((i: IService, index: number) => (
								<ServicesListButton
									key={`${i.uuid}-${i.serviceId}-${i.service_type}-servicesListButton`}
									index={index}
									addFavorite={addFavorite}
									type={i?.service_type}
									chosenServices={choosenServices}
									setChosenServices={setChoosenService}
									changeChosenServicesValue={changeChosenServicesValue}
									localChosenServices={localChoosenServices}
									setLocalChosenServices={setLocalChosenService}
									item={{
										...i,
										...(i?.service_type === 'bridge'
											? { start: '', end: '' }
											: { theeth: '' }),
										count: '1',
										amount: i.price,
									}}
									isDisabled={
										!createVisit &&
										!isStage &&
										visits_services?.data.some(
											(item: any) =>
												item?.services.some(
													(service: any) => service?.uuid === i?.uuid,
												),
										)
									}
									handleCheckboxChange={handleCheckboxChange}
									themeStatus={themeStatus}
								/>
							))
						) : (
							<h4>Услуги не найдены</h4>
						)}
					</div>
					{services?.meta?.count > servicesPerPage && (
						<div className='col-12'>
							<PaginationButtons
								data={services || []}
								label='customers'
								className='card-footer'
								small
								filterPagination
								setCurrentPage={setServicesCurrentPage}
								currentPage={servicesCurrentPage}
								perPage={servicesPerPage}
								setPerPage={setServicesPerPage}
								totalPage={Math.ceil(services?.meta?.count / servicesPerPage) || 1}
							/>
						</div>
					)}
				</div>
			</div>
		);
	},
);

export default Services;
