import React, { FC, memo, useEffect, useState } from 'react';
import PaginationButtons, {
	dataPagination,
	PER_COUNT_FILTER,
} from '../../../../../components/PaginationButtons';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { ServicesList } from '../../components/ServicesList';
import { itemIndexInArray } from '../../../../../utils/functions';

interface IHeaderProps {
	services: any;
	setChosenService(...args: unknown[]): unknown;
	setLocalChosenService(...args: unknown[]): unknown;
	localChoosenServices: any;
	choosenServices: any;
	choosenServicesData: any;
	createVisit: any;
	isStage: any;
	hendelDeleteService(...args: unknown[]): unknown;
}

const ChoosenServices: FC<IHeaderProps> = memo(
	({
		choosenServicesData: chosenServicesData,
		hendelDeleteService: handleDeleteService,
		choosenServices: chosenServices,
		setChosenService,
		localChoosenServices: localChosenServices,
		setLocalChosenService,
		services,
		createVisit,
		isStage,
	}) => {
		const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
		const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);
		const [arrayLength, setArrayLength] = useState<number>(0);
		useEffect(() => {
			const totalPages = Math.ceil(chosenServicesData?.length / servicesPerPage) || 1;
			setArrayLength(totalPages);
			if (servicesCurrentPage > totalPages) {
				setServicesCurrentPage(totalPages);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [chosenServicesData, servicesPerPage]);

		useEffect(() => {
			if (chosenServicesData?.length === servicesPerPage) {
				setServicesCurrentPage(1);
			}
		}, [chosenServicesData?.length, setServicesCurrentPage, servicesPerPage]);

		return (
			<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
				<span className='fs-5 fw-bold'>Выбранные Услуги</span>
				<div className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
					<div className='d-flex flex-column flex-wrap col-12 gap-2'>
						{chosenServicesData?.length ? (
							dataPagination(
								chosenServicesData,
								servicesCurrentPage,
								servicesPerPage,
							).map((i: IService, index: number) => (
								<ServicesList
									key={`${i.uuid}-${i.serviceId}-${i.service_type}-choose`}
									type={i.service_type}
									localChosenServices={localChosenServices}
									setLocalChosenServices={setLocalChosenService}
									chosenServices={chosenServices}
									setChosenServices={setChosenService}
									index={itemIndexInArray(chosenServicesData, i)}
									item={i}
									disable
									isDisabled={
										!createVisit &&
										!isStage &&
										services
											?.map((item: any) => item.service)
											?.some(
												(item: any) =>
													item?.services?.some(
														(service: any) => service?.uuid === i?.uuid,
													),
											)
									}
									handleDeleteService={handleDeleteService}
								/>
							))
						) : (
							<h4>Услуги не выбраны</h4>
						)}
					</div>
					{chosenServicesData?.length > 0 && (
						<div className='col-12'>
							<PaginationButtons
								data={chosenServicesData || []}
								label='services'
								small
								filterPagination
								serviceModal
								setCurrentPage={setServicesCurrentPage}
								currentPage={servicesCurrentPage}
								perPage={servicesPerPage}
								setPerPage={setServicesPerPage}
								totalPage={arrayLength}
							/>
						</div>
					)}
				</div>
			</div>
		);
	},
);

export default ChoosenServices;
