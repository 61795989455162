import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import FormGroup from '../../../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../../../../components/bootstrap/forms/Textarea';
import Button from '../../../../../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { PatientService } from '../../../../../../../custom/api/service/patient.service';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { ClinicService } from '../../../../../../../custom/api/service/clinic.service';
import { UserService } from '../../../../../../../custom/api/service/user.service';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { VisitService } from '../../../../../../../custom/api/service/visit.service';
import CustomSelect from '../../../../../../../custom/components/Select/select';
import ScheduleModal from '../../../../../../../custom/pages/doctors/schedule/ScheduleModal';
import useDarkMode from '../../../../../../../hooks/useDarkMode';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { GoogleService } from '../../../../../../../custom/api/service/google-callendar.service';
import { ConsultationListService } from '../../../../../../../custom/api/service/consulatation-list.service';
import { IConsultationList } from '../../../../../../../custom/api/interface/consultation-list.interface';
import DentalContext from '../../../../../../../contexts/dentalContext';
import { connectClick } from '../../../../../../../utils/functions';
import VisitInfoComponent from './CreateVisitComponents/VisitInfoComponent';
import ServiceChooseModal from '../../../../../../../custom/pages/services/ServiceChooseModal/ServiceChooseModal';
import { StageService } from '../../../../../../../custom/api/service/stage.service';
import { IStage } from '../../../../../../../custom/api/interface/stage.interface';
import Loading from '../../../../../../../custom/components/loading/Loading';
import DentalContextFunctions from '../../../../../../../contexts/dentalContextFunctions';

interface CreateConsultationParams {
	inputDto: IConsultationList;
	uuid: string;
}

type NotificationPlacement = NotificationArgsProps['placement'];

interface CreateVisitProps {
	patientId: string;
	setIsOpen(...args: unknown[]): unknown;
	setServiceUuid(...args: unknown[]): unknown;
	createVisit_state: boolean;
	serviceId: null | [];
	medicalCaseUuid: null | string;
	stageUuid: null | string;
	clinicUuid: string | null;
	setValues(...args: unknown[]): unknown;
	values: any;
	visitData: any;
	schedulModalOpen: any;
	setSchedulModalOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setVisitData(...args: unknown[]): unknown;
	visitDate?: any;
	setCreateVisit(...args: unknown[]): unknown;
	visit?: IVisit;
}

const CreateVisit: FC<CreateVisitProps> = ({
	patientId,
	clinicUuid,
	setCreateVisit,
	createVisit_state,
	setValues,
	values,
	setIsOpen,
	serviceId,
	medicalCaseUuid,
	stageUuid,
	visitData,
	schedulModalOpen,
	setSchedulModalOpen,
	changeValues,
	setServiceUuid,
	setVisitData,
	visit,
}) => {
	const [search, setSearch] = useState<any>({});
	const { getInfoForCreateVisit } = useContext(DentalContextFunctions);
	const { setSelectMany, setSelectedItems, setSelectedItemsAll, setSelectManyAll } =
		useContext(DentalContext);
	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const DebouncedSearch = useDebounce(search);
	const { themeStatus } = useDarkMode();
	const [api, contextHolder] = notification.useNotification();
	const [isGoogleCalendar, setIsGoogleCallendar] = useState<boolean>(false);
	const [isGoogleCalendarLink, setIsGoogleCallendarLink] = useState<any>(null);
	const [consultationListUuid, setConslutationListUuid] = useState<any>('');
	const [consultationListData, setConslutationListdata] = useState<any>({});

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};
	const getServices = useCallback((services: any) => {
		return services ? services.map((relation: any) => relation.event) : undefined;
	}, []);

	const getValue = useCallback((visitValue: any, defaultValue: any) => {
		return visitValue !== undefined ? visitValue : defaultValue;
	}, []);

	const { data: stage }: { data: IStage[] | any; isLoading: boolean } = useQuery(
		['stage', stageUuid],
		() => (stageUuid ? StageService.findOne(stageUuid) : Promise.resolve(null)),
		{
			refetchOnWindowFocus: false,
			enabled: !!stageUuid,
		},
	);

	const { data: consulatation_list }: { data: any[] | any; isLoading: boolean } = useQuery(
		['consulatation_list', stage?.uuid],
		() =>
			ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage?.uuid,
							},
						},
					],
				}),
			),
		{
			enabled: !!stage?.uuid,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	useEffect(() => {
		if (isUserDoctor) {
			changeValues('clinicUuid', user_role?.roles[0]?.clinicUuid);
		}
	}, [changeValues, isUserDoctor, user_role?.roles]);

	const queryClient = useQueryClient();

	const { data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() =>
			PatientService.findOne(
				patientId,
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					filterMeta: {},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const changeSearchValue = useCallback((key: string, value: string | number) => {
		setSearch((prev: any) => ({ ...prev, [key]: value }));
	}, []);

	const { data: clinics } = useQuery<IClinic | null, Error>(
		['clinics', DebouncedSearch],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (isGoogleCalendar && isGoogleCalendarLink && typeof isGoogleCalendarLink === 'string') {
			if (
				isGoogleCalendar &&
				isGoogleCalendarLink &&
				typeof isGoogleCalendarLink === 'string'
			) {
				connectClick(isGoogleCalendarLink);
			}
		}
	}, [isGoogleCalendar, isGoogleCalendarLink]);

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			if (values.stageUuid) {
				if (!!values.consulatation_list_uuid && !!values.form_data) {
					updateConslutationList({
						uuid: values.consulatation_list_uuid,
						inputDto: { dentalFormula: values.form_data },
					});
				} else {
					setValues({});
					queryClient.invalidateQueries('consultation_list');
					queryClient.invalidateQueries('stages');
					queryClient.invalidateQueries('visits');
					setIsOpen(false);
					setSelectMany(false);
					setSelectedItems([]);
					setSelectManyAll(false);
					setSelectedItemsAll([]);
					setCreateVisit(false);
				}
			}
		},
		onError: (error: any) => {
			if (error.response.data.message === 'calendar_auth_failed') {
				setIsGoogleCallendar(true);
			}
			if (error.response.data.message === 'choose_user_date_time')
				openNotification(
					'bottomRight',
					'Важно !',
					'Выберите дату и врача чтобы создать визит',
				);
			if (error.response.data.message === 'cant_create_visit_whith_canceled_stage') {
				openNotification(
					'bottomRight',
					'Важно !',
					'Нельзя создать визит в отменённом этапе',
				);
			}

			if (error.response.data.message === 'cant_update_visit_whith_canceled_stage') {
				openNotification(
					'bottomRight',
					'Важно !',
					'Нельзя изменить визит в отменённом этапе',
				);
			}

			if (error.response.data.message === 'cant_update_canceled_visit') {
				openNotification('bottomRight', 'Важно !', 'Нельзя изменить отменённый визит');
			}
			if (error.response.data.message === 'end_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время окончания визита');

			if (error.response.data.message === 'start_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время начала визита');

			if (error.response.data.message === 'cant_select_past_date') {
				openNotification('bottomRight', 'Важно !', 'Нельзя выбрать прошедшую дату');
			}

			if (error.response.data.message === 'can_not_create_visit_whitout_services')
				openNotification('bottomRight', 'Важно !', 'выберите услуги чтобы создать визит');
			if (error.response.data.message === 'time_range_choosen')
				openNotification('bottomRight', 'Важно !', 'выбранное время уже занято');
		},
	};

	const { mutate: updateConslutationList, isLoading: listLoading } = useMutation(
		({ uuid, inputDto }: CreateConsultationParams) =>
			ConsultationListService.update(uuid, inputDto),
		{
			onSuccess: () => {
				setValues({});
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('stages');
				queryClient.invalidateQueries('visits');
				setCreateVisit(false);
				setSelectMany(false);
				setSelectedItems([]);
				setSelectManyAll(false);
				setSelectedItemsAll([]);
				setIsOpen(false);
			},
			onError: (error) => {
				console.error(error);
			},
		},
	);
	const { mutate, isLoading: visitLoading } = useMutation(
		(inputDto: IVisit) => VisitService.create(inputDto),
		queryOptions,
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
		await mutate({
			...values,
			services: values.services,
		});
	};

	const { data: calendarAccess } = useQuery(
		['calendar_access', isGoogleCalendar],
		async () => {
			if (isGoogleCalendar) {
				return await GoogleService.generateGoogleCallendarAccses();
			}
			return null;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		setIsGoogleCallendarLink(calendarAccess);
	}, [isGoogleCalendar, calendarAccess]);

	useEffect(() => {
		if (!createVisit_state) {
			return;
		}
		if (values?.services?.length > 0 && consultationListData && consultationListUuid) {
			const data = getInfoForCreateVisit(
				values.services,
				JSON.stringify(consultationListData),
			);
			setValues((prev: any) => ({
				...prev,
				services: values?.services,
				form_data: data.data,
				consulatation_list_uuid: consultationListUuid,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.services, createVisit_state, consultationListData, consultationListUuid]);

	useEffect(() => {
		if (consulatation_list?.data?.length > 0) {
			setConslutationListUuid(consulatation_list?.data[0]?.uuid);
			setConslutationListdata(consulatation_list?.data[0]?.dentalFormula);
		}
	}, [consulatation_list]);

	useEffect(() => {
		if (!createVisit_state) {
			return;
		}
		if (consulatation_list?.data[0]?.dentalFormula) {
			const newValues: any = {
				services: getValue(getServices(visit?.services), serviceId) || [],
				clinicUuid: getValue(visit?.clinicUuid, clinicUuid),
				complaint: getValue(undefined, values.complaint),
				visitNumber: getValue(undefined, values.visitNumber),
				patientUuid: patientId,
				startDate: values?.startDate ?? '',
				endDate: values?.endDate ?? '',
				userUuid: values?.userUuid ?? '',
				userFullName: values?.userFullName ?? '',
				visitDate: values?.visitDate ?? '',
			};
			if (medicalCaseUuid !== null && stageUuid !== null) {
				newValues.medicalCaseUuid =
					visit?.medicalCaseUuid !== undefined ? visit.medicalCaseUuid : medicalCaseUuid;
				newValues.stageUuid = visit?.stageUuid !== undefined ? visit.stageUuid : stageUuid;
			}
			const data = getInfoForCreateVisit(
				newValues?.services,
				JSON.stringify(consulatation_list?.data[0]?.dentalFormula),
			);
			setValues({
				...newValues,
				services: newValues?.services,
				form_data: data?.data,
				consulatation_list_uuid: consulatation_list?.data[0]?.uuid,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		patientId,
		medicalCaseUuid,
		stageUuid,
		visit,
		serviceId,
		clinicUuid,
		createVisit_state,
		getInfoForCreateVisit,
		consultationListData,
		consulatation_list?.data,
	]);

	return (
		<>
			<form style={{ width: '100%' }} onSubmit={handleSubmit}>
				<div className='d-flex flex-row justify-content-around align-items-start mb-4 col-12'>
					{contextHolder}
					<div className='d-flex flex-column gap-4 col-5'>
						<FormGroup className='col-12' id='visitNumber' label='Номер визита'>
							<Input
								required
								type='number'
								value={values.visitNumber}
								onChange={(e: any) => changeValues('visitNumber', e.target.value)}
							/>
						</FormGroup>
						<FormGroup className='col-12' id='patient' label='Пациент'>
							<Input
								disabled={true}
								value={`${patient?.surname} ${patient?.name} ${patient?.middleName}`}
								type='text'
							/>
						</FormGroup>
						<FormGroup id='clinic' label='Филиал' className='mb-2'>
							<CustomSelect
								disabled={!!visit || isUserDoctor}
								placeholder={'Выберите филиал'}
								name={'clinic'}
								value={values.clinicUuid}
								options={clinics?.data.map((item: IClinic, index: number) => {
									return {
										value: item.uuid,
										label: item.name,
									};
								})}
								changeSearchValue={changeSearchValue}
								changeValues={changeValues}
							/>
						</FormGroup>

						<FormGroup className='col-12' id='complaint' label='Жалоба'>
							<Textarea
								required
								value={values.complaint}
								onChange={(e: any) => changeValues('complaint', e.target.value)}
							/>
						</FormGroup>
					</div>
					<div className='d-flex flex-column gap-5 col-5'>
						{' '}
						<FormGroup label='Услуги' className='col-12'>
							<Button
								style={{
									boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
								}}
								onClick={() => {
									setPopperIsOpenService(true);
								}}
								color={themeStatus}
								className='text-nowrap me-2 col-12'>
								Управление услугами
							</Button>
						</FormGroup>
						<FormGroup id='doctor' label=' ' className='mb-2'>
							<Button
								onClick={() => {
									setSchedulModalOpen(true);
								}}
								isLight
								color='primary'
								className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-between'>
								Выбрать врача, дату и время визита
							</Button>
						</FormGroup>
						<VisitInfoComponent values={values} />
					</div>
				</div>
				<div className='d-flex justify-content-between w-100'>
					<Button
						className='fw-bold fs-6'
						color='link'
						isLight
						style={{ color: '#0D6EFD' }}
						onClick={() => {
							setCreateVisit(false);
						}}>
						Назад
					</Button>
					<Button
						isDisable={listLoading || visitLoading}
						className='fw-bold fs-6 position-relative'
						color='info'
						type='submit'>
						<Loading loading={listLoading || visitLoading} />
						Создать визит
					</Button>
				</div>
			</form>
			{!!visit ? (
				<ScheduleModal
					medicalCaseUuid={medicalCaseUuid ?? null}
					prevVisit={visit}
					clinicUuid={values?.clinicUuid}
					changeValues={changeValues}
					visitData={visitData}
					setVisitData={setVisitData}
					isOpen={schedulModalOpen}
					setIsOpen={setSchedulModalOpen}
					values={values}
				/>
			) : (
				<ScheduleModal
					medicalCaseUuid={medicalCaseUuid ?? null}
					clinicUuid={values?.clinicUuid}
					changeValues={changeValues}
					visitData={visitData}
					setVisitData={setVisitData}
					isOpen={schedulModalOpen}
					values={values}
					setIsOpen={setSchedulModalOpen}
				/>
			)}
			<ServiceChooseModal
				stage_type={stage?.type}
				setIsOpen={setPopperIsOpenService}
				isOpen={popperIsOpenService}
				setServiceUuid={setServiceUuid}
				stage={stageUuid}
				setChosenValues={setValues}
				serviceDropdownInfo={values?.services}
				createVisit
			/>
		</>
	);
};

export default CreateVisit;
