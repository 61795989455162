import { PhoneNumberUtil } from 'google-libphonenumber';
import EVENT_STATUS from '../common/data/enumEventStatus';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const generateUuid = () => {
	return uuidv4();
};

interface Tooth {
	id: string;
	diagnostics: string;
	note: string;
	disease: {
		root: string;
		canal: string;
		gum: string;
	};
	enamel: Record<string, JSX.Element>;
}

interface DentalFormula {
	top: Tooth[];
	bottom: Tooth[];
}

export const VisitStatusStyles = (status: any) => {
	switch (status) {
		case EVENT_STATUS.PENDING.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(255, 193, 7, 0.3)',
					borderColor: 'rgba(255, 193, 7, 1)',
				},
				iconeStyles: {
					color: '#FFCF52',
					iconeColor: {
						color: EVENT_STATUS.PENDING.color,
					},
				},
			};
		case EVENT_STATUS.APPROVED.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(25, 135, 84, 0.3)',
					borderColor: 'rgba(25, 135, 84, 1)',
				},
				iconeStyles: {
					color: '#198754',
					iconeColor: {
						color: EVENT_STATUS.APPROVED.color,
					},
				},
			};
		case EVENT_STATUS.IN_PROGRESS.name || 'На приеме':
			return {
				dateStyles: {
					backgroundColor: 'rgba(18, 102, 241, 0.3)',
					borderColor: 'rgba(18, 102, 241, 1)',
				},
				iconeStyles: {
					color: '#1266F1',
					iconeColor: {
						color: EVENT_STATUS.IN_PROGRESS.color,
					},
				},
			};

		case EVENT_STATUS.IN_EXPECTATION.name:
			return {
				dateStyles: {
					backgroundColor: 'rgb(249, 115, 0, 0.45)',
					borderColor: 'rgba(255, 165, 0, 1)',
				},
				iconeStyles: {
					color: 'rgb(249, 115, 0)',
					iconeColor: {
						color: EVENT_STATUS.IN_EXPECTATION.color,
					},
				},
			};
		case EVENT_STATUS.CANCELED.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(220, 53, 69, 0.30)',
					borderColor: '#DC3545',
				},
				iconeStyles: {
					color: '#DC3545',
					iconeColor: {
						color: EVENT_STATUS.CANCELED.color,
					},
				},
			};

		case EVENT_STATUS.DEBT.name:
			return {
				dateStyles: {
					backgroundColor: '#e183cd',
					borderColor: '#ba0092',
				},
				iconeStyles: {
					color: '#ba0092',
					iconeColor: {
						color: '#F120C4',
					},
				},
			};
		case EVENT_STATUS.CALCULATION.name:
			return {
				dateStyles: {
					backgroundColor: 'rgba(220, 53, 69, 0.30)',
					borderColor: '#DC3545',
				},
				iconeStyles: {
					color: '#DC3545',
					iconeColor: {
						color: EVENT_STATUS.CALCULATION.color,
					},
				},
			};
		case EVENT_STATUS.VISIT_ENDED.name:
			return {
				dateStyles: {
					backgroundColor: '#6de2ac',
					borderColor: '#00b260',
				},
				iconeStyles: {
					color: '#00b260',
					iconeColor: {
						color: '#23D080',
					},
				},
			};
		default:
			return null;
	}
};

export const formatDate = (date: Date): any => {
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1;
	const day: number = date.getDate();

	const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
	const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

	const formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;
	const formattedDateDot: string = `${formattedDay}.${formattedMonth}.${year}`;

	return {
		date1: formattedDate,
		date2: formattedDateDot,
	};
};

export const getDayOfWeekInRussian = (dateString: string) => {
	if (dateString) {
		const date = DateTime.fromISO(dateString);
		const russianDays: string[] = [
			'Понедельник',
			'Вторник',
			'Среда',
			'Четверг',
			'Пятница',
			'Суббота',
			'Воскресенье',
		];
		const dayIndex = date.weekday - 1; // Luxon uses 1-based index
		return russianDays[dayIndex];
	} else {
		return '';
	}
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
	const phoneUtil = PhoneNumberUtil.getInstance();
	const number = phoneNumber.replace(/\+/g, '');
	try {
		const parsedNumber = phoneUtil.parse(number);
		return phoneUtil.isValidNumber(parsedNumber);
	} catch (e) {
		const allRegions: string[] = phoneUtil.getSupportedRegions();
		let isValidForAnyRegion: boolean = false;
		for (const region of allRegions) {
			try {
				const parsedNumber = phoneUtil.parse(number, region);
				if (phoneUtil.isValidNumber(parsedNumber)) {
					isValidForAnyRegion = true;
					break;
				}
			} catch (e) {
				continue;
			}
		}
		return isValidForAnyRegion;
	}
};

export const getFontSizeFromLocalStorage = () => {
	try {
		const fontSize = localStorage.getItem('fontSize');
		if (fontSize) {
			const parsedFontSize = JSON.parse(fontSize);
			return parsedFontSize.value || 16;
		}
	} catch (error) {
		console.error('Error parsing fontSize from localStorage:', error);
	}

	const defaultFontSize = { name: 'Нормальный', value: 16 };
	localStorage.setItem('fontSize', JSON.stringify(defaultFontSize));
	return defaultFontSize.value;
};

export const parseDateTime = (dateString: string): { date: string; time: string } => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');

	const formattedDate = `${day}.${month}.${year}`;
	const formattedTime = `${hours}:${minutes}`;

	return {
		date: formattedDate,
		time: formattedTime,
	};
};

export const formatPrice = (
	amount: number | string,
	currency: string,
	locale: string = 'en-US',
): string => {
	try {
		const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
		if (isNaN(parsedAmount)) {
			return '';
		}
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currency,
		}).format(parsedAmount);
	} catch (error) {
		console.error('Error formatting price:', error);
		return amount?.toString();
	}
};

export const formatDateToYYYYMMDD = (date: any) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');
	return `${day}.${month}.${year}`;
};

export const connectClick = (link: string) => {
	const width_ = 500;
	const height_ = 400;
	const left = window.screenX + (window.outerWidth - width_) / 2;
	const top = window.screenY + (window.outerHeight - height_) / 2.5;
	const title = `GOOGLE CALLENDAR ACCESS`;
	const url = link;
	if (url) {
		window.open(url, title, `width=${width_},height=${height_},left=${left},top=${top}`);
	}
};

export const getServices = (services: any) => {
	return services ? services.map((relation: any) => relation.event) : undefined;
};

export const getUserFullName = (user: any) => {
	return user ? `${user.name} ${user.surname} ${user.middleName}` : undefined;
};

export const getValue = (visitValue: any, defaultValue: any) => {
	return visitValue !== undefined ? visitValue : defaultValue;
};

export const checkDateInRanges = (startDate: string, endDate: string, dateRanges: any) => {
	const start = new Date(startDate);
	const end = new Date(endDate);
	return dateRanges?.some((range: any, index: number) => {
		const rangeStart = new Date(range.startDate);
		const rangeEnd = new Date(range.endDate);
		if (start >= rangeStart && start <= rangeEnd) {
			return true;
		}
		if (start <= rangeStart && start <= rangeEnd && end >= rangeStart && end >= rangeEnd) {
			return true;
		}
		if (end >= rangeStart && end <= rangeEnd) {
			return true;
		}
		return false;
	});
};

export const calculateDuration = (startTime: string, endTime: string): any => {
	const [startHour, startMinute] = startTime.split(':').map(Number);
	const [endHour, endMinute] = endTime.split(':').map(Number);

	const startTotalMinutes = startHour * 60 + startMinute;
	const endTotalMinutes = endHour * 60 + endMinute;

	const durationInMinutes = endTotalMinutes - startTotalMinutes;

	return durationInMinutes;
};

export const calculateEndTime = (start: string, duration: number): string => {
	const startTime = dayjs(start, 'HH:mm');
	const endTime = startTime.add(duration, 'minute');
	return endTime.format('HH:mm');
};

export const joinTimeAndDate = (time: string, date: string): any => {
	const hour = time.split(':')[0];
	const minute = time.split(':')[1]; // Corrected from 'mimute' to 'minute'
	if (!isNaN(+dayjs(date).hour(+hour).minute(+minute))) {
		const formattedTime = dayjs(date).hour(+hour).minute(+minute).toISOString();
		return `${date.split('T')[0]}T${formattedTime.split('T')[1].split('.')[0]}.000Z`;
	}
};
export const isDateBeforeNow = (date: Date | string): boolean => {
	const givenDate = new Date(date);
	const currentDate = new Date();
	return givenDate > currentDate;
};

export const calculateTimeFromNow = (targetDateTime: string | undefined): string | number => {
	if (!targetDateTime) {
		return 'Invalid date';
	}
	const now = moment();
	const then = moment(targetDateTime);
	const diffDays = now.diff(then, 'days');
	const diffMonths = now.diff(then, 'months');
	const diffYears = now.diff(then, 'years');
	if (diffDays <= 0) {
		return 0;
	} else if (diffYears >= 1) {
		return diffYears + ' лет';
	} else if (diffMonths >= 1) {
		return diffMonths + (diffMonths === 1 ? ' месяц ' : ' месяцев');
	} else {
		return diffDays + (diffDays === 1 ? ' день' : ' дней');
	}
};

export const updateToothData = (
	dentalFormula: DentalFormula,
	toothId: string,
	newDiagnostics: string,
	newNote: string,
): DentalFormula => {
	const updateTooth = (tooth: Tooth) => {
		if (tooth.id === toothId) {
			return {
				...tooth,
				diagnostics: newDiagnostics,
				note: newNote,
			};
		}
		return tooth;
	};

	const updatedTop = dentalFormula.top.map(updateTooth);
	const updatedBottom = dentalFormula.bottom.map(updateTooth);

	return {
		top: updatedTop,
		bottom: updatedBottom,
	};
};

export const findDataById = (key: string, id: string, object: any) => {
	const array = object;
	if (Array.isArray(array)) {
		if (key === 'bridge') {
			return array?.find((item) => item?.start === id || item?.end === id);
		} else return array?.find((item) => item?.theeth === id);
	}
	return null;
};

export const findAllDataById = (key: string, id: string, array: any[]) => {
	if (!Array.isArray(array)) return [];

	if (key === 'bridge') {
		return array.filter((item) => item.start === id || item.end === id);
	}

	return array.filter((item) => item.theeth === id);
};

export const processData = (planData: any, thisToothData: any) => {
	if (
		planData.bridge ||
		planData.milk_theeth ||
		planData.implant ||
		planData.event ||
		planData.deletion ||
		planData.referral
	) {
		return {
			implant: findDataById('implant', thisToothData.id, planData.implant),
			milk_theeth: findDataById('milk_theeth', thisToothData.id, planData.milk_theeth),
			bridge: findDataById('bridge', thisToothData.id, planData.bridge),
			deletion: findDataById('deletion', thisToothData.id, planData.deletion),
			referral: findAllDataById('referral', thisToothData.id, planData.referral),
			event: findAllDataById('event', thisToothData.id, planData.event),
		};
	}
	return null;
};

export const circleSides = ['top_left', 'top_right', 'bottom_left', 'bottom_right'];
export const allSides = ['center', ...circleSides];

export const getSurfaceServices = (enamel: any, disease: any, theethId: string) => {
	const allSurfaces = allSides.reduce((acc: any[], side: string) => {
		const diseaseNames = disease.distal[side] || [];
		acc.push(
			...diseaseNames.map((diseaseName: string) => {
				return {
					id: theethId,
					title: diseaseName,
					side,
				};
			}),
		);
		return acc;
	}, []);
	return allSurfaces;
};

export const getChannelsServices = (
	rootsAndChannels: any,
	surfaceTypeIds: any[],
	theethId: string,
) => {
	return surfaceTypeIds.flatMap((i) => {
		return rootsAndChannels
			.filter((item: any) => item.surfaceTypeId === i.surfaceTypeId)
			.map((item: any) => ({ ...item, id: theethId })); // Add id here
	});
};

type SurfaceKey = 'top_right' | 'top_left' | 'bottom_left' | 'bottom_right' | 'center';

export const getSurfaceKey = (key: string): SurfaceKey | undefined => {
	if (key.startsWith('top_right')) return 'top_right';
	if (key.startsWith('top_left')) return 'top_left';
	if (key.startsWith('bottom_right')) return 'bottom_right';
	if (key.startsWith('bottom_left')) return 'bottom_left';
	if (key === 'center') return 'center';
	return undefined;
};

export const deepCopy = (obj: any): any => {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	if (obj instanceof Array) {
		return obj.map(deepCopy);
	}

	const copiedObj: any = {};
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			copiedObj[key] = deepCopy(obj[key]);
		}
	}
	return copiedObj;
};

export const deepCopyAll = (obj: any, seen = new WeakMap()) => {
	if (typeof obj !== 'object' || obj === null) return obj;
	if (seen.has(obj)) return seen.get(obj); // Prevent cyclic reference

	const copy: any = Array.isArray(obj) ? [] : {};
	seen.set(obj, copy); // Mark this object as seen

	Object.keys(obj).forEach((key) => {
		copy[key] = deepCopyAll(obj[key], seen); // Recursively copy properties
	});

	return copy;
};

export const itemIndexInArray = (arr: any[], item: any): number => {
	return arr.findIndex((service: any) => {
		if (service.uuid === item.uuid) {
			if (item?.service_type === 'bridge') {
				return (
					service?.start?.toString() === item?.start?.toString() &&
					service?.end?.toString() === item?.end?.toString()
				);
			} else {
				return (
					service?.theeth?.toString() === item?.theeth?.toString() &&
					service?.service_type === item?.service_type
				);
			}
		}
		return false;
	});
};

export const showPandingServices = (arr: string, services: string) => {
	if (arr && services) {
		const event_all = JSON.parse(arr);
		const event_coplated = JSON.parse(services);
		return event_all
			.filter((item: any) => {
				return item?.event_uuid;
			})
			.map((item: any) => {
				const existsInServices = event_coplated.some((dataItem: any) => {
					return item?.event_uuid === dataItem?.event_uuid;
				});
				return !existsInServices ? item : undefined;
			})
			?.filter((item: any) => item !== undefined);
	} else {
		return [];
	}
};

export const getDataForReset = (services: string, key: string) => {
	const services_data = JSON.parse(services);
	if (services_data.length > 0) {
		return services_data.filter((item: any) => {
			if (key !== 'event') {
				return item.service_type === key;
			} else {
				return item;
			}
		});
	} else {
		return [];
	}
};

const generateSequence = (
	start: string | number,
	end: string | number,
	data_formula: any,
	direction?: string,
): string[] => {
	if (!start || !end) {
		return [''];
	}
	let result: string[] = [];
	const startId = start?.toString();
	const endId = end?.toString();

	if (!direction) {
		direction = startId?.[0] === '1' || endId?.[0] === '2' ? 'top' : 'bottom';
	}

	const formula = data_formula?.[direction];
	if (!Array.isArray(formula)) {
		console.error(`Invalid direction or data structure: ${direction}`);
		return result;
	}

	const startIndex = formula.findIndex((tooth: any) => tooth?.id === startId);
	const endIndex = formula.findIndex((tooth: any) => tooth?.id === endId);

	if (startIndex < 0 || endIndex < 0) {
		return result;
	}

	if (startIndex < endIndex) {
		for (let i = startIndex + 1; i < endIndex; i++) {
			result.push(formula[i]?.id);
		}
	} else {
		for (let i = startIndex - 1; i > endIndex; i--) {
			result.push(formula[i]?.id);
		}
	}

	return result;
};

export const updateFormulaTheethDropDown = (data: any, default_formula_json: string) => {
	const new_data = JSON.parse(data);
	const default_formula = JSON.parse(default_formula_json);
	const propertiesToUpdate = [
		'bridge',
		'implant',
		'event',
		'referral',
		'empty',
		'deletion',
		'milk_theeth',
	];

	propertiesToUpdate.forEach((property) => {
		new_data.forEach((item: any) => {
			if (!item.start && !item.theeth) return;
			const toothId = item.start || item.theeth;

			const direction = toothId[0] === '1' || toothId[0] === '2' ? 'top' : 'bottom';

			const toothIndex = default_formula[direction].findIndex(
				(tooth: any) => tooth.id === toothId,
			);

			if (toothIndex > -1) {
				if (property === 'bridge') {
					let bridgeStart = item?.start;
					let bridgeEnd = item?.end;

					// Skip if bridgeStart or bridgeEnd is empty
					if (!bridgeStart || !bridgeEnd) return;

					const startIdx = default_formula[direction].findIndex(
						(tooth: any) => tooth.id === bridgeStart,
					);
					const endIdx = default_formula[direction].findIndex(
						(tooth: any) => tooth.id === bridgeEnd,
					);

					if (startIdx > -1 && endIdx > -1) {
						const correct_start = startIdx > endIdx ? bridgeEnd : bridgeStart;
						const correct_end = startIdx > endIdx ? bridgeStart : bridgeEnd;
						bridgeStart = correct_start;
						bridgeEnd = correct_end;
						const sequence = generateSequence(
							correct_start,
							correct_end,
							default_formula,
							direction,
						);
						default_formula[direction] = default_formula[direction].map(
							(tooth: any) => {
								if (tooth.id === correct_start) {
									return { ...tooth, bridge: true, bridge_position: 'start' };
								} else if (tooth.id === correct_end) {
									return { ...tooth, bridge: true, bridge_position: 'end' };
								} else if (sequence.includes(tooth.id)) {
									return {
										...tooth,
										bridge: true,
										bridge_position: 'center',
									};
								}
								return tooth;
							},
						);
					}
				} else {
					default_formula[direction][toothIndex] = {
						...default_formula[direction][toothIndex],
						[property]: true,
					};
				}
			}
		});
	});

	return JSON.stringify(default_formula);
};

export const copyObjectExcludingFields = (obj: any) => {
	const fieldsToExclude = ['image', 'roots_count', 'diagnostics', 'note', 'disease', 'enamel'];
	return Object.keys(obj).reduce((acc: any, key: any) => {
		if (!fieldsToExclude.includes(key)) {
			acc[key] = obj[key];
		}
		return acc;
	}, {});
};

export const getAmount = (services: any[]): number => {
	const amount = services.reduce((total: number, item: any) => {
		const itemAmount = Number(item.amount) || 0;
		if (item?.materials?.length > 0) {
			const materialsTotal = item.materials.reduce(
				(totalMaterial: number, itemMaterial: any) => {
					if (!itemMaterial?.price) {
						return 0;
					}
					const materialAmount = Number(itemMaterial?.price * +itemMaterial.count) || 0;
					return totalMaterial + materialAmount;
				},
				0,
			);

			return total + itemAmount + materialsTotal;
		}
		return total + itemAmount;
	}, 0);

	return amount;
};
