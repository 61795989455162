/* eslint-disable array-callback-return */
import React, { FC, useContext } from 'react';
import TheethItemComponent from './Theeth/TheethItemComponent';
import DentalContext from '../../../../../../contexts/dentalContext';

interface ITeethTableProps {
	setIsToothChosen: any;
	setIsToothCircleChosen: any;
	nullifyCards: any;
	isReadOnly?: boolean;
	className?: string | {} | null;
	isPrint?: boolean;
}

const TeethTable: FC<ITeethTableProps> = ({
	setIsToothChosen,
	setIsToothCircleChosen,
	nullifyCards,
	isReadOnly,
	className,
	isPrint,
}) => {
	const { dentalFormula } = useContext(DentalContext);
	return (
		<>
			<div
				style={{ height: 'unset', minHeight: 'unset' }}
				className={`col-12 d-flex align-items-end justify-content-center flex-wrap teeth-direction ${className}`}>
				<div
					style={{ ...(isPrint && { border: 'none' }) }}
					key={`dentalFormula-1`}
					className='col-6 d-flex justify-content-end gap-2 teeth-direction-items teeth-direction-items-one'>
					{dentalFormula?.top?.map((item: any, index: number) => {
						if (index + 1 <= 8) {
							return (
								<TheethItemComponent
									key={`${item.id}-${index}-items-one`}
									key_custom={`${item.id}-items-one`}
									data={item}
									onToothClick={setIsToothChosen}
									onToothCircleClick={setIsToothCircleChosen}
									nullifyCards={nullifyCards}
									isPrint={!!isPrint}
								/>
							);
						}
					})}
				</div>
				<div
					key={`dentalFormula-2`}
					style={{ ...(isPrint && { border: 'none' }) }}
					className='col-6 d-flex gap-2 teeth-direction-items teeth-direction-items-two'>
					{dentalFormula?.top?.map((item: any, index: number) => {
						if (index + 1 <= 16 && index + 1 > 8) {
							return (
								<TheethItemComponent
									key={`${item.id}-${index}-items-two`}
									key_custom={`${item.id}-items-two`}
									data={item}
									onToothClick={setIsToothChosen}
									onToothCircleClick={setIsToothCircleChosen}
									nullifyCards={nullifyCards}
									isPrint={!!isPrint}
								/>
							);
						}
					})}
				</div>
				<div
					style={{ ...(isPrint && { border: 'none' }) }}
					key={`dentalFormula-3`}
					className='col-6 d-flex justify-content-end gap-2 teeth-direction-items teeth-direction-items-three'>
					{dentalFormula?.bottom?.map((item: any, index: number) => {
						if (index + 1 <= 8) {
							return (
								<TheethItemComponent
									key={`${item.id}-${index}-items-three`}
									key_custom={`${item.id}-items-three`}
									data={item}
									onToothClick={setIsToothChosen}
									onToothCircleClick={setIsToothCircleChosen}
									nullifyCards={nullifyCards}
									isPrint={!!isPrint}
								/>
							);
						}
					})}
				</div>
				<div
					style={{ ...(isPrint && { border: 'none' }) }}
					key={`dentalFormula-4`}
					className='col-6 d-flex gap-2 teeth-direction-items teeth-direction-items-for'>
					{dentalFormula?.bottom?.map((item: any, index: number) => {
						if (index + 1 <= 16 && index + 1 > 8) {
							return (
								<TheethItemComponent
									key={`${item.id}-${index}-items-for`}
									key_custom={`${item.id}-items-for`}
									data={item}
									onToothClick={setIsToothChosen}
									onToothCircleClick={setIsToothCircleChosen}
									nullifyCards={nullifyCards}
									isPrint={!!isPrint}
								/>
							);
						}
					})}
				</div>
			</div>
		</>
	);
};

export default TeethTable;
